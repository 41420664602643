import React from 'react';
import Footer from '../footer/Footer';

interface CompProps {
  children?: React.ReactNode;
  stickyFooter?: boolean;
  wrapperClass?: string;
}

const MainLayout: React.FC<CompProps> = ({children, stickyFooter, wrapperClass}) => {
  return (
    <div className={ `${wrapperClass ? wrapperClass : ''} ${stickyFooter ? 'sticky' : ''}` }>
      { children }
      <Footer />
    </div>
  );
}

export default MainLayout;