
const DTOUserToFormFormat = (data: any) => {
  const referenteDonaciones = data.referentes.find((referente: any) => referente.tipo === 'donaciones');
  const referenteVoluntariado = data.referentes.find((referente: any) => referente.tipo === 'voluntariado');
  const bankingInformation = data.bankingInformations[0]
  const sedes = data.sedes.map((sede: any) => {
    return {
      id: sede.id,
      ubicacionId: sede.ubicacion.id,
      pisoDepto: sede.ubicacion.pisoDepto ?? "",
      coordenadas: {
        lat: sede.ubicacion.coordenadas.coordinates[0],
        lng: sede.ubicacion.coordenadas.coordinates[1]
      },
      direccion: sede.ubicacion.descripcion,
    }
  })
  const oportunidadesMapped = data.oportunidades.map((oportunidad: any, i: number) => {
    const sedeIndexSelected = sedes.findIndex((sede: any) => sede.id === oportunidad.sedeId);
    return {
      ...oportunidad,
      titulo: oportunidad.titulo,
      descripcion: oportunidad.descripcion,
      sede: sedeIndexSelected == -1 ? 0 : sedeIndexSelected.toString(),
      horario: Object.keys(oportunidad).filter(key => key.startsWith("turnos")).filter(horario => oportunidad[horario]),
      ...Object.fromEntries(oportunidad.volunteerSubcategories.map((subcategoria: any, i: number) => [subcategoria.id, true])),
      categorias: oportunidad.volunteerSubcategories.map((categoria: any, i: number) => categoria.id),
      dias: Object.keys(oportunidad).filter(key => key.startsWith("dias")).filter(dia => oportunidad[dia])
      // user.oportunidades[0].volunteerSubcategories[0].EventXVolunteerSubcategory
    }
  })

  const getDonaciones = (data: any): { [key: string]: boolean } => {
    const donaciones: { [key: string]: boolean } = {}
    data.forEach((donacion: any) => {
      donaciones[donacion.id] = true
    });
    return donaciones
  }


  return {
    'datos-principales-form': {
      id: data.id,
      name: data.name,
      email: data.email,
      description: data.description,
      facebook: data.facebook,
      twitter: data.twitter,
      instagram: data.instagram,
      website: data.website,
      foto: data.photo && data.photo.startsWith('data:image/png;base64, ') ? data.photo : `data:image/png;base64, ${data.photo}`,
      gallery: data["fotos_ongs"]
        .map((foto_ong: any) =>
          foto_ong.foto_blob_bu.startsWith('data:image/png;base64, ') ? { id: foto_ong.id, fotoContent: foto_ong.foto_blob_bu } : { id: foto_ong.id, fotoContent: `data:image/png;base64, ${foto_ong.foto_blob_bu}` }
        )
    },
    'sede-principal-form': sedes[0],
    "referente-de-donaciones-form": {
      idReferente: referenteDonaciones?.idReferente,
      personaReferente: referenteDonaciones?.personaReferente,
      telefono: referenteDonaciones?.telefono,
      email: referenteDonaciones?.email,
      numeroDeContacto: referenteDonaciones?.numeroDeContacto,
      tipo: "donaciones",
      recibir_soporte: referenteDonaciones?.recibir_soporte
    },
    "referente-de-voluntariado-form": {
      idReferente: referenteVoluntariado?.idReferente,
      personaReferente: referenteVoluntariado?.personaReferente,
      telefono: referenteVoluntariado?.telefono,
      email: referenteVoluntariado?.email,
      numeroDeContacto: referenteVoluntariado?.numeroDeContacto,
      tipo: "voluntariado",
      recibir_soporte: referenteVoluntariado?.recibir_soporte
    },
    "oportunidades-form": oportunidadesMapped,
    cuentaBancaria: {
      id: bankingInformation?.id,
      ongId: bankingInformation?.ongId,
      banco: bankingInformation?.bankName,
      numeroCuenta: bankingInformation?.accountNumber,
      tipoDeCuenta: bankingInformation?.accountType,
      accountName: "",
      currencyDollar: null,
      website: bankingInformation?.linkToDonations
    },
    show_banking_information: true,
    "sedes-form": sedes.splice(1),
    "donaciones-form": getDonaciones(data.ongs_donaciones)
  };
}
export default DTOUserToFormFormat;
