import React from "react";
import image from "../../../../images/Ok-pana 1.png";
import {
  TitleRegister,
  SubTitleRegister
} from "../../utils/constants";

const RegistroCompleto = () => {
  return (
    <div className="registro-completo">
      <div className="title">{TitleRegister.text}</div>
      <img src={image} alt="registro-completo"/>
      <div className="titulo">{SubTitleRegister.success}</div>
    </div>
  );
};

export default RegistroCompleto;
