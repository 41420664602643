import React from 'react';
import { Navigate, useLoaderData, useLocation } from 'react-router';

interface Props {
  children: JSX.Element;
  redirectRoute: string;
}

export const ViewAllowed = ({ children, redirectRoute }: Props) => {
  const { isViewAllowed } = useLoaderData() as {
    isViewAllowed: boolean;
  };
  const location = useLocation();

  if (!isViewAllowed) {
    return <Navigate to={redirectRoute} state={{ from: location }} replace />;
  }

  return children;
};
