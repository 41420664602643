import React from "react";
import { NewsCardI } from "../models";

const NewsCard = (props: { card: NewsCardI }) => {
  const card: NewsCardI = props.card;

  const photo = card.image;

  return (
    <div className="carta">
      <div className="titulo">{card.title}</div>
      <div className="fecha">{card.date}</div>
      <div className="foto">
        <img src={photo} />
      </div>
      <div className="description">{card.description}</div>
    </div>
  );
};

export default NewsCard;
