import React from "react";
import Filters from "../../../../../components/donate/search/filters";
import BlockedMap from "../../../../../components/donate/search/blocked-map";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

import "./index.css";
import useCategories from "../../../../../components/donate/search/useCategories";
import { Category } from "../../../../../utils/entities/Category";
import { Subcategorie } from "../../../../../utils/entities/Subcategorie";
import Cargando from "../../../../../utils/components/Cargando";

const ContainerElement = <div style={{ height: "100%", width: "100%" }} />;

const MapElement = <div style={{ height: "100%" }} />;

const Index = (props: any) => {
  const navigate = useNavigate();
  const categories = useCategories();

  const onFinishForm = (values: any) => {
    var selectedNames: string[] = [];
    //Quitamos nulos
    for (var value in values) {
      if (values[value]) {
        selectedNames = [...selectedNames, value];
      }
    }

    var selected: string[] = [];

    selectedNames.forEach((selectedName: string) => {
      categories.forEach((categoria: Category) => {
        if (
          selectedName.split("-")[0] === categoria.name &&
          categoria.subcategories
        ) {
          categoria.subcategories.forEach((subcategorie: Subcategorie) => {
            if (selectedName.split("-")[1] === subcategorie.name) {
              selected = [...selected, "" + subcategorie.id];
            }
          });
        }
      });
    });

    var result: string = selected.join("~");
    if (result === "") {
      notification.open({
        type: "warning",
        message: "Sin categorías",
        description: `Por favor selecciona al menos 1 categoría para realizar la búsqueda`,
        duration: 5,
      });
    } else {
      navigate("/donar/" + result);
    }
  };

  return (
    <div id="donate">
      <div className="search-page">
        <div className="search-section">
          <Filters onFinishForm={onFinishForm} categories={categories} />
        </div>
        <div className="map-section">
          <div className="map-overlay">
            <div>Realiza una búsqueda</div>
          </div>
          <BlockedMap
            containerElement={ContainerElement}
            mapElement={MapElement}
            loadingElement={<Cargando />}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
