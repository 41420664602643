import React, { useState } from "react";
import sendEmail from "../../../../utils/sendMail";
import { successResponse, errorResponse } from "../constants";

type FormProps = {};
type FormState = {
  name: string;
  email: string;
  phone: string;
  type: string;
  consult: string;
  isFormHidden: boolean;
  msgAlert?: string;
  typeAlert?: string;
  titleAlert?: string;
};

//to-do agregar la url a los archivos de constantes cuando hagan el merge de news
const mailMethod: string = "api/contact/contactEmail";
export default class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      type: "1", //default value, needs to be set up in case user doesn't makes a change in the drop down list
      consult: "",
      isFormHidden: false,
    };
  }

  handleChange(event: { target: { id: string; value: string } }) {
    var fieldName = event.target.id;
    var fleldVal = event.target.value;
    this.setState({ ...this.state, [fieldName]: fleldVal });
  }

  updateAlertStatus(value: boolean) {
    this.setState({
      ...this.state,
      isFormHidden: false,
    });
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var form = event.currentTarget;
    var contactUs = this.formatData();

    sendEmail(contactUs, mailMethod, (sent) => {
      if (sent) {
        form.reset();
        this.setState({
          ...this.state,
          isFormHidden: true,
          typeAlert: successResponse.typeAlert,
          msgAlert: successResponse.msgAlert,
          titleAlert: successResponse.titleAlert,
        });
      } else {
        this.setState({
          ...this.state,
          isFormHidden: true,
          typeAlert: errorResponse.typeAlert,
          msgAlert: errorResponse.msgAlert,
          titleAlert: errorResponse.titleAlert,
        });
      }
    });
  };

  formatData(): any {
    var d = new Date();
    var result: any = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      tipo: this.state.type === "1" ? "ONG" : "Voluntario",
      consult: this.state.consult,
      contactDate:
        d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear(),
    };
    return result;
  }

  reset = () => {
    this.setState({ phone: "", name: "", email: "", consult: "", type: "1" });
  };

  render(): JSX.Element {
    return (
      <div className="formulario">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSe5SGr4LZ_nDInP7Lb23gt0E2pqpx0TvAax491dl5zF5ixg6Q/viewform?embedded=true"
          frameBorder="0"
          style={{ top: 0, left: 0, width: "100%", height: "100%" }}
        >
          Cargando…
        </iframe>
      </div>
    );
  }
}
