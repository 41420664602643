import React from "react";
import Filters from "../../../../../components/volunteering/search/filters";
import BlockedMap from "../../../../../components/volunteering/search/blocked-map";
import useFilters from "../../../../../components/volunteering/search/useVolunteerFilters";

import { notification } from "antd";
import { useNavigate } from "react-router-dom";

import "./index.css";
import { VolunteerCategory } from "../../../../../utils/entities/VolunteerFilter";
import Cargando from "../../../../../utils/components/Cargando";

const ContainerElement = <div style={{ height: "100%", width: "100%" }} />;

const MapElement = <div style={{ height: "100%" }} />;

const Index = (props: any) => {
  const navigate = useNavigate();
  const filters = useFilters();

  const onFinishForm = (values: any) => {
    var selectedNames: string[] = [];
    //Quitamos nulos
    for (var value in values) {
      if (values[value]) {
        selectedNames = [...selectedNames, value.split("-")[0]];
      }
    }

    var selected: string[] = [];

    for (let i = 0; i < selectedNames.length; i++) {
      const selectedName = selectedNames[i];
      dance:
      for (let j = 0; j < filters.length; j++) {
        const filter: VolunteerCategory = filters[j];
        if (selectedName === filter.name) {
          selected = [...selected, "" + filter.id];
          break dance;
        }
      }
    }

    var result: string = selected.join("~");
    if (result === "") {
      notification.open({
        type: "warning",
        message: "Sin causas",
        description: `Por favor selecciona al menos una causa para seleccionar para realizar la búsqueda`,
        duration: 5,
      });
    } else {
      navigate("/voluntariado/" + result);
    }
  };

  return (
    <div id="volunteer">
      <div className="search-page">
        <div className="search-section">
          <Filters onFinishForm={onFinishForm} filters={filters} />
        </div>
        <div className="map-section">
          <div className="map-overlay">
            <div>Por favor, realiza una búsqueda</div>
          </div>
          <BlockedMap
            containerElement={ContainerElement}
            mapElement={MapElement}
            loadingElement={<Cargando />}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
