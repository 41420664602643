import ajaxAction from "../ajaxAction";


export const updateStatistics = (ong_id: number | undefined, type: string, origin: string) => {

  if (ong_id) {
    return ajaxAction({
      method: 'POST',
      data: {ong_id, type, origin},
      service: `/api2/statistics`,
    })
  }

}
