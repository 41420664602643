import React from "react";
import { Sede } from "../../../utils/entities/Sede";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Ong as ongE } from "../../../utils/entities/Ong";
import useOng from "./useOng";

interface DireccionProps {
  ongId: number;
}

function Direccion(props: DireccionProps) {
  const { ongId } = props;

  const ong: ongE = useOng(ongId);
  const sedePrincipal = ong.id ? ong.sedes[0] : undefined;

  return (
    <div className="direccion">
      <div className="subtitulo">Dirección</div>
      <div className="direccion-container">
        <div className="icon">
          <FontAwesomeIcon icon={faLocationDot} />
        </div>
        <div className="data">
          <div>{sedePrincipal?.description}</div>
          <div>Horarios: {sedePrincipal?.time}</div>
        </div>
      </div>
    </div>
  );
}
export default Direccion;
