import React, { useEffect, useRef, useState } from "react";
import SedeModal from "./components/sedeModal";
import { Sede } from "../../utils/models";
import { Form, Button, Radio, Space, notification } from "antd";
import { Requeriment } from "../../utils/models";

const eliminarSedeNotification = {
  message: 'Error al eliminar sede',
  description: `No se puede eliminar esta sede puesto que esta asociada a una 
    oportunidad. Puedes cambiar la sede de la(s) oportunidad(es) que tengan 
    asociada esta sede si aun quieres eliminar esta sede`,
  style: {
    backgroundColor: "#f8d7da",
    borderColor: "#f5c6cb",
    color: "#721c24"
  },
  duration: 9
}

const Sedes = (props: any) => {

  const nombre: string = "sedes-form";
  const requerimientos: Requeriment[] = [];
  const data: any = props.data;
  const tituloForm = "Otras sedes";
  const [form] = Form.useForm();
  const actualizarDatos = props.actualizarDatos;

  const setActiveTabName = props.setActiveTabName;
  useEffect(() => {
    setActiveTabName(nombre)
  }, [])

  const procesarDatos = (valores: any) => {
    return sedes
  }

  const FW = props.fw;

  const [sedes, setSedes] = useState(data[nombre] ?? []);

  const [numSedes, setNumSedes] = useState(sedes.length);
  const sedesMax: number = 5;
  const [selectedSede, setSelectedSede] = useState(sedesMax);

  const oportunidades = props.data["oportunidades"] ?? [];

  const addSede = (sede: Sede) => {
    setSedes((prevState: any) => prevState.concat(sede));
    setNumSedes((oldNumSedes: any) => oldNumSedes + 1);
    setSelectedSede(sedesMax);
  }

  const updateSedes = (sede: Sede) => {
    setSedes((prevState: any) => {
      prevState[selectedSede] = sede;
      return prevState;
    });
    setSelectedSede(sedesMax);
  }

  const deleteSede = () => {
    const sedeUsed = oportunidades.some((oportunidad: any) => {
      return (parseInt(oportunidad.sede) - 1 === selectedSede)
    })
    if (sedeUsed) {
      notification.open(eliminarSedeNotification)
    } else {
      setSedes((prevState: any) => {
        prevState.splice(selectedSede, 1);
        return prevState;
      });
      setNumSedes((oldNumSedes: any) => oldNumSedes - 1);
    }
    setSelectedSede(sedesMax);
  }

  const changeSelectedSede = (e: any) => {
    setSelectedSede(e.target.value);
  }

  const clearSelectedSede = (e: any) => {
    if (+e.target.value === +selectedSede) {
      setSelectedSede(sedesMax);
    }
  }

  const saveDataButtonRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (props.modo === "edicion") {
      saveDataButtonRef!.current!.click();
    }
  }

  return (
    <FW
      nombre={nombre}
      tituloForm={tituloForm}
      data={data}
      form={form}
      actualizarDatos={actualizarDatos}
      procesarDatos={procesarDatos}
      requerimientos={requerimientos}
    >
      <div className="sedes-container">
        <div className="caption">¿Tu ONG tiene otras sedes? ¡Agregalas acá!</div>
        <Button
          ref={saveDataButtonRef}
          style={{ display: 'none' }}
          onClick={() => { }}
          htmlType="submit"
          form={nombre}
        >
        </Button>
        <div className="buttons-container">
          <div className="button-container" >
            {(numSedes >= sedesMax) &&
              <SedeModal
                formName={`new-sede-${numSedes}-form`}
                onFinishForm={(sede: any) => {
                  addSede(sede);
                }}
                buttonMessage={"Agregar"}
                sede={{}}
                modo={props.modo}
                disabled={true}
              />
            }
            {!(numSedes >= sedesMax) &&
              <SedeModal
                formName={`new-sede-${numSedes}-form`}
                onFinishForm={(sede: any) => {
                  addSede(sede);
                  handleClick()
                }}
                buttonMessage={"Agregar"}
                sede={{}}
                modo={props.modo}
              />
            }
          </div>
          <div className="button-container" >
            {(numSedes < 1 || selectedSede >= sedesMax) &&
              <SedeModal
                formName={`sede-${selectedSede}-form`}
                onFinishForm={(sede: any) => {
                  updateSedes(sede);
                }}
                buttonMessage={"Modificar"}
                sede={sedes[selectedSede] ?? {}}
                modo={props.modo}
                disabled={true}
              />
            }
            {!(numSedes < 1 || selectedSede >= sedesMax) &&
              <SedeModal
                className="btn btn-primary"
                formName={`sede-${selectedSede}-form`}
                onFinishForm={(sede: any) => {
                  updateSedes(sede);
                  handleClick()
                }}
                buttonMessage={"Modificar"}
                sede={sedes[selectedSede] ?? {}}
                modo={props.modo}
              />
            }
          </div>
          <div className="button-container" >
            {(numSedes < 1 || selectedSede >= sedesMax) &&
              <Button
                className="btn-disabled"
                type="primary"
                danger
                onClick={() => { deleteSede() }}
                disabled={true}
              >
                Eliminar
              </Button>
            }
            {!(numSedes < 1 || selectedSede >= sedesMax) &&
              <Button
                type="primary"
                danger
                onClick={() => {
                  deleteSede()
                  handleClick()
                }}
              >
                Eliminar
              </Button>
            }
          </div>
        </div>
        <Radio.Group onChange={changeSelectedSede} value={selectedSede}>
          <Space direction="vertical">
            {sedes.map((value: any, index: number) => {
              return (
                <Radio key={index} value={index} onClick={clearSelectedSede}>{value.direccion}</Radio>
              )
            })
            }
          </Space>
        </Radio.Group>
      </div>
    </FW>
  );
}

export default Sedes;
