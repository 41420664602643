import L from 'leaflet';

import React, { useEffect, useRef } from 'react';
import { customIcon } from '../../../../../../../utils/const/iconConfig';
import { createTileLayer } from '../../../../../../../utils/const/mapUtils';
import { MapWithMakerProps } from '../../types/props/MapWithMakerProps';

import 'leaflet/dist/leaflet.css';

const MapWithAMarker = ({ center, markerPosition, setMarkerPosition, style }: MapWithMakerProps) => {

  const mapRef = useRef<L.Map | null>(null);
  const markerRef = useRef<L.Marker | null>(null);

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map('map', {
        center: center,
        zoom: 12,
        minZoom: 8,
        maxZoom: 15,
      });

      mapRef.current.attributionControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');
      createTileLayer().addTo(mapRef.current);

      markerRef.current = L.marker(markerPosition, {
        icon: customIcon,
        draggable: true
      }).addTo(mapRef.current);

      markerRef.current.on('dragend', (e: L.DragEndEvent) => {
        const position = e.target.getLatLng();
        setMarkerPosition(position);
      });

    } else {
      markerRef.current?.setLatLng(markerPosition);
      mapRef.current.setView(markerPosition, mapRef.current.getZoom());
    }

  }, [center, setMarkerPosition, markerPosition]);

  return <div id="map" style={style}></div>;
};

export default MapWithAMarker;
