import React from 'react';
import { Result, Button, Tooltip, Space } from 'antd';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import './NotFound.css';

const NotFound = () => {
    const currentPath = window.location.pathname;
    const handleGoBack = () => {
        // Vuelve a la página anterior utilizando JavaScript
        window.history.back();
    };
    return (
        <div className='not-found-container'>
            <Result
                className="not-found-page"
                status="404"
                subTitle={`Lo sentimos, no pudimos encontrar la página "${currentPath}"`}
                title="Página No Encontrada"
                extra={
                    <Space>
                        <Tooltip title="Volver a la página anterior">
                            <Button
                                className="not-found-button"
                                icon={<ArrowLeftOutlined />}
                                onClick={handleGoBack}
                                type="primary"
                            >
                                Atrás
                            </Button>
                        </Tooltip>
                        <Tooltip title="Ir a la página principal">
                            <Button
                                href="/"
                                icon={<HomeOutlined />}
                                type="default"
                            >
                                Ir al Inicio
                            </Button>
                        </Tooltip>
                    </Space>
                }
            />
        </div>
    );
};

export default NotFound;