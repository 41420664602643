import React, { useState } from "react";
import "./mainSection.css";
import AuthService from "../../../services/auth/AuthService";
import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { useNavigate } from "react-router";
import roadmap1 from "../../../images/new-login/roadmap1.png";
import roadmap2 from "../../../images/new-login/roadmap2.png";
import roadmap3 from "../../../images/new-login/roadmap3.png";
import roadmap4 from "../../../images/new-login/roadmap4.png";

const MainSection = (props: any) => {
  const [mensajeMailEnviado, setMensajeMailEnviado] = useState<boolean>(false);
  const [failure, setFailure] = useState<boolean>(false);
  const [failureText, setFailureText] = useState<string>("");
  const [modalAbierta, setModalAbierta] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const abrirModal = () => setModalAbierta(true);
  const cerrarModal = () => setModalAbierta(false);

  const onFinish = (values: any) => {
    AuthService.loginONG(
      "/auth2/login/ong",
      values.username,
      values.password
    ).then(([success, value]: [boolean, string]) => {
      if (success) {
        window.location.assign("/editarONG")
        // navigate();
        // window.location.reload();
      } else {
        setFailure(true);
        setFailureText(value);
      }
    });
  };

  const modalResetPass = () => {
    abrirModal();
    // resetPass()
  }


  const submitForm = () => {

    const email = form.getFieldsValue()["email"];

    AuthService.forgottenPassOrActivateUser(
      email,
      "ong",
      (bol: boolean, nul: null) => { }
    ).then(() => {
      setMensajeMailEnviado(true);
    });

  }


  const registerONG = () => {
    navigate("/registroONG");
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div id="main-section">
      <div className="cover">
        <div className="registerANDlogin">
          <div className="register">
            <div className="bannerImage" />
            <div className="titulo">
              <b>Registrá tu ONG</b> y conectate con <b>miles de voluntarios</b>
            </div>
            <Button
              className="ant-btn ant-btn-primary orange-button"
              onClick={registerONG}
            >
              Registrar mi ONG
            </Button>
          </div>
          <div className="login">
            <div className="titulo">Ingresar</div>
            {failure && (
              <div style={{ marginBottom: "5px" }}>
                <Alert message={failureText} closable type="error" />
              </div>
            )}
            <Form
              name="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="username"
                rules={[{ required: true, message: "Ingresar email." }]}
              >
                <Input placeholder="E-mail" />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                rules={[{ required: true, message: "Ingresar la contraseña." }]}
              >
                <Input.Password placeholder="Contraseña" />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Mantener sesión activa</Checkbox>
              </Form.Item>
            </Form>
            <Button
              className="ant-btn ant-btn-primary orange-button"
              htmlType="submit"
              form="login-form"
            >
              Ingresar
            </Button>
            <Button
              type="primary"
              className="ant-btn orange-ghost-btn"
              ghost
              onClick={() => { modalResetPass() }}>
              Olvidó su contraseña?
            </Button>
            <Modal
              title="Recuperar Contraseña"
              okText="Enviar correo"
              cancelText="Cancelar"
              visible={modalAbierta}
              onOk={form.submit}
              onCancel={() => cerrarModal()}
              afterClose={() => form.resetFields()}>
              <Form
                name="reset password"
                initialValues={() => false}
                form={form}
                onFinish={submitForm}
              >
                <Form.Item
                  name="email"
                  label="E-mail"
                  className="obligatorio"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, ingresar email.",
                    },
                    {
                      type: "email",
                      message: "El email ingresado no es válido",
                    },
                    { max: 60, message: "El email no debe exceder los 60 caracteres" },
                  ]}
                >
                  <Input placeholder="Ingresar e-mail" />
                </Form.Item>

                {mensajeMailEnviado &&
                  <Alert message="Si la dirección de correo ingresado esta registrada se enviarán las instrucciones para reestablecer la contraseña. " type="info" closable />
                }
              </Form>
            </Modal>
          </div>
        </div>
        <div className="roadmap">
          <div className="contenedor">
            <img src={roadmap1} />
            <div>Registrá tu ONG</div>
          </div>
          <div className="contenedor">
            <img src={roadmap2} />
            <div>Cargá las sedes</div>
          </div>
          <div className="contenedor">
            <img src={roadmap3} />
            <div>Configurá tus necesidades para recibir donaciones</div>
          </div>
          <div className="contenedor">
            <img src={roadmap4} />
            <div>Creá llamados a voluntariados</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
