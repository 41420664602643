import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "antd";

interface Country {
  country: string, // ex: "ARG"
  startsWith: string, // ex: "+54"
  mask: string, // ex: '+54-9-0000000000'
  maxLength: number,
  flag: string // ex: "https://www.countryflags.io/ar/flat/64.png",
}

interface PhoneNumberInputProps {
  options: Country[];
  form: any;
  validateCallback: (phoneNumber: string) => boolean;
  validateRequired: () => boolean;
  formInfoName: string; // ex: "phoneNumber". The input value can be accessed with form.getFieldValue(formInfoName)
  countryInfoName: string; // ex: "UY". The input value can be accessed with form.getFieldValue(countryInfoName)
  label: string;
  disabled: boolean;
  isUpdated: boolean;
}

interface IFormPropertyMap {
  [key: string]: string;
}

const telefonoRequerido = "Por favor, ingresar número de teléfono.";
const contactoRequerido = "Por favor, ingresar número de contacto.";

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {

  const formPropertyMap: IFormPropertyMap = {
    "telefono": "countryTelefono",
    "numeroDeContacto": "countryNumeroDeContacto",
    "phoneNumber": "country"
  }

  const selectCountry = (value: string): Country | null => {
    return props.options.find(option => option.country === value) || null
  }

  const getFormType = (): Country | null => {
    let numValue: string = "";
    if (props.formInfoName in formPropertyMap) {
      numValue = props.form.getFieldValue()[formPropertyMap[props.formInfoName]];
    }

    return selectCountry(numValue);
  }

  const [country, setCountry] = useState<Country | null>(
    getFormType() || null
  );

  let defaultRequired = true;
  if (props.validateRequired) {
    defaultRequired = false;
  }

  const getCountryPrefix = (): string => {
    let cn: Country | null = getFormType();
    if (cn != null) { return cn.startsWith }
    return "";
  }

  const getCountryMaxLength = (): number => {
    if (country != null) {
      return country.maxLength
    }
    return 15;
  }

  const requiredMessage = () => props.formInfoName === 'telefono' ? telefonoRequerido : contactoRequerido;
  return <Form.Item label={props.label} className={!props.disabled ? "obligatorio" : ""}>
    <Input.Group compact style={{ whiteSpace: "nowrap" }}>
      <Form.Item name={props.countryInfoName}>
        <Select
          disabled={props.disabled}
          style={{ width: 100 }}
          onChange={value => {
            const countrySelected = selectCountry(value)
            setCountry(countrySelected);
            props.form.setFieldsValue({ [props.formInfoName]: "" });
          }}>
          {props.options.map((option: Country) => (
            <Select.Option value={option.country} key={option.country}>
              {" "}
              {option.country}{" "}
              <img src={option.flag} alt={option.flag} style={{ width: 25, height: 25 }} />
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={props.formInfoName}
        rules={[{ required: defaultRequired ? defaultRequired : props.validateRequired(), message: requiredMessage() },
        () => ({
          validator(_, value) {
            if (!value || props.validateCallback(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Número de contacto no válido"));
          }
        })]}>
        {props.form &&
          <Input disabled={props.disabled}
            addonBefore={props.disabled ? "" : getCountryPrefix()}
            maxLength={getCountryMaxLength()}
            placeholder="+59891234567"
            onChange={(event) => {
              props.form.setFieldsValue({ [props.countryInfoName]: country?.country });
              props.form.setFieldsValue({ [props.formInfoName]: event.target.value });
            }}
          />
        }
      </Form.Item>
    </Input.Group>
  </Form.Item>;
}

export default PhoneNumberInput;
