import React, { FC, useState } from 'react'
import "./editar-ong.css";
import { useParams } from 'react-router-dom';
import Layout from './components/UI/Layout';
import { Space } from 'antd';
import fetcher from '../../../../../helpers/fetcher';
import useSWR from 'swr';
import TabGroup from './components/UI/tabs/TabGroup';
import { Tabs } from './types/Tabs';
import { Ong } from './types/Ong';
import ProfileData from './components/UI/tabs/ProfileData';
import CentralBusiness from './components/UI/tabs/CentralBusiness';
import OtherBusiness from './components/UI/tabs/OtherBusiness';
import StatusAndHistoryAccount from './components/UI/tabs/StatusAndHistoryAccount';
import BankData from './components/UI/tabs/BankData';
import { SWRProvider, useSharedSWR } from './contexts/SWRContext';
import Photographs from './components/UI/tabs/Photographs';

const EditarONG: FC = () => {
  const { ongId } = useParams();
  const [activeTab, setActiveTab] = useState<string>('tab1');
  const fallbackData = {
    fallbackData: {},
  };

  const { data, isLoading, mutate } = useSWR<Partial<Ong>>(`/api2/ong/id/${ongId}`, fetcher, fallbackData);
  
  const { name, }: Partial<Ong> = data!;

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const tabs: Tabs[] = [
    {
      key: 'tab1',
      tab: 'Datos del Perfil',
      component: <ProfileData />,
    },
    {
      key: 'tab2',
      tab: 'Fotografías',
      component: <Photographs />,
    },
    {
      key: 'tab3',
      tab: 'Datos de la Sede Central',
      component: <CentralBusiness />,
    },
    {
      key: 'tab4',
      tab: 'Otras Sedes',
      component: <OtherBusiness />,
    },
    {
      key: 'tab5',
      tab: 'Estado e Historia de la Cuenta',
      component: <StatusAndHistoryAccount />,
    },
    {
      key: 'tab6',
      tab: 'Datos Bancarios',
      component: <BankData />,
    },
  ];
  return (
    <>
      <Layout
        title={`Editar ONG: ${name ?? "⏳"}`}
        isLoading={isLoading}
      >
        <Space direction='vertical'>
          <SWRProvider value={{ data, isLoading, mutate }}>
            <TabGroup
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              tabs={tabs}
            />
          </SWRProvider>
        </Space>
      </Layout>
    </>
  )
}

export default EditarONG;


