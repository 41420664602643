import { Button } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Evento } from "../../../utils/entities/Evento";

interface DetalleProps {
  evento: Evento;
  showModal: () => void;
}

function Detalle(props: DetalleProps) {
  const evento: Evento = props.evento;
  const showModal: () => void = props.showModal;

  return (
    <div className="detalle">
      <div className="subtitulo">Oportunidades de voluntariado</div>
      <div className="grey-container">
        <div className="item">{evento.description}</div>

        <div className="item">
          <div className="item-titulo">Ubicación</div>
          <div className="item-texto">{evento.ubicacion.descripcion}</div>
          <div className="item-lista">{evento.ubicacion.descripcion}</div>
        </div>
        <div className="item">
          <div className="item-titulo">Interés</div>
          <div className="item-texto">
            {evento.categories.map((categoria, index) => {
              return (
                <span key={`${index}-${categoria.name}`}>
                  {categoria.name +
                    (index === evento.categories.length - 1 ? " " : ", ")}
                </span>
              );
            })}
          </div>
          <div className="item-lista">
            {evento.categories.map((categoria, index) => {
              return (
                <div key={`${index}-${categoria.name}`}>{categoria.name}</div>
              );
            })}
          </div>
        </div>
        <div className="item">
          <div className="item-titulo">Tiempo disponible</div>
          <div className="item-texto">
            {evento.tiempos.map((tiempo, index) => {
              return (
                <span key={`${index}-${tiempo}`}>
                  {tiempo + (index === evento.tiempos.length - 1 ? " " : ", ")}
                </span>
              );
            })}
          </div>
          <div className="item-lista">
            {evento.tiempos.map((tiempo, index) => {
              return <div key={`${index}-${tiempo}`}>{tiempo}</div>;
            })}
          </div>
        </div>
        <div className="item">
          <div className="item-titulo">Horario</div>
          <div className="item-texto">
            {evento.horarios.map((horario, index) => {
              return (
                <span key={`${index}-${horario}`}>
                  {horario +
                    (index === evento.horarios.length - 1 ? " " : " - ")}
                </span>
              );
            })}
          </div>
          <div className="item-lista">
            {evento.horarios.map((horario, index) => {
              return <div key={`${index}-${horario}`}>{horario}</div>;
            })}
          </div>
        </div>
        <div className="item">
          <div className="item-titulo">Duración</div>
          <div className="item-texto">
            {evento.tipoDuracionId === 1 ? "Menos de 3 meses" : "3 meses o más"}
          </div>
          <div className="item-lista">
            {evento.tipoDuracionId === 1 ? "Menos de 3 meses" : "3 meses o más"}
          </div>
        </div>
        <div className="item">
          <div className="item-titulo">Días de disponibilidad</div>
          <div className="item-texto">
            {evento.dias.map((dia, index) => {
              return (
                <span key={`${index}-${dia}`}>
                  {dia + (index === evento.dias.length - 1 ? " " : ", ")}
                </span>
              );
            })}
          </div>
          <div className="item-lista">
            {evento.dias.map((dia, index) => {
              return <div key={`${index}-${dia}`}>{dia}</div>;
            })}
          </div>
        </div>
        <div className="item">
          <div className="item-titulo">Presencia</div>
          <div className="item-texto">
            {evento.presencia.map((presencialidad, index) => {
              return (
                <span key={`${index}-${presencialidad}`}>
                  {presencialidad +
                    (index === evento.presencia.length - 1 ? " " : ", ")}
                </span>
              );
            })}
          </div>
          <div className="item-lista">
            {evento.presencia.map((presencialidad, index) => {
              return (
                <div key={`${index}-${presencialidad}`}>{presencialidad}</div>
              );
            })}
          </div>
        </div>
        <div className="item-button">
          <Button
            className="ant-btn ant-btn-primary btn-buscar"
            type="primary"
            onClick={showModal}
          >
            Quiero involucrarme
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Detalle;
