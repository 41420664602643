import { CategoryVolunteer } from "../entities/CategoryVolunteer";
import { Coordenadas } from "../entities/Coordenadas";
import { Evento } from "../entities/Evento";
import { ubicacionDtoToEntity } from "./UbicacionMapper";

export function eventDtoToEntity(e: any): Evento {
  return {
    id: e.id,
    titulo: e.titulo,
    description: e.descripcion,
    coords: {
      x: e.ubicacion.coordenadas.coordinates[0],
      y: e.ubicacion.coordenadas.coordinates[1],
    },
    ongId: e.ongId,
    sedeId: e.sedeId,
    categories: mapCategories(e.eventos_filtros),
    tiempos: [
      e.tiempo_unavez ? "Una vez" : "",
      e.tiempo_ocasional ? "Ocasional" : "",
      e.tiempo_mediotiempo ? "Medio tiempo" : "",
      e.tiempo_tiempocompleto ? "Tiempo completo" : "",
    ].filter((n) => n),
    horarios: [
      e.turnos_maniana ? "Mañana" : "",
      e.turnos_tarde ? "Tarde" : "",
      e.turnos_noche ? "Noche" : "",
    ].filter((n) => n),
    presencia: [
      e.presencia_virtual ? "Física" : "",
      e.presencia_fisica ? "Virtual" : "",
    ].filter((n) => n),
    dias: [
      e.dias_lunes ? "Lunes" : "",
      e.dias_martes ? "Martes" : "",
      e.dias_miercoles ? "Miércoles" : "",
      e.dias_jueves ? "Jueves" : "",
      e.dias_viernes ? "Viernes" : "",
      e.dias_sabado ? "Sábado" : "",
      e.dias_domingo ? "Domingo" : "",
    ].filter((n) => n),
    tipoDuracionId: e.tipoDuracionId,
    ubicacion: ubicacionDtoToEntity(e.ubicacion),
  };
}

function mapCategories(values: any[]): CategoryVolunteer[] {
  let array: CategoryVolunteer[] = [];
  values.forEach((element) => {
    array.unshift({
      id: element.id,
      name: element.nombre,
    });
  });
  return array;
}
