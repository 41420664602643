import React from "react";
import logo from "../../../images/new-home/AddONG.png";
import { Button } from "antd";
import { Link } from "react-router-dom";

import img1 from "../../../images/new-home/AddONGimg1.png";
import img2 from "../../../images/new-home/AddONGimg2.png";
import img3 from "../../../images/new-home/AddONGimg3.png";

const AddONG = () => {
  return (
    <div id="h-addONG">
      <div className="textContainer">
        <div className="icon">
          <img className="navbar-brand-img" src={logo} alt="Involucrate Logo" />
        </div>
        <div className="text1">
          Involucrate es una red de <b>250</b> organizaciones, colectivos y
          proyectos sociales...
        </div>
        <div className="text2">
          <b>Involucrate</b> es un portal de <b>voluntariado</b> y{" "}
          <b>donaciones </b>
          que busca ser una plataforma de nexo y acercamiento.
          <div className="separador" />
          Somos un grupo de voluntarios convocados por el Fondo para
          Emprendimientos Solidarios y creemos en la importancia de la
          solidaridad, el compromiso y la acción en el desarrollo de la sociedad
          y en el desarrollo personal.
          <div className="separador" />
          De diferentes perfiles y diferentes edades, todos aportamos para
          generar un nexo entre las organizaciones y las personas que buscan el
          bien común. Somos un grupo abierto en continuo crecimiento que busca
          la manera más efectiva de aportar positivamente a la sociedad.
        </div>
        <Button type="primary" ghost>
          <Link to="/registroONG">
            Sumar mi organización
          </Link>
        </Button>
      </div>

      <div className="imagesContainer">
        <img className="imagen1" src={img1} alt="" />
        <img className="imagen2" src={img2} alt="" />
        <img className="imagen3" src={img3} alt="" />
      </div>
    </div>
  );
};

export default AddONG;
