import React from 'react';
import "./cuenta.css";
import Layout from '../administradores/components/UI/Layout';
import ChangePasswordForm from './compontents/ChangePasswordForm';

const Cuenta = () => {
    return (

        <Layout title='Cambiar Contraseña'>

            <ChangePasswordForm />

        </Layout>

    )
}

export default Cuenta;
