import React, { useEffect, useState } from "react";
import Title from "../../../../../components/donate/oportunity/title";
import useOng from "../../../../../components/donate/oportunity/useOng";
import MiniMap from "../../../../../components/donate/oportunity/mini-map";
import { Ong as ongE } from "../../../../../utils/entities/Ong";
import Description from "../../../../../components/donate/oportunity/description";
import MetodosContacto from "../../../../../components/donate/oportunity/metodos_contacto";
import Direccion from "../../../../../components/donate/oportunity/direccion";
import RedesSociales from "../../../../../components/donate/oportunity/redes_sociales";
import OtrasDonaciones from "../../../../../components/donate/oportunity/otras_donaciones";
import "./index.css";
import { useNavigate, useParams } from "react-router";
import Fotos from "../../../../../components/donate/oportunity/fotos";
import { Sede } from "../../../../../utils/entities/Sede";
import Cargando from "../../../../../utils/components/Cargando";

const ContainerElement = <div style={{ height: "128px", width: "100%" }} />;

const MapElement = <div style={{ height: "100%" }} />;

const Index = () => {
  const { ongId, sedeId } = useParams();
  const navigate = useNavigate();

  //Estados para los marcadores (se seleccionan en search y se usan en map)
  const ong: ongE = useOng(ongId!);
  const [sede, setSede] = useState<Sede>({} as Sede);

  useEffect(
    () => {
      try {
        if (isNaN(parseInt(sedeId!)) && !isFinite(parseInt(sedeId!))) {
          throw new Error("Error! The id is not numeric");
        }
        if (isNaN(parseInt(ongId!)) && !isFinite(parseInt(ongId!))) {
          throw new Error("Error! The id is not numeric");
        }
        if (ong?.id) {
          const sedeActual = ong.sedes.find(s => s.id === parseInt(sedeId!));
          if (sedeActual === undefined)  throw new Error("La sede no esta asociada a la ong");
          setSede(sedeActual);
        }
      } catch (error) {
        console.log(error);
        navigateToPreviousPage();
      }
    },
    [ong]
  );

  const navigateToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div id="donate-oportunity">
      <div className="donate-content">
        <div className="donation-page">
          {ong?.id && <Title ong={ong} navigate_to_map={navigateToPreviousPage} />}
          {sede?.id && <MiniMap
            sede={sede}
            containerElement={ContainerElement}
            mapElement={MapElement}
            loadingElement={<Cargando/>}
          />}
          {ong?.id && <Description ong={ong} />}
          {ong?.id && 
          <MetodosContacto
            phone={ong.phone}
            email={ong.email}
            whatsapp={ong.contactNumber}
            ongId={ong.id}
          />}
          {(ong.photo || (ong?.galeria && ong.galeria.length > 0)) && <Fotos ong={ong} />}
          {sede?.id && <Direccion sede={sede} />}
          {ong?.id &&
          <RedesSociales
            ongId={ong.id}
            web={ong.website}
            twitter={ong.twitter}
            facebook={ong.facebook}
            instagram={ong.instagram}
          />}
          {sede?.id && <OtrasDonaciones sedeId={parseInt(sedeId!)} />}
        </div>
      </div>
    </div>
  );
};

export default Index;
