import ajaxAction, { Config } from "../utils/ajaxAction";

const fetcher = async (
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" = "GET",
    data?: unknown,
) => {
    const config: Config = {
        service: url,
        method,
        auth: "",
    };
    if (data) {
        config.data = data;
    }
    try {
        const response = await ajaxAction(config);
        const data = await response.json();
        return data;
    }
    catch (error: unknown) {
        if (error instanceof Error) {
            throw error;
        }
    }
}

export default fetcher;