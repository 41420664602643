import L from 'leaflet';
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { customIcon } from '../../../utils/const/iconConfig';
import { createTileLayer } from '../../../utils/const/mapUtils';
import { Event } from "../../../utils/entities/Event";

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css';

interface MapProps {
  events: Array<Event>;
}

const NormalMap = ({ events }: MapProps) => {
  const navigate = useNavigate();
  const goToOportunity = useCallback((id: number) => {
    navigate(`/evento/${id}`);
  }, [navigate]);

  useEffect(() => {
    const map = L.map('map', {
      center: [-34.8833, -56.1667],
      zoom: 12,
      minZoom: 8,
      maxZoom: 15,
      zoomControl: false
    });

    map.attributionControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');
    createTileLayer().addTo(map);

    const markers = L.markerClusterGroup();

    events.forEach(event => {
      const popupContent = L.popup({
        minWidth: 200,
        maxWidth: 200
      }).setContent(`
        <div class="dialog-content">
          <div class="titulo">${event.ongName}</div>
          <div class="descripcion">${event.description}</div>
          <div class="button-row">
            <button
              onclick="window.reactTriggerGoToOportunity(${event.id})"
              type="button"
              class="ant-btn ant-btn-primary ir-a-donacion"
            >
              <span>Ver más</span>
            </button>
          </div>
        </div>
      `);

      const marker = L.marker([event.coords.x, event.coords.y],
        {
          icon: customIcon,
          title: event.titulo
        }).bindPopup(popupContent);

      markers.addLayer(marker);
    });

    map.addLayer(markers);

    (window as any).reactTriggerGoToOportunity = (id: number) => {
      goToOportunity(id);
    };

    return () => {
      map.remove();
      delete (window as any).reactTriggerGoToOportunity;
    };

  }, [events, goToOportunity]);

  return <div id="map" style={{ height: '100%', width: '100%' }}></div>;
};

export default NormalMap;
