import { useState, useEffect } from "react";
import ajaxAction, { Config } from "../../../utils/ajaxAction";
import { getOngById } from "../../../utils/constants";
import { ongDtoToEntity } from "../../../utils/mappers/OngMapper";
import { Ong as ongE } from "../../../utils/entities/Ong";

const useOng = (id: number) => {
  const [ong, setOng] = useState({} as ongE);

  useEffect(
    () => {
      if (id) {
        const config: Config = {
          service: `${getOngById}${id.toString()}`,
          method: "GET",
          auth: "",
        };

        ajaxAction(config)
          .then(async (response: any) => {
            const data = await response.json();
            let dataMappeada = ongDtoToEntity(data);
            setOng(dataMappeada);
          })
          .catch((err: any) => console.log(err));
      }
    }
    ,
    [id]
  );

  return ong;
};

export default useOng;
