import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import logo from "../../../images/new-home/Coca-Cola.png";
import Card from "./components/Card";

const VoluntariadoCorporativo = () => {
  return (
    <div id="h-voluntariadoCorporativo">
      <div className="titulo tituloM">
        Voluntariado
        <div />
        Corporativo
      </div>
      <div className="titulo tituloD">Voluntariado Corporativo</div>
      <div className="text">
        Una de las mejores herramientas para compañías que deseen ser parte
        de...
      </div>
      <div className="cards-container">
        <Card
          text="Que gran experiencia dar una mano en..."
          source="Ana María, Head of RRHH"
          company="Coca-Cola"
          img={logo}
        />
        <Card
          text="Que gran experiencia dar una mano en..."
          source="Ana María, Head of RRHH"
          company="Coca-Cola"
          img={logo}
        />
        <Card
          text="Que gran experiencia dar una mano en..."
          source="Ana María, Head of RRHH"
          company="Coca-Cola"
          img={logo}
        />
      </div>
      <Button type="primary" ghost>
        <Link to="" target="_blank">
          Conocé más
        </Link>
      </Button>
    </div>
  );
};

export default VoluntariadoCorporativo;
