import React from "react";
import OngCreacion from "../../../../../components/ongs/ongCreacion";
import "./nuevaong.css";

const NuevaONG = () => {
    return (
        <div id="ongCreator">
          <div className="bar" />
          <OngCreacion />
        </div>
  );
};

export default NuevaONG;
