import React from "react";
import Header from "../../components/header/Header";
import MainLayout from "../../components/main";
import "./index.css";
export const TermsAndConditions = () => {
  return (
    <MainLayout stickyFooter={true}>
      <Header />
      <main className="terms main">
        <div className="main-box">
          <h1 className="title terminos">
            TÉRMINOS Y CONDICIONES DE INVOLUCRATE
          </h1>
          <div id="wrap">
            <div className="texto">
              <p>
                <i>
                  {" "}
                  Todas las personas y organizaciones (de ahora en más USUARIOS)
                  que utilicen el sitio web www.involucrate.uy (de ahora en más
                  el SITIO) o cualquier URL que derive hacia www.involucrate.uy
                  dejan constancia a través de su registro que aceptan los
                  Términos y Condiciones de Involucrate.{" "}
                </i>
              </p>
              <p>
                Involucrate es un movimiento con el objetivo de contribuir al
                desarrollo social a través de impulsar causas como la búsqueda
                de la igualdad de oportunidades para todas las personas,
                defender los derechos humanos, promover la cultura, proteger el
                medioambiente y los animales otros objetivos altruistas
                relacionados. Será el punto de encuentro de personas y
                organizaciones con buenas intenciones, para realizar buenas
                acciones. El SITIO busca promocionar las acciones solidarias en
                Uruguay a través de la publicación de oportunidades de
                voluntariado y necesidades de donaciones de las Organizaciones
                sociales. De ahora en más, nos referiremos al voluntario,
                voluntaria y/o donante que busca oportunidades en el SITIO como
                INDIVIDUO o INDIVIDUOS, y a las organizaciones sociales,
                agrupaciones o instituciones que publican oportunidades en el
                SITIO como ORGANIZACIÓN U ORGANIZACIONES.
              </p>
              <b>Referente al voluntario, voluntaria y/o donante</b>
              <p>
                Involucrate promueve que el INDIVIDUO se involucre en la causa
                que persiguen las organizaciones y que su acción sea más que una
                simple ayuda. El INDIVIDUO utilizará el SITIO con un fin
                altruista, con objetivos como el de contribuir en el desarrollo
                social a través de la búsqueda de igualdad en oportunidades,
                defender los derechos humanos, promover la cultura, proteger el
                medioambiente y los animales u otros objetivos relacionados.
                Todo INDIVIDUO que esté dispuesto a suministrar recursos a las
                organizaciones que publican sus necesidades en Involucrate (sea
                tiempo de voluntariado o donaciones materiales) acepta no tener
                derecho a ningún reclamo de recompensa por el tiempo o
                materiales brindados a la ORGANIZACIÓN. En especial, acepta que
                no existe un vínculo laboral entre el INDIVIDUO y la
                ORGANIZACIÓN. Los INDIVIDUOS deben cumplir con los compromisos
                asumidos y respetar los fines y normativas de la ORGANIZACIÓN.
                También asumirán la responsabilidad de participar de las
                capacitaciones proporcionadas por la organización. El INDIVIDUO,
                luego de haber realizado un voluntariado se compromete a
                realizar un comentario sobre la ORGANIZACIÓN. Dicho comentario
                debe contribuir con el desarrollo social y los fines que
                persigue Involucrate. El voluntario será especialmente cuidadoso
                cuando las experiencias no fueron del todo positivas, midiendo
                la verdadera responsabilidad que tuvo la organización y las
                posibilidades reales que tenía la ORGANIZACIÓN para revertirlo.
                El INDIVIDUO se compromete a mantener confidencialidad sobre
                datos y base de datos dispensado por la ORGANIZACIÓN con el fin
                de realizar la actividad o donación acordada. Si el INDIVIDUO
                nota que otro INDIVIDUO u ORGANIZACIÓN no cumple con los
                Términos y Condiciones de Involucrate, debe escribirnos a
                hola@involucrate.uy para comunicarlo. Es importante que entre
                todos cuidemos el uso que se le da a Involucrate para que la
                plataforma sea realmente efectiva.
              </p>
              <b>Referente a las Organizaciones </b>
              <p>
                Promovemos que las ORGANIZACIONES hagan el esfuerzo de mantener
                a los voluntarios y donantes involucrados en la causa y que no
                solo tomen sus recursos como una simple ayuda. Las publicaciones
                de la ORGANIZACIÓN en el SITIO deben ser para cumplir con un fin
                altruista. En especial, deben estar alineadas con el objetivo de
                Involucrate expresado en este reglamento. Está prohibido el uso
                del SITIO con fines comerciales. La ORGANIZACIÓN sólo podrá
                publicar oportunidades de voluntariado o necesidades de
                donaciones materiales. En especial, no podrán publicar ofertas
                laborales. La ORGANIZACIÓN deberá presentar las oportunidades de
                forma clara y concisa, explicando cuales serán las tareas que
                tendrá que desarrollar el INDIVIDUO y cuál es el fin que
                persigue la ORGANIZACIÓN. La ORGANIZACIÓN se compromete a
                contestar toda solicitud de voluntariado o donaciones recibida,
                aún en el caso de que el INDIVIDUO no sea seleccionado por la
                ORGANIZACIÓN. Si un INDIVIDUO entra en una preselección y no es
                seleccionado, el proceso también deberá finalizar con la
                comunicación explícita de la resolución. La ORGANIZACIÓN no
                podrá hacer público los datos que recibe de los INDIVIDUOS ni
                compartirlos con terceros. La ORGANIZACIÓN se compromete a hacer
                todo lo que esté a su alcance para mantener la confidencialidad
                de los datos recibidos a través del SITIO. La ORGANIZACIÓN
                acepta no utilizar los datos de contacto de los candidatos
                recibidos a través de Involucrate para enviar emails
                informativos generales, correos no deseado, o con otro motivo
                diferente a la postulación del INDIVIDUO, salvo aceptación
                expresa por parte del INDIVIDUO. La ORGANIZACIÓN se compromete a
                confirmar las horas que realiza cada INDIVIDUO. También se
                comprometen a realizar un comentario sobre el INDIVIDUO, que
                podrá ser utilizado como referencia por otras organizaciones
                donde el INDIVIDUO quieran participar. De forma de
                retroalimentar la información, la ORGANIZACIÓN se compromete a
                publicar el llamado de Involucrate en sus redes sociales, para
                también captar la atención a sus seguidores. Si la ORGANIZACIÓN
                nota que otra ORGANIZACIÓN o INDIVIDUO no cumple con los
                Términos y Condiciones de Involucrate, debe escribirnos a
                hola@involucrate.uy para comunicarlo. Es importante que entre
                todos cuidemos el uso que se le da a Involucrate para que la
                plataforma sea realmente efectiva.
              </p>
              <b>Involucrate se reserva el derecho de: </b>
              <div>
                <ul className="styled-list">
                  <li>
                    Comprobar la veracidad de los datos de las ORGANIZACIONES e
                    INDIVIDUOS que se registren en el SITIO y a no dar acceso al
                    SITIO a aquellos USUARIOS que no puedan ser verificados.
                  </li>
                  <li>
                    Dar de baja a todo USUARIO que NO cumplan con este
                    reglamento.
                  </li>
                  <li>
                    Eliminar cualquier publicación que NO cumpla con este
                    reglamento.
                  </li>
                  <li>
                    Revisar y no publicar comentarios negativos en el SITIO
                    escritos por los USUARIOS.
                  </li>
                  <li>
                    Publicar toda la información que esté en el SITIO en otros
                    sitios web, redes sociales u otros soportes de contenidos ya
                    sea con el fin de difundir oportunidades puntuales o
                    promocionar el SITIO.
                  </li>
                  <li>
                    Tener la competencia exclusiva de la interpretación de los
                    Términos y condiciones de Involucrate y modificarlo en
                    cualquier momento sin previo aviso o notificación.
                  </li>
                  <li>
                    Los Términos y Condiciones podrán ser modificados y
                    actualizados por Involucrate sin notificación previa al
                    USUARIO ni a ninguna otra persona física o jurídica. Es
                    responsabilidad del USUARIO verificar periódicamente
                    cualquier alteración que pudiera realizarse sobre los
                    Términos y Condiciones. Los Términos y Condiciones
                    modificados se consideran aceptados por el USUARIO en caso
                    de silencio al respecto por parte de éste en un plazo
                    superior a los 15 días desde su publicación.
                  </li>
                </ul>
              </div>
              <b>
                Los USUARIOS aceptan expresamente que Involucrate no asume
                responsabilidad por:
              </b>
              <p>
                <ul className="styled-list">
                  <li>
                    Daños y perjuicios derivados del mal uso del SITIO o sus
                    contenidos realizado por el Usuario o terceros.
                  </li>
                  <li>
                    Los daños, infracciones, delitos, contravenciones o
                    cualquier otra clase de ilícitos que pudieran cometerse, ya
                    se trate de derechos de propiedad intelectual, al honor y
                    reputación de las personas, sus datos personales,
                    crediticios, derechos del consumidor, ni cualquier otro
                    derecho que pudiera ser vulnerado como consecuencias del mal
                    uso del Sitio por parte de los USUARIOS.
                  </li>
                  <li>
                    Cualquier contenido del SITIO, incluyendo, pero sin
                    limitarse, a cualquier error u omisión en cualquier
                    contenido, o por la pérdida o daño de cualquier contenido.
                    INVOLUCRATE no garantiza el contenido preciso de la
                    información introducida en el SITIO.
                  </li>
                  <li>
                    Los daños causados a raíz de la falla o cualquier
                    discontinuidad en el SITIO.
                  </li>
                  <li>
                    Los daños y perjuicios que pueda sufrir cualquier USUARIO
                    y/o tercero como consecuencia del uso del SITIO. En
                    especial, INVOLUCRATE no será responsable por los daños y
                    perjuicios que pueda sufrir cualquier USUARIO y/o tercero
                    derivados de la información introducida en el SITIO, como
                    ser, por ejemplo, daños derivados de la falta de veracidad
                    respecto de la existencia o localización de una ORGANIZACIÓN
                    que figura en el mapa y daños derivados de la relación entre
                    el INDIVIDUO o un tercero y una ORGANIZACIÓN y/o sus
                    dependientes y/o quienes aparentan serlo derivada de la
                    información obtenida del SITIO.
                  </li>
                  <li>
                    Los términos y condiciones de links de webs externas
                    publicadas en el SITIO pero que no pertenecen a Involucrate.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </main>
    </MainLayout>
  );
};
