import React from "react";
import { Sede } from "../../../utils/entities/Sede";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

interface DireccionProps {
  sede: Sede;
}

function Direccion(props: DireccionProps) {
  const sede: Sede = props.sede;

  return (
    <div className="direccion">
      <div className="subtitulo">Dirección</div>
      <div className="direccion-container">
        <div className="icon">
          <FontAwesomeIcon icon={faLocationDot} />
        </div>
        <div className="data">
          <div>{sede?.description}</div>
          <div>Horarios: {sede?.time}</div>
        </div>
      </div>
    </div>
  );
}
export default Direccion;
