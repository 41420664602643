import React, { useState } from "react"
import { Button, Form, Input, Modal } from "antd"
import { AgregarModalProps } from "../../../types/AgregarModalProps"

const AgregarModal = ({ title = "Agregar", addModalVisible, handleAddModalClose, handleAdd, nombre, onInputChange }: AgregarModalProps) => {
    const [blank, setBlank] = useState(true);
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onInputChange(event)
        if (event?.target.value === "") {
            setBlank(true);
            return
        }
        setBlank(false)
    }
    return (
        <Modal
            visible={addModalVisible}
            title={title}
            onCancel={handleAddModalClose}
            footer={[
                <Button key="cancel" onClick={handleAddModalClose}>
                    Cancelar
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleAdd}
                    disabled={blank}
                    title={blank ? "Ingrese un nombre para poder guardar" : "Guardar nueva categoria"}
                >
                    Guardar
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <Form.Item label="Nombre">
                    <Input
                        value={nombre}
                        name="nombre"
                        onChange={onChange}
                    />
                    {
                        (blank)
                        &&
                        <span style={{ color: "red", marginTop: 1 }}>
                            Por favor ingrese un nombre
                        </span>
                    }
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AgregarModal;