import React, { useState } from "react";
import "./administradores.css";
import Layout from "./components/UI/Layout";
import UserTable from "./components/tables/UserTable";

const Administradores = () => {

  return (
    <Layout title="Usuarios del BackOffice">
      <UserTable />
    </Layout>
  )
};

export default Administradores;
