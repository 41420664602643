import React, { ChangeEvent, useEffect, useState } from "react";
import { EditarModalProps } from "../../../types/EditarModalProps";
import { Button, Form, Input, Modal } from "antd";

const EditarModal = ({ title = "Editar", editModalVisible, handleEditModalClose, handleEdit, selectedCategoria, nombre, onInputChange }: EditarModalProps) => {
    const [blank, setBlank] = useState(false);
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onInputChange(event)
        if (event?.target.value === "") {
            setBlank(true);
            return
        }
        setBlank(false)
    }
    useEffect(() => {
        if (selectedCategoria) {
            const change = { target: { value: selectedCategoria.nombre, name: "nombre" } } as ChangeEvent<HTMLInputElement>;
            onInputChange(change)
        }
    }, [editModalVisible])
    return (
        <Modal
            visible={editModalVisible}
            title={title}
            onCancel={handleEditModalClose}
            footer={[
                <Button key="cancel" onClick={handleEditModalClose}>
                    Cancelar
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleEdit}
                    disabled={blank}
                    title={blank ? "Ingrese un nombre para poder guardar" : "Guardar categoria editada"}
                >
                    Guardar
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <Form.Item label="Nombre">
                    <Input
                        defaultValue={selectedCategoria?.nombre}
                        value={nombre}
                        name="nombre"
                        onChange={onChange}
                    />
                    {
                        (blank)
                        &&
                        <span style={{ color: "red", marginTop: 1 }}>
                            Por favor ingrese un nombre
                        </span>
                    }
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditarModal;