import React, { Fragment } from 'react';

interface Content {
  id: number;
  title: string;
  body: JSX.Element;
}

interface TabFormat {
  key: string;
  title: string;
  content: Content[];
}

const FaqContent: TabFormat[] = [
  {
    key: 'about',
    title: 'Sobre Involucrate',
    content: [
      {
        id: 1,
        title: '¿Qué es Involucrate?',
        body: (
          <Fragment>
            Involucrate es un portal de voluntariado y donaciones que busca ser
            una plataforma de nexo y acercamiento.<br /><br />
            Puedes ver <a href='https://www.youtube.com/watch?v=CQGV_pMnvCs' target='_blank' rel='noopener noreferrer'>este video</a> para más información.
          </Fragment>
        ),
      },
      {
        id: 2,
        title: '¿Quiénes Somos?',
        body: (
          <Fragment>
            Somos un grupo de voluntarios convocados por el{' '}
            <strong>Fondo para Emprendimientos Solidarios</strong> y creemos en la
            importancia de la solidaridad, el compromiso y la acción en el
            desarrollo de la sociedad y en el desarrollo personal. De diferentes
            perfiles y diferentes edades, todos aportamos para generar un nexo
            entre las organizaciones y las personas que buscan el bien
            común.Somos un grupo abierto en continuo crecimiento que{' '}
            <strong>busca la manera más efectiva de aportar positivamente a la sociedad</strong>.
          </Fragment>
        ),
      },
      {
        id: 3,
        title: '¿Que buscamos?',
        body: (
          <Fragment>
            Buscamos generar una comunidad más integrada. Donde todas las
            personas se involucren en diferentes causas sociales y ambientales,
            promoviendo la solidaridad, el desarrollo social y el desarrollo
            personal a través de diferentes experiencias. Creemos que la
            realidad se transforma con acciones, por eso facilitamos un mapa
            donde las personas pueden encontrar cómo ponerse en acción por sus
            ideales.
          </Fragment>
        ),
      },
      {
        id: 4,
        title: '¿Cómo funciona?',
        body: (
          <Fragment>
            Las organizaciones postean de forma gratuita y libre sus necesidades
            de donaciones como de voluntariado. Las personas ingresan a
            Involucrate y encuentran, mediante filtros, la posibilidad de
            involucrarse que más se adapte a su vida y sus ideales.
          </Fragment>
        ),
      },
    ],
  },
  {
    key: 'organizations',
    title: 'Para Organizaciones',
    content: [
      {
        id: 1,
        title: 'Quiero postular oportunidades de voluntariado. ¿Cómo funciona?',
        body: (
          <Fragment>
            Como Organización primero debes registrarte y postear tus
            oportunidades de voluntariado. Si tienes preguntas sobre cómo
            registrarte, <a href='https://www.youtube.com/watch?v=v0-23-RPNYE' target='_blank' rel='noopener noreferrer'>este video</a>{' '}
            es bien explicativo. La plataforma es libre y gratuita. También
            puedes revisar este <a href='https://drive.google.com/open?id=1O7WB5LaoXMlqaA8ijaAPOFD-Jjfg6xaC' target='_blank' rel='noopener noreferrer'>tutorial para registrarse como organización</a>{' '}
            o <a href='https://drive.google.com/file/d/1eq4Up7Ou8ghipeQfL_fCE-x2wZt1WI8Y/view?usp=sharing' target='_blank' rel='noopener noreferrer'>tutorial para publicar necesidad</a>.
            <br /><br />
            Un vez que tengas las oportunidades en Involucrate, recibirás una
            notificación y un mail cada vez que alguien se interese en tu
            oportunidad de voluntariado. Recibirás su nombre, contacto y una
            pequeña descripción personal. Debes comunicarte con la persona
            interesada, explicarles sobre la organización, las actividades que
            hay que realizar y convenir si es la persona adecuada para llevar
            adelante el voluntariado.
            <br /><br />
            Una vez que comience el voluntariado, debes aceptarla en la
            plataforma. De esta forma, tendrás la lista de todos los voluntarios
            que tengas en tu organización de forma fácil para llevar el
            seguimiento.
            <br /><br />
            El voluntario o voluntaria estará actualizando las horas realizadas
            en el voluntariado las cuales debes aceptar para que sean aplicadas
            en su historial personal.
            <br /><br />
            Una vez que finalice el voluntariado, deberás darle de baja en la
            plataforma y se te creará un historial con todos los voluntarios que
            se han involucrado en tu organización.
            <br /><br />
            Como es una herramienta colaborativa, debes dejar un comentario
            sobre la voluntaria o voluntario, el cual será referencia para las
            próximas instituciones a que se postule.
            <br /><br />
            <strong>Nota:</strong> toda persona que se contacta por una oportunidad de
            voluntariado deben estar registradas en Involucrate.
          </Fragment>
        ),
      },
      {
        id: 2,
        title: 'Quiero publicar que recibo donaciones materiales. ¿Cómo funciona?',
        body: (
          <Fragment>
            Como Organización primero debes registrarte y postear tus
            oportunidades de voluntariado. Si tienes preguntas sobre cómo
            registrarte, <a href='https://www.youtube.com/watch?v=v0-23-RPNYE&&t=4s' target='_blank' rel='noopener noreferrer'>este video</a>{' '}
            es bien explicativo. La plataforma es libre y gratuita. También
            puedes revisar este <a href='https://drive.google.com/open?id=1O7WB5LaoXMlqaA8ijaAPOFD-Jjfg6xaC' target='_blank' rel='noopener noreferrer'>tutorial para registrarse como organización</a>
            o <a href='https://drive.google.com/file/d/1eq4Up7Ou8ghipeQfL_fCE-x2wZt1WI8Y/view?usp=sharing' target='_blank' rel='noopener noreferrer'>tutorial para publicar necesidad</a>.
            <br /><br />
            Cada vez que alguien quiera donar algo a tu organización, te enviará
            un mail o llamará por teléfono.
            <br /><br />
            Nota: las personas que quieren donar, no necesitan estar registradas
            en Involucrate para obtener los datos de la organización.
          </Fragment>
        ),
      },
      {
        id: 3,
        title: 'Soy una Organización y quiero inscribirme, ¿Cómo hago?',
        body: (
          <Fragment>
            Para inscribirte como organización, sigue los pasos de{' '}
            <a href='https://www.youtube.com/watch?v=v0-23-RPNYE&&t=4s' target='_blank' rel='noopener noreferrer'>este video</a>.
            Si prefieres, también puedes ver <a href='https://drive.google.com/open?id=1O7WB5LaoXMlqaA8ijaAPOFD-Jjfg6xaC' target='_blank' rel='noopener noreferrer'>
            este tutorial</a> que muestra el paso a paso. ¡Mucha suerte! Y si tienes alguna duda
            contactate con nosotros vía email a <a href='mailto:hola@involucrate.uy'>hola@involucrate.uy</a>.
          </Fragment>
        ),
      },
      {
        id: 4,
        title: 'Soy una Organización y no puedo ingresar con mi usuario y contraseña.',
        body: (
          <Fragment>
            Asegurate de estar ingresando primero que nada en la pestaña Mi ONG
            (y no ingresar donde ingresan los voluntarios y voluntarias). Si aún
            no puedes ingresar, pide una nueva contraseña haciendo click en
            “Recuperar mi contraseña”. Si aún tienes problemas, no dudes en
            contactarnos a <a href='mailto:hola@involucrate.uy'>hola@involucrate.uy</a> y te
            ayudaremos a solucionar nuestro problema.
          </Fragment>
        ),
      },
      {
        id: 5,
        title: '¿Por qué tengo que validar las horas de los voluntarios?',
        body: (
          <Fragment>
            La validación de horas queda registrada en el historial del
            voluntario. De esta forma el voluntario puede tener un registro de
            todos los lugares donde estuvo involucrado y las horas que desempeñó
            en él, validado por la organización. Esto le puede servir en un
            futuro ya sea para el armado de un curriculum u otras
            funcionalidades que tenga la plataforma en el futuro.
            <br /><br />
            El beneficio para la ONG es dejar constancia de las horas realizadas
            fueron como voluntario o voluntaria, sin tener derecho a ningún
            reclamo posterior.
          </Fragment>
        ),
      },
    ],
  },
  {
    key: 'volunteers',
    title: 'Para Voluntarios',
    content: [
      {
        id: 1,
        title: '¿Cómo hago para registrarme como voluntaria o voluntario?',
        body: (
          <Fragment>
            Para registrarte debes ingresar en: “ingresar” (botón de arriba a la
            derecha). Podes ingresar a través de Facebook, Google o con una
            dirección de email.
            <br /><br />
            Una vez registrada podrás ingresar más información sobre ti. Esta
            información solo la podrá ver las organizaciones con las que tú
            quieras ponerte en contacto.
          </Fragment>
        ),
      },
      {
        id: 2,
        title: '¿Cómo hago para ser voluntaria sin estar registrada?',
        body: (
          <Fragment>
            Para anotarte en una oportunidad de voluntariado debes estar
            registrada.
            <br /><br />
            Involucrate es una plataforma colaborativa, esto quiere decir que se
            maneja con datos que todos los usuarios de la plataforma suben. Así
            como los voluntarios suben sus comentarios sobre las organizaciones
            para que lo vean otros voluntarios, también las organizaciones dejan
            sus comentarios sobre los voluntarios para que la vean otras
            organizaciones. Tanto las organizaciones como los voluntarios deben
            estar registrados para que la plataforma cumpla con su cometido
            colaborativo.
          </Fragment>
        ),
      },
      {
        id: 3,
        title: '¿Tengo que poner un comentario sobre la organización?',
        body: (
          <Fragment>
            Los comentarios sobre la organización serán mostrados a aquellas
            personas que estén buscando realizar un voluntariado en esa
            organización.
            <br /><br />
            Involucrate es una plataforma colaborativa. Toda la información que
            puedas poner sobre la organización, será de ayuda para otros
            voluntarios. Además, al ser voluntaria, podrás conocer a la
            organización de más adentro, por lo que tendrás una visión súper
            valiosa para compartir. Generalmente, los comentarios incentivan a
            las personas a realizar voluntariado en esa organización.
          </Fragment>
        ),
      },
      {
        id: 4,
        title: 'Me registré como Voluntaria/o ¿Quién puede ver mi información?',
        body: (
          <Fragment>
            Tu información solo será compartida con las organizaciones que hayas
            querido involucrarte. Involucrate también utilizará tus preferencias
            para presentarte las oportunidades que más te interesen. Bajo ningún
            concepto Involucrate compartirá tus datos personales con terceras
            partes de la que tú no hayas manifestado interés.
          </Fragment>
        ),
      },
      {
        id: 5,
        title: '¿Quiero involucrarme en una oportunidad de voluntariado, pero la organización no contesta?',
        body: (
          <Fragment>
            Muchas veces quien maneja la parte de voluntariado de una
            organización también tiene asignadas otras tareas. Es por eso que es
            prudente esperar unos días para recibir una respuesta. Si en ese
            tiempo no se contactan, intenta comunicarte directamente con ellos,
            indicando que te contactaste a través de Involucrate. Si aun así no
            logras el contacto, escribínos a hola@involucrate.uy. y veremos te
            ayudaremos a solucionar la comunicación.
          </Fragment>
        ),
      },
    ],
  },
];

export default FaqContent;
