import React from 'react';
import Card from './components/Card';
import { CardI } from './models';

const cards: CardI[] = [
  {
    imagen: 'img1',
    titulo: 'Corona Solidaria',
    texto: 'Eventos, acciones y programas',
    linkAnchorProps: {
      href: 'https://www.involucrateeventos.uy/',
      target: '_blank',
    },
  },
  {
    imagen: 'img2',
    titulo: 'Dónde Donar',
    texto: 'Encontrá lugares donde donar',
    linkAnchorProps: {
      href: '/donar',
    },
  },
  {
    imagen: 'img3',
    titulo: 'Donde Ayudar',
    texto: 'Encontrá donde hacer voluntariado',
    linkAnchorProps: {
      href: '/voluntariado',
    },
  },
];

const MainSection = () => {
  return (
    <div id="h-mainSection">
      {cards.map((item, i) => {
        return <Card card={item} key={i} />;
      })}
    </div>
  );
};

export default MainSection;
