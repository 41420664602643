import { useState, useEffect } from "react";
import { getEvents } from "../../../utils/constants";
import ajaxAction, { Config } from "../../../utils/ajaxAction";
import { arrayEventDtoToEntity } from "../../../utils/mappers/EventsMapper";
import { Event } from "../../../utils/entities/Event";

const configEvents: Config = {
  service: getEvents,
  method: "GET",
};

const useEvents = (selected: string[]) => {
  const [events, updateEvents] = useState([] as Event[]);

  useEffect(() => {
    ajaxAction(configEvents)
      .then(async (response) => {
        const data = await response.json();
        const eventos = arrayEventDtoToEntity(data);
        let filtered: Event[] = [];
        eventos.forEach((event) => {
          if (isCategorieSelected(event)) {

            filtered.push(event);
          }
        });
        updateEvents(filtered);
      })
      .catch((err) => console.log(err));
  }, [selected]);

  function isCategorieSelected(event: Event): boolean {
    for (let i = 0; i < event.categories.length; i++) {
      const categorie = event.categories[i];
      for (let j = 0; j < selected.length; j++) {
        const element = selected[j];
        if (element === categorie.id.toString()) {
          return true;
        }
      }
    }
    return false;
  }

  return events;
};

export default useEvents;
