import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/plots";
import ajaxAction from "../../../../utils/ajaxAction";
import { Button, Col, Row, Select } from "antd";
import moment from "moment";
import Header from "../../../header/Header";
const { Option } = Select;

interface Stats {
  id: number;
  ong_id: number;
  cantidad: number;
  fecha: string;
  origen: string;
  ong: {
    name: string;
  };
}

const Stats: React.FC = () => {
  const [ongs, setOngs] = useState<[Stats] | []>([]);
  const [selectedOng, setSelectedOng] = useState<number | null | undefined>(
    null
  );
  const [filtro, setFiltro] = useState<string | null>("donacion");
  const [filtroContacto, setFiltroContacto] = useState<string | null>(
    "whatsapp"
  );
  const [chartData, setChartData] = useState<any>([]);
  let chart: any;

  const contactCategories = [
    { name: "WhatsApp", value: "whatsapp" },
    { name: "Email", value: "email" },
    { name: "Twitter", value: "twitter" },
    { name: "Facebook", value: "facebook" },
    { name: "Teléfono", value: "telefono" },
  ];

  const categories = [
    { name: "Donación", value: "donacion" },
    { name: "Voluntariado", value: "voluntariado" },
  ];

  useEffect(
    () => {
      ajaxAction({
        method: "GET",
        service: `/api2/statistics/getAllOngs/${filtro}/${filtroContacto}`,
      })
        .then((response) => {
          return response.json();
        })
        .then((ongs: [Stats]) => {
          setOngs(ongs);

          return ongs;
        });
    },
    [filtro, filtroContacto]
  );

  const config = {
    data: chartData,
    //width: 800,
    //height: 400,
    xField: "date",
    yField: "value",
    point: {
      size: 5,
      shape: "diamond",
    },
    label: {
      style: {
        fill: "#aaa",
      },
    },
    autoFit: true, // for responsive design
  };

  const handleChartPreps = () => {
    ajaxAction({
      method: "GET",
      service: `/api2/statistics/${filtro}/${filtroContacto}/getByOng/${selectedOng}`,
    })
      .then((response) => {
        return response.json();
      })
      .then((ongs: any) => {
        let data = ongs.map((ong: any) => {
          return {
            date: moment(ong.fecha, "YYYYMMDD").format("DD/MM/YYYY"),
            value: ong.cantidad,
          };
        });
        setChartData(data);
        return ongs;
      });
  };

  const handleChange = (value: any) => {
    setSelectedOng(value);
  };

  const handleChangeFilter = (value: any) => {
    setFiltro(value);
    setSelectedOng(undefined);
  };

  const handleChangeFilterContact = (value: any) => {
    setFiltroContacto(value);
    setSelectedOng(undefined);
  };

  return (
    <div>
      <Header />

      <div style={{ background: "#f5f5f5" }}>
        <Row>
          <Col span={12} offset={6} style={{ marginBottom: "5px" }}>
            <h1>Estadísticas de Contacto</h1>
          </Col>
        </Row>

        <Row>
          <Col span={12} offset={6} style={{ marginBottom: "5px" }}>
            <Select
              style={{ width: "75%" }}
              placeholder="Selecciona una categoria"
              defaultValue={["donacion"]}
              onChange={handleChangeFilter}
              optionLabelProp="label"
            >
              {categories.map((category) => (
                <Option value={category.value} label={category.name}>
                  <div className="demo-option-label-item">{category.name}</div>
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={12} offset={6} style={{ marginBottom: "5px" }}>
            <Select
              style={{ width: "75%" }}
              placeholder="Selecciona un Medio de Contacto"
              defaultValue={"whatsapp"}
              onChange={setFiltroContacto}
              optionLabelProp="label"
            >
              {contactCategories.map((category) => (
                <Option value={category.value} label={category.name}>
                  <div className="demo-option-label-item">{category.name}</div>
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={12} offset={6}>
            <Select
              //mode="multiple"
              style={{ width: "75%" }}
              placeholder="Selecciona una ONG"
              value={selectedOng}
              onChange={handleChange}
              optionLabelProp="label"
            >
              {ongs.map((ong: Stats) => {
                return (
                  <Option key={ong.id} value={ong.ong_id} label={ong.ong.name}>
                    {ong.ong.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>

        <br />

        <Row style={{ marginBottom: "15px" }}>
          <Col span={12} offset={6}>
            <Button type="primary" size="large" onClick={handleChartPreps}>
              Generar gráfico
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={12} offset={6}>
            <Line
              {...config}
              onReady={(chartInstance) => (chart = chartInstance)}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Stats;
