import { useState, useEffect } from "react";
import ajaxAction, { Config } from "../../../utils/ajaxAction";
import { getONGs } from "../../../utils/constants";
import { Params } from "../models/params";
import { paramsDtoToEntity } from "../mappers/paramsMapper";

const useAllOngs = (status: string, searchTitle: string, page: number) => {
  const [params, setParams] = useState<Params>();

  useEffect(
    () => {
      const config: Config = {
        service: `${getONGs}?status=${status}&page=${page}&size=10&name=${searchTitle}`,
        method: "GET",
        auth: "",
      };

      ajaxAction(config)
        .then(async (response: any) => {
          const data = await response.json();
          setParams(paramsDtoToEntity(data));
        })
        .catch((err: any) => console.log(err));
    },
    [status, searchTitle, page]
  );

  return params;
};

export default useAllOngs;
