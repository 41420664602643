import React from "react";
import { Ong } from "../../../utils/entities/Ong";

const Description = (props: any) => {
  const ong: Ong = props.ong;

  return <div className="descripcion">{ong.description}</div>;
};

export default Description;
