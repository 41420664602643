import React, { useState, useEffect, useReducer, useRef } from "react";
import { arrActionsTypes } from "../ongEdicion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "antd";
import NavigationFW from "../formWrappers/navigationFW";

const Navigation = (props: any) => {
  const form = props.form;
  const tabs: ((props: any) => JSX.Element)[] = props.tabs;
  const data = props.data;
  const setAction = props.setAction;
  const setFutureTabIndex = props.setFutureTabIndex;
  const navigationRef = useRef<HTMLDivElement | null>(null);
  const buttonsRef = useRef<HTMLButtonElement[]>([]);
  const [currentTabNumber, setCurrentTabNumber] = useState<number>(0)
  const activeTabIndex = props.activeTabIndex;

  const moverTabs = (offset: number): void => {
    if (navigationRef && navigationRef.current) {
      navigationRef.current.scrollLeft = offset;
    }
  }

  const reductor = (estado: number, accion: string): number => {
    let estadoRetornar: number = estado;

    switch (accion) {
      case 'Derecha':

        if (estado < buttonsRef.current.length - 1) {
          moverTabs(buttonsRef.current[estado + 1].offsetLeft)
          estadoRetornar = estado + 1;
        }

        break;
      case 'Izquierda':

        if (estado > 0) {
          moverTabs(buttonsRef.current[estado - 1].offsetLeft)
          estadoRetornar = estado - 1;
        }

        break;

      default:
        estadoRetornar = estado
        break;
    }

    setCurrentTabNumber(estadoRetornar)
    return estadoRetornar;

  }

  const estadoInicialNavegacion: number = 0;
  const [estadoNavegacion, actualizarNavegacion] = useReducer(reductor, estadoInicialNavegacion);

  useEffect(() => {
    // se crea un array de los botones que se usan para la navegacion
    buttonsRef.current = Array.from(navigationRef?.current?.querySelectorAll("button")!) as HTMLButtonElement[];
  }, []);

  const getNavigation = () => {
    return (
      <div className="navigation-MiONG-container">
        <Button disabled={currentTabNumber === 0} className="navigation-button-MiONG left-navigation-MiONG" onClick={() => { actualizarNavegacion('Izquierda'); }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <div className="navigation-MiONG">
          <div className="navigation-MiONG-scroll" ref={navigationRef}>
            {tabs.map((Tab: ((props: any) => JSX.Element), index: number) => {
              return (
                <Button
                  className={(activeTabIndex === index) ? 'tab-navigation active' : 'tab-navigation'}
                  key={index}
                  form={form}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    setAction(arrActionsTypes[0]);
                    setFutureTabIndex(index);
                  }}
                  style={{ padding: "0px" }}
                >
                  <Tab
                    actualizarDatos={() => { }}
                    setActiveTabName={() => { }}
                    data={data}
                    modo="registro"
                    fw={NavigationFW}
                  />
                </Button>

              )
            })}
          </div>
        </div>
        <Button disabled={currentTabNumber === (buttonsRef.current.length - 2)} className="navigation-button-MiONG right-navigation-MiONG" onClick={() => { actualizarNavegacion('Derecha') }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
    );
  };

  return <div>{getNavigation()}</div>;
};

export default Navigation;
