import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect } from 'react';
import { customIcon } from '../../../utils/const/iconConfig';
import { createTileLayer } from '../../../utils/const/mapUtils';
import { Sede } from '../../../utils/entities/Sede';

interface MiniMapProps {
  sede: Sede;
}

const MiniMap = ({ sede }: MiniMapProps) => {

  useEffect(() => {
    const map = L.map('map', {
      center: [sede.coordenadas.coordinates[0], sede.coordenadas.coordinates[1]],
      zoom: 12,
      minZoom: 8,
      maxZoom: 15
    });

    map.attributionControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');
    createTileLayer().addTo(map);

    L.marker(
      [sede.coordenadas.coordinates[0], sede.coordenadas.coordinates[1]],
      { icon: customIcon }
    ).addTo(map);

    return () => {
      map.remove();
    };

  }, [sede]);

  return <div id="map" style={{ height: "128px", width: "100%" }} />;
};

export default MiniMap;
