import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import FaqContent from "../../../../utils/faq";
import "./faq.css";

const tabs: JSX.Element[] = FaqContent.map((item) => {
  return (
    <Tab eventKey={item.key} title={item.title} key={item.key}>
      {item.content.map((item) => {
        return (
          <Card className="mb-3" key={item.id}>
            <Card.Header>{item.title}</Card.Header>
            <Card.Body>
              <Card.Text>{item.body}</Card.Text>
            </Card.Body>
          </Card>
        );
      })}
    </Tab>
  );
});

const Faq: React.FC = () => (
  <div id="faq">
    <div className="container">
      <h2 className="my-4">Preguntas Frecuentes</h2>
      <Tabs defaultActiveKey="about" id="faq-tabs" className="mb-3">
        {tabs}
      </Tabs>
    </div>
  </div>
);

export default Faq;
