import { UploadFile } from 'antd';

export interface UploadChangeParam<T = UploadFile> {
  // https://github.com/ant-design/ant-design/issues/14420
  file: T;
  fileList: T[];
  event?: { percent: number };
}

export enum Status {
  Unfinished,
  Loading,
  Success,
  Error,
}

export interface Requeriment {
  tab_name: string;
  requeriments: string[];
}

export interface LatLng {
  lat:number,
  lng:number,
}

export interface Sede {
  city: string,
  region: string,
  street: string,
  number: string,
  coordinates:LatLng,
  pisoDepto?: string,
  postal_code: string
}
