import React, { useEffect } from "react";
import { UserFormProps } from "../../types/UserFormProps";
import { Button, Form, Input, Select } from "antd";
const { Option } = Select;

const UserForm: React.FC<UserFormProps> = ({ user, onSave, onCancel }) => {
    const [form] = Form.useForm();

    const handleFinish = (values: any) => {
        onSave(values);
    };
      // Set the default values using the user prop when the component mounts or the user prop changes
  useEffect(() => {
    form.setFieldsValue({
      ...user,
      state: user?.status, // Set the default value for "state" field to "status" value
    });
  }, [form, user]);

    return (
        <Form form={form} onFinish={handleFinish} initialValues={user!} className="user-form">
            <Form.Item label="ID" name="id" hidden>
                <Input />
            </Form.Item>
            <Form.Item 
                label="Usuario" 
                name="name" 
                rules={
                    [
                        { 
                            required: true, 
                            message: 'Por favor ingrese un nombre para el usuario',
                        }
                    ]
                }
            >
                <Input />
            </Form.Item>
            <Form.Item 
                label="Email" 
                name="email" 
                rules={
                    [
                        { 
                            required: true, 
                            type: 'email',
                            message: 'Por favor ingrese un correo electrónico'
                        }
                    ]
                }
            >
                <Input />
            </Form.Item>
            <Form.Item label="Estado" name="state" rules={[{ required: true }]}>
                <Select defaultValue={user?.status} >
                    <Option value="DISABLED">Deshabilitado</Option>
                    <Option disabled value="DELETED">Eliminado</Option>
                    <Option value="ENABLED">Habilitado</Option>
                </Select>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                    Guardar
                </Button>
                <Button onClick={onCancel}>Cancelar</Button>
            </Form.Item>
        </Form>
    );
};

export default UserForm;