import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import NormalMap from "../../../../../components/volunteering/map/normal_map";
import useEvents from "../../../../../components/volunteering/map/useEvents";
import "./index.css";

const Index = () => {
  const { filtros } = useParams();
  const navigate = useNavigate();

  //Lista de las categorías seleccionadas
  const [categorias, setCategorias] = useState<string[]>([]);

  //Estados para los marcadores (se seleccionan en search y se usan en map)
  const events = useEvents(categorias);

  useEffect(() => {
    const splitedFiltros = filtros!.split("~") as string[];
    splitedFiltros.map((filtro: string) => parseInt(filtro))
    setCategorias(splitedFiltros);
  }, [filtros]);

  return (
    <div id="volunteer-map">
      <div className="volunteer-content">
        <div className="map-page">
          <Button
            className="ant-btn ant-btn-primary regresar-a-busqueda"
            onClick={() => { navigate("/voluntariado") }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>¿Qué causas buscas?</span>
          </Button>
          <NormalMap events={events} />
        </div>
      </div>
    </div>
  );
};

export default Index;
