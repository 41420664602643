import { useState, useEffect } from "react";
import ajaxAction, { Config } from "../../../utils/ajaxAction";
import { getCSVEvents } from "../../../utils/constants";

const useCSVEvents = () => {

  const [events, setEvents] = useState("")

  useEffect(
    () => {
      const config: Config = {
        service: `${getCSVEvents}`,
        method: "GET",
        auth: "",
      };

      ajaxAction(config)
        .then(async (response: any) => {
          const data = await response.text();
          setEvents(data);
        })
        .catch((err: any) => console.log(err));
    },
    []
  );

  return events;
};

export default useCSVEvents;
