import React, { FC } from 'react'
import { Tabs, Card } from 'antd';
import { Tabs as TabsType } from "../../../types/Tabs";

const { TabPane } = Tabs;

type TabGroupProps = {
    activeTab: string;
    handleTabChange: (key: string) => void;
    tabs: TabsType[];
}

const TabGroup: FC<TabGroupProps> = ({ activeTab, handleTabChange, tabs }) => (
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
        {
            tabs.map(
                ({ key, tab, component }) => (
                    <TabPane tab={tab} key={key}>
                        <Card>
                            {component}
                        </Card>
                    </TabPane>
                )
            )
        }
    </Tabs>
);
export default TabGroup;
