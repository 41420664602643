import { OngBasic } from "../models/ongBasic";
import { entityTypeDtoToEntity } from "./entityTypeMapper";

export function ongBasicDtoToEntity(ongBasic: any): OngBasic {
  return {
    id: ongBasic.id,
    email: ongBasic.email,
    phone: ongBasic.phone,
    name: ongBasic.name,
    tipoEntidadId: ongBasic.tipoEntidadId,
    entityType: ongBasic.entityType ? entityTypeDtoToEntity(ongBasic.entityType) : null,
  };
}
