import React from "react";
import { Form, Input, Button, Row, Space, message } from 'antd';

const RecuperarPassONG = () => {
    const [form] = Form.useForm();

    // Validador personalizado para confirmar la contraseña
    const confirmPasswordValidator = (_: unknown, value: string) => {
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('Las contraseñas no coinciden'));
        }
        return Promise.resolve();
    };

    const onFinish = async (values: { password: string }) => {
        const { password } = values;
        const data = { password };
        const url = '/api/ong/cambiarPass';

        try {
            const token = localStorage.getItem('ong-jwt');
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Error en la solicitud.');
            }

            const result = await response.json();
            message.success('¡Contraseña cambiada exitosamente!');
            console.log('Contraseña cambiada exitosamente:', result);

            setTimeout(() => {
                // Realiza la acción que deseas después de 3 segundos
                window.location.href = "/mapa";
            }, 3000);

        } catch (error) {
            message.error('¡Algo salió mal! Por favor, inténtalo de nuevo.');
            console.error('Error al cambiar la contraseña:', error);

        }
    }

    return (
        <>
            <div className="container">
                <div></div>
                <div>
                    <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: "25px" }}>
                       <h2>Cambiar Contraseña</h2>
                        <Row>
                            <Form form={form} onFinish={onFinish}>
                                <Form.Item
                                    name="password"
                                    label="Nueva Contraseña"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingrese la nueva contraseña',
                                        },
                                        {
                                            min: 6,
                                            message: 'La contraseña debe tener al menos 6 caracteres',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    name="confirmPassword"
                                    label="Confirmar Contraseña"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor confirme la contraseña',
                                        },
                                        {
                                            validator: confirmPasswordValidator,
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Cambiar Contraseña
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Row>
                    </Space>
                </div>
                <div></div>
            </div>
        </>
    )

}

export default RecuperarPassONG