import { Params } from "../models/params";
import { ongBasicDtoToEntity } from "./ongBasicMapper";

export function paramsDtoToEntity(params: any): Params {
  return {
    totalItems: params.totalItems,
    totalPages: params.totalPages,
    currentPage: params.currentPage,
    ongs: params.ongs.map((ongBasic: any) => ongBasicDtoToEntity(ongBasic)),
  };
}
