import React, { useEffect } from "react";
import { Form } from "antd";
import { Requeriment } from "../utils/models";

const validateRequirements: (reqs: Requeriment[], data: any) => boolean = (
  reqs,
  data
) => {
  return reqs.every((requirement: Requeriment) => {
    if (!data[requirement.tab_name]) {
      return false;
    } else {
      return requirement.requeriments.some(
        (element: string) => data[requirement.tab_name][element]
      );
    }
  });
};

const desplazarHaciaArriba = () => {
  const element = document.body;
  element.scrollIntoView({ block: "start", behavior: "smooth" });
};

//Form wrapper for creation forms
const CreationFW = (props: any) => {
  const nombre = props.nombre;
  const tituloForm = props.tituloForm;
  const data: any = props.data;
  const formData: any = props.data[nombre] ? props.data[nombre] : {};
  const form = props.form;
  //Función a la que le pasamos los resultados del form y que ella vea qué hace con ellos
  const actualizarDatos = props.actualizarDatos;
  //Función que procesa los datos del formulario
  const procesarDatos = props.procesarDatos;
  //Los requerimientos del formulario
  const requerimientos = props.requerimientos;

  useEffect(() => {
    const cumple_requerimientos = validateRequirements(requerimientos, data);
    if (!cumple_requerimientos) {
      actualizarDatos({ [nombre]: data[nombre] ? data[nombre] : {} });
    }
  }, []);

  return (
    <Form
      name={nombre}
      form={form}
      autoComplete="off"
      initialValues={formData}
      onFinish={(values) => {
        actualizarDatos({ [nombre]: procesarDatos(values) });
      }}
      onFinishFailed={() => desplazarHaciaArriba()}
    >
      <div className="ong-titulo-seccion">{tituloForm}</div>
      {props.children}
    </Form>
  );
};

export default CreationFW;
