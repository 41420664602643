import React from 'react';
import { Space, Row, Table, } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import EditOtherBusiness from './EditOtherBusiness';
import { useSharedSWR } from '../../../contexts/SWRContext';
import OtherBusinessDelete from './OtherBusinessDelete';

interface DataType {
  ubicacion: string;
  nombreResponsable: string;
  apellidoResponsable: string;
  mail: string;
  id: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Ubicacion',
    dataIndex: 'ubicacion',
    key: 'ubicacion',
    render: (item) => item.descripcion,
  },
  /*{
    title: 'Nombre del Responsable',
    dataIndex: 'nombreResponsable',
    key: 'nombreResponsable'
  },
  {
    title: 'Apellido del Responsable',
    dataIndex: 'apellidoResponsable',
    key: 'apellidoResponsable'
  },
  {
    title: 'Email',
    dataIndex: 'mail',
    key: 'mail'
  },*/
  {
    title: 'Acciones',
    key: 'operation',

    render: (_, record) => (
      <>
        <EditOtherBusiness
          formName={"editar-sede-"}
          buttonText="Editar"
          formTitle={"Editar Sede"}
          onFinishForm={() => {

          }}
          sede={record}
        />
        &nbsp;
        <  OtherBusinessDelete
          formName={"editar-sede-"}
          buttonText="Editar"
          formTitle={"Editar Sede"}
          onFinishForm={() => {

          }}
          sede={record}
        />
      </>
    ),
  },

];

const OtherBusiness = (props: any) => {
  const { data, mutate } = useSharedSWR();

  let objetoTabla: any = [];

  if (data && data.sedes) {

    // construyo un objeto nuevo para rellenar la tabla 
    objetoTabla = Array.from(data?.sedes).filter((sede: any) => {
      if (!sede.esCentral) {
        return {
          ...sede,
          ubicacionDescripcion: sede.ubicacion.descripcion,
          responsableNombreCompleto: sede.nombreResponsable + " " + sede.apellidoResponsable
        }
      }
      return;
    })
  }

  return (
    <Row align='middle'>
      <Space align='baseline' wrap>
        {objetoTabla && objetoTabla.length > 0 ?
          (<Table
            bordered
            columns={columns}
            dataSource={objetoTabla}
            pagination={false}
            rowKey={ (record)=> record.id}
            size="large"
          />) : (<p>No hay otras sedes</p>)}
      </Space>
    </Row>

  )
}

export default OtherBusiness;