import React from "react";
import { Outlet } from "react-router";
import Menu from "./components/menu/menu";

const Index = (props: any) => {
  return (
    <div id="frontoffice">
      <Menu />
      <Outlet />
    </div>
  );
};

export default Index;
