import React from 'react'
import { Col, Space, Row, Button, Switch } from 'antd';
import { Select } from 'antd';
import {Input} from '../Input';
import { useSharedSWR } from '../../../contexts/SWRContext';
import fetcher from '../../../../../../../../helpers/fetcher';
import { useForm } from '../../../../../../../../hooks/useFrom';
const { Option } = Select;

const BankData = () => {
  const { data, mutate } = useSharedSWR()
  const { formState, onInputChange, onInputChangeForId, onResetForm } = useForm({})
  const {
    id,
    ongId,
    bankName,
    accountName,
    accountNumber,
    accountType = null,
    currencyDollar = null,
    linkToDonations,

  } = data?.bankingInformations?.at(0) || {};
  const show_banking_information = data?.show_banking_information;

  const handleEditOng = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const body = { ...formState };
    
    try {
      const datum = await fetcher(
        `/api2/ong/bo/bankinfo/${data?.id}`,
        "PATCH",
        body
      );
      
      if (mutate) {
        mutate();
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error: ${error.message}`)
      }
    }
  }
  return (
    <>
      <Row align="middle"  >

        <Space direction='horizontal' size="large" align='baseline' wrap>

          <Space direction='vertical' >
            <Col>
              <label htmlFor="bankName">Nombre del Banco</label>
              <Input name="bankName" defaultValue={bankName} onChange={onInputChange} placeholder="Nombre del banco" />
            </Col>
            <Col >
              <label htmlFor="accountName">Nombre de Cuenta</label>
              <Input name='accountName' defaultValue={accountName} onChange={onInputChange} placeholder="Nombre de la cuenta" />
            </Col>

            <Col>
              <label htmlFor="accountNumber">Número de Cuenta</label>
              <Input name="accountNumber" defaultValue={accountNumber} onChange={onInputChange} placeholder="número de cuenta" />
            </Col>

          </Space>

          <Space direction='vertical'>
            <Col>
              <Row>
                <Col span={24}>
                  <label htmlFor="accountType">Tipo de Cuenta</label>
                </Col>
                <Col span={24}>
                  <Select id="accountType" defaultValue={accountType} onChange={(e) => { onInputChangeForId(e, "accountType") }} style={{ width: '100%' }}>
                    <Option value={null} disabled>Elegir una opción</Option>
                    <Option value="CC">Cuenta corriente</Option>
                    <Option value="CA">caja de ahorro</Option>
                    <Option value="OTRO">Otro tipo</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col span={24}>
                  <label htmlFor="currencyDollar">Moneda</label>
                </Col>
                <Col span={24}>
                  <Select id="currencyDollar" defaultValue={currencyDollar} onChange={(e) => { onInputChangeForId(e, "currencyDollar") }} style={{ width: '100%' }}>
                    <Option value={null} disabled >elegir una opción</Option>
                    <Option value={false}>pesos</Option>
                    <Option value={true}>dólares</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Space>

          <Space direction='vertical'>
            <Col>
              <label htmlFor="linkToDonations">Link externo para Colaborar</label>
              <Input name='linkToDonations' defaultValue={linkToDonations} onChange={onInputChange} placeholder="Ingrese un nombre" />
            </Col>
            <Col>
              <Space direction='vertical' align='center' >
                <label htmlFor="show_banking_information">Datos Bancarios </label>
                <Switch
                  id="show_banking_information"
                  onChange={(e) => { onInputChangeForId(e, "show_banking_information")  }}
                  defaultChecked={show_banking_information}
                  checked={formState.show_banking_information}
                  checkedChildren="Habilitados"
                  unCheckedChildren="Deshabilitados"
                  title="Habilitar datos bancarios"
                />
              </Space>
            </Col>

          </Space>

        </Space>
        <br />
        <span >
          Para recibir donaciones en dinero, primero
          complete los campos de esta sección y la opción
          <strong> datos bancarios  debe decir  <i style={{ color: '#002766' }}>"Habilitados"</i></strong>
        </span>
      </Row>
      <Space style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
        <Button
          type="primary"
          onClick={handleEditOng}
        >
          Guardar
        </Button>
        <Button
          onClick={onResetForm}
        >
          Cancelar
        </Button>
      </Space>
    </>
  )
}

export default BankData;