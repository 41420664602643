import React from "react";
import "./home.css";
import MainSection from "../../../../components/home/mainSection";
import AddONG from "../../../../components/home/addONG";
import VoluntariadoCorporativo from "../../../../components/home/voluntariadoCorporativo";
import Noticias from "../../../../components/home/news";
import Testimonial from "../../../../components/home/testimonial";
import About from "../../../../components/home/about";
import Empresas from "../../../../components/home/empresas";
import Contact from "../../../../components/home/contact";

const Home = () => {
  return (
    <div id="home">
      <div className="section section1">
        <MainSection />
      </div>
      <div className="section section2">
        <AddONG />
      </div>
      <div className="section section3">
        <VoluntariadoCorporativo />
      </div>
      <div className="bar" />
      <div className="section section4">
        <Noticias />
      </div>
      <div className="section section5">
        <Testimonial />
      </div>
      <div className="section section6">
        <About />
      </div>
      <div className="section section7">
        <Empresas />
      </div>
      <div className="section section8">
        <Contact />
      </div>
    </div>
  );
};

export default Home;
