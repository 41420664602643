import React from "react";
import OngEdicion from "../../../../components/ongs/ongEdicion";
import "./index.css";

const Index = () => {
  return (
    <div id="ongEditor">
      <OngEdicion />
    </div>
  );
};

export default Index;
