import React, { useEffect, useState } from "react";
import { Requeriment } from "../utils/models";

const validateRequirements: (reqs: Requeriment[], data: any) => boolean = (reqs, data) => {
    return reqs.every((requirement: Requeriment) => {
        if (!data[requirement.tab_name]) {
            return false
        } else {
            return requirement.requeriments.some((element: string) => data[requirement.tab_name][element])
        }
    });
};

//Form wrapper for creation forms
const NavigationFW = (props: any) => {

    const tituloForm = props.tituloForm;
    const data: any = props.data;
    //Los requerimientos del formulario
    const requerimientos = props.requerimientos;

    const [cumpleRequerimientos, setCumpleRequerimientos] = useState<boolean>(false);

    useEffect(() => {
        setCumpleRequerimientos(validateRequirements(requerimientos, data))
    }, [data]);

    if (cumpleRequerimientos) {
        return (
            <div style={{ padding: "4px 15px" }}>{tituloForm}</div>
        )
    } else {
        return null;
    }

}

export default NavigationFW;