import React from "react";
import { updateStatistics } from "../../../utils/services/StatisticsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Button } from "antd";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { Ong as ongE } from "../../../utils/entities/Ong";
import useOng from "./useOng";

interface RedesSocialesProps {
  ongId: number;
}

const parse_addres = (domain: string, address?: string) => {
  if (address) {
    return address
      .replace("https://", "")
      .replace(`www.${domain}.com/`, "")
      .replace(`${domain}.com/`, "")
      .replace("@", "");
  }
  // Probar con la siguiente regex
  // return url.replace(/(http.:\/\/|www\.(facebook|twitter|instagram)\.com[^\/]*|\?.*)/g, '');
  return "";
};

function RedesSociales(props: RedesSocialesProps) {
  const { ongId } = props;

  const ong: ongE = useOng(ongId);

  if (ong.facebook || ong.instagram || ong.twitter || ong.website) {
    return (
      <div className="redes-sociales">
        <div className="subtitulo">Sitio web y redes sociales</div>
        <div className="redes-container">
          {ong.facebook && (
            <Button
              className="ant-btn ant-btn-primary btn-red btn-facebook"
              onClick={() => {
                updateStatistics(ongId, "facebook", "voluntario");
              }}
              href={ong.facebook}
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
              <span>/{parse_addres("facebook", ong.facebook)}</span>
            </Button>
          )}
          {ong.instagram && (
            <Button
              className="ant-btn ant-btn-primary btn-red btn-instagram"
              onClick={() => {
                updateStatistics(ongId, "instagram", "voluntario");
              }}
              href={ong.instagram}
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
              <span>/{parse_addres("instagram", ong.instagram)}</span>
            </Button>
          )}
          {ong.twitter && (
            <Button
              className="ant-btn ant-btn-primary btn-red btn-twitter"
              onClick={() => {
                updateStatistics(ongId, "twitter", "voluntario");
              }}
              href={ong.twitter}
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
              <span>/{parse_addres("twitter", ong.twitter)}</span>
            </Button>
          )}
          {ong.website && (
            <Button
              className="ant-btn ant-btn-primary btn-red btn-web"
              onClick={() => {
                updateStatistics(ongId, "web", "voluntario");
              }}
              href={ong.website}
              target="_blank"
            >
              <FontAwesomeIcon icon={faGlobe} />
              <span>{ong.website}</span>
            </Button>
          )}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default RedesSociales;
