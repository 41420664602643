import { Coordinates } from "../entities/Coordinates";
import { Sede } from "../entities/Sede";

export function sedeDtoToEntity(sede: any): Sede {
  return {
    id: sede.id,
    address: sede.ubicacion.calle,
    number: sede.ubicacion.numero,
    description: sede.ubicacion.descripcion,
    pisoDepto: sede.ubicacion.pisoDepto,
    time: sede.horarios,
    city: sede.ubicacion.localidad,
    coordenadas: mapCoordenadas(sede.ubicacion.coordenadas),
  };
}

export function sedeListDtoToEntity(sedes: any[]): Sede[] {
  var sedesM: Sede[] = [];
  if (sedes && sedes.length > 0) {
    sedes.forEach((element) => {
      const sede = sedeDtoToEntity(element);
      sedesM.push(sede);
    });
  }
  return sedesM;
}

function mapCoordenadas(coordenadas: any): Coordinates {
  return {
    type: coordenadas.type,
    coordinates: [coordenadas.coordinates[0], coordenadas.coordinates[1]],
  };
}
