import React, { useState, Fragment } from 'react';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import OportunidadForm from "./oportunidadForm";

const OportunidadModal = (props: any) => {

  const formName = props.formName;
  const onFinishForm = props.onFinishForm;
  const buttonStyle = props.buttonStyle;
  const buttonMessage = props.buttonMessage;
  const oportunidad = props.oportunidad;
  const disabled = props.disabled;
  const sedes = props.sedes;
  const sedePrincipal = props.sedePrincipal;
  const categorias = props.categorias;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    //setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Button
        type="primary"
        onClick={showModal}
        style={buttonStyle}
        disabled={disabled}
        className={disabled ? 'btn-disabled' : 'btn-enabled'}
      >
        {buttonMessage}
      </Button>
      <Modal
        key={`${formName}-${oportunidad.titulo}-${isModalVisible}`}
        title={`${oportunidad.titulo || "Agregar oportunidad de voluntariado"}`}
        visible={isModalVisible}
        onOk={handleOk}
        okText="Guardar"
        onCancel={handleCancel}
        cancelText="Cancelar"
        okButtonProps={{ form: formName, htmlType: 'submit' }}
        className="modalStyle"
      >
        <OportunidadForm
          name={formName}
          oportunidad={oportunidad}
          onFinishForm={(oportunidad: any) => {
            onFinishForm(oportunidad);
            setIsModalVisible(false);
          }}
          sedes={sedes}
          sedePrincipal={sedePrincipal}
          categorias={categorias}
          modo={props.modo}
        />
      </Modal>
    </Fragment>
  );
};

export default OportunidadModal;
