import { useState } from 'react';

type UseModalReturnType = [
  boolean, // estado del modal (abierto/cerrado)
  () => void, // función para abrir el modal
  () => void, // función para cerrar el modal
];

const useModal = (): UseModalReturnType => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return [isOpen, openModal, closeModal];
};

export default useModal;