import ajaxAction from "../../utils/ajaxAction";

function post(url: string, data: object, callback: any) {
  return ajaxAction({
    method: "POST",
    data: data,
    service: url,
  })
    .then((response) => response.json())
    .then((response) => callback(response))
    .catch((error) => {
      console.log("request failed", error);
      return error;
    });
}

class AuthService {
  // ONG

  public static loginONG(url: string, email: string, password: string) {
    return post(url, { email, password }, (response: any) => {
      // If the user was authenticated successfully, save a random token to the localStorage
      if (response.authenticated) {
        localStorage.setItem("ong-jwt", response.jwt);
        return [true, response.jwt];
      } else {
        // If there was a problem authenticating the user, show an error on the form
        return [false, response.message];
      }
    });
  }

  public static loggedInFrontOffice() {
    let tokenFromStorage = localStorage.getItem("ong-jwt");
    if (!tokenFromStorage) {
      return Promise.resolve(null);
    } else {
      let url = "/auth2/mefromtoken?token=" + tokenFromStorage;

      return ajaxAction({
        method: "GET",
        service: url,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.jwt) {
            return response;
          } else {
            localStorage.removeItem("ong-jwt");
            return null;
          }
        })
        .catch(function(err) {
          console.log(err);
          localStorage.removeItem("ong-jwt");
          return null;
        });
    }
  }

  // Backoffice

  public static loginBackOffice(url: string, email: string, password: string) {
    return post(url, { email, password }, (response: any) => {
      // If the user was authenticated successfully, save a random token to the localStorage
      if (response.authenticated) {
        localStorage.setItem("jwt", response.jwt);
        return [true, response.jwt];
      } else {
        // If there was a problem authenticating the user, show an error on the form
        return [false, response.message];
      }
    });
  }

  public static logOutBackOffice() {
    localStorage.removeItem("jwt");
  }

  public static logOutFrontOffice() {
    localStorage.removeItem("ong-jwt");
  }

  public static loginToken(url: string, token: string, callback: any) {
    return post(url, { token }, (response: any) => {
      // If the user was authenticated successfully, save a random token to the localStorage
      if (response.authenticated) {
        localStorage.setItem("jwt", response.jwt);
        callback(true, response);
      } else {
        // If there was a problem authenticating the user, show an error on the form
        callback(false, response.message);
      }
    });
  }

  public static loginConfirmation(url: any, token: any, callback: any) {
    return post(url, { token }, (response: any) => {
      if (response.confirmated) {
        callback(true, response.url);
      } else {
        // If there was a problem authenticating the user, show an error on the form
        callback(false, response.message);
      }
    });
  }

  public static forgottenPassOrActivateUser(
    email: string,
    rol: any,
    callback: any
  ) {
    return post("/auth2/forgotpass", { email, rol }, (response: any) => {
      if (response.successful) {
        callback(true, null);
      } else {
        // If there was a problem authenticating the user, show an error on the form
        callback(false, response.message);
      }
    });
  }

  public static loggedInBackOffice() {
    let tokenFromStorage = localStorage.getItem("jwt");
    if (!tokenFromStorage) {
      return Promise.resolve(null);
    } else {
      let url = "/auth2/mefromtoken?token=" + tokenFromStorage;

      return ajaxAction({
        method: "GET",
        service: url,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.jwt) {
            return response;
          } else {
            localStorage.removeItem("jwt");
            return null;
          }
        })
        .catch(function(err) {
          console.log(err);
          localStorage.removeItem("jwt");
          return null;
        });
    }
  }

  public static register(url: string, data: any, callback: any) {
    post(url, data, (response: any) => {
      console.log(response);
      // If the user was successfully registered, log them in
      if (response.registered) {
        localStorage.setItem("jwt", response.jwt);
        callback(true, response.jwt);
      } else {
        // If there was a problem registering, show the error
        callback(false, response.message);
      }
    });
  }

  public static registerConfirmation(url: string, data: any, callback: any) {
    post(url, data, (response: any) => {
      if (response.registered) {
        callback(true, null);
      } else {
        // If there was a problem registering, show the error
        callback(false, response.message);
      }
    });
  }
}

export default AuthService;
