import React, { createContext, useContext } from 'react';
import { KeyedMutator } from 'swr';
import { Ong } from '../types/Ong';


type valueProps = {
    data?: Partial<Ong> | undefined | null;
    isLoading?: boolean;
    mutate?: KeyedMutator<Partial<Ong>>;
}
type ChildrenProps = {
    children: React.ReactNode;
    value: valueProps
}

// Crea el context
const SWRContext = createContext<valueProps>({
    data: null,
    isLoading: true,
    mutate:undefined
});

// Crea el proveedor del contexto
export const SWRProvider = ({ children, value }: ChildrenProps) => (
    <SWRContext.Provider value={value}>
        {children}
    </SWRContext.Provider>
);

// Crea un custom hook para acceder al contexto
export const useSharedSWR = () => useContext(SWRContext);