import { Button, Divider, Modal, Space } from 'antd'
import React, { useState } from 'react'
import fetcher from '../../../../../../../../helpers/fetcher';
import { useSharedSWR } from '../../../contexts/SWRContext';

const OtherBusinessDelete = ({ sede }: any) => {
    const { id, ubicacion } = sede;
    const { mutate } = useSharedSWR();
    const descripcion = ubicacion.descripcion
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    const handleDeleteOng = async (event: React.MouseEvent<HTMLButtonElement>) => {

        try {
            const datum = await fetcher(
                `/api2/ong/bo/sedes/${id}`,
                "DELETE"
            );           
            mutate!()
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Error: ${error.message}`)
            }
        }
    }

    const OtherBusinessDeleteBody = ({ descripcion }: { descripcion: string }) => {
        return (
            <>
                <h6>¿Está seguro que desea eliminar la sede?</h6>
                <p> la sede se encuentra en: {descripcion}</p>
                <Divider />
                <Space style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <Button
                        type="primary"
                        onClick={handleDeleteOng}
                    >
                        Eliminar
                    </Button>
                    <Button
                        onClick={hideModal}
                    >
                        Cancelar
                    </Button>
                </Space>
            </>
        )
    }



    return (
        <>
            <Button onClick={() => showModal()}>{"Eliminar"}</Button>
            <Modal
                width="25%"
                key={`delete-form`}
                title={`${"Eliminar sede"}`}
                visible={isModalVisible}
                footer={null}
                closable={false}
            >
                <OtherBusinessDeleteBody descripcion={descripcion}/>
            </Modal>
        </>
    )
}

export default OtherBusinessDelete

