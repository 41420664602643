import { Category } from "../entities/Category";
import { arraySubcategorieDtoToEntity } from "./SubcategorieMapper";

export function categoryEntityToDto(categoria: any): Category {
  return {
    id: categoria.id,
    name: categoria.nombre,
    subcategories: arraySubcategorieDtoToEntity(categoria.subcategorias),
  };
}

export function arrayCategoryDtoToEntity(cat: any[]): Category[] {
  var list: Category[] = [];
  if (cat) {
    cat.forEach((element) => {
      list.unshift(categoryEntityToDto(element));
    });
  }
  return list;
}
