import React, { useEffect, useState } from "react";
import { Form, Input, Switch } from "antd";
import UploadImage from "./components/uploadImage";
import UploadGallery from "./components/uploadGallery";
import { Config } from "../../../../utils/ajaxAction";
import { getAvailableEmail } from "../../../../utils/constants";
import ajaxAction from "../../../../utils/ajaxAction";
import { facebookRE, instagramRE, twitterRE, websiteRE } from "../../utils/constants";
import "./index.css";
import { Requeriment } from "../../utils/models";

const { TextArea } = Input;

const DatosPrincipales = (props: any) => {

  var debounce = require("debounce-promise");

  const verifyEmail: any = (email: string) => {
    var config: Config = {
      service: getAvailableEmail,
      method: "POST",
      data: { email },
    };
    return new Promise((resolve: any, reject: any) => {
      ajaxAction(config)
        .then(async (response) => {
          const data = await response.json();
          if (data.available) {
            resolve("Email disponible");
          } else {
            reject(
              "Este email ya se encuentra en uso, intentar con otro diferente"
            );
          }
        })
        .catch((err) => console.log(err));
    });
  };

  const nombre: string = "datos-principales-form";
  const requerimientos: Requeriment[] = [];
  const data: any = props.data;
  const tituloForm = "Datos Principales"
  const [form] = Form.useForm();
  const actualizarDatos = props.actualizarDatos;

  const setActiveTabName = props.setActiveTabName;
  useEffect(() => {
    setActiveTabName(nombre)
  }, [])

  const procesarDatos = (valores: any) => {
    return valores
  }

  const FW = props.fw;

  var request = debounce(verifyEmail, 500);

  return (
    <FW
      nombre={nombre}
      tituloForm={tituloForm}
      data={data}
      form={form}
      actualizarDatos={actualizarDatos}
      procesarDatos={procesarDatos}
      requerimientos={requerimientos}
    >
      <Form.Item
        name="name"
        label="Nombre de la ONG"
        className="obligatorio"
        rules={[
          {
            required: true,
            message: "Por favor, ingresar el nombre de la ONG.",
            whitespace: true,
          },
          { type: "string" },
          { max: 50, message: "El nombre no debe exceder los 50 caracteres" },
        ]}
        hasFeedback
      >
        <Input placeholder="Ingresar el nombre de la ONG" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        className="obligatorio"
        rules={[
          {
            required: true,
            message: "Por favor, ingresar un email.",
            whitespace: true,
          },
          {
            type: "email",
            message: "El email ingresado no es válido",
          },
          {
            validator: (_, value) => {
              if (!value || props.modo === "edicion") {
                return Promise.resolve();
              }
              return new Promise((resolve, reject) => {
                request(value)
                  .then((response: any) => {
                    resolve(response);
                  })
                  .catch((error: any) => {
                    reject(error);
                  });
              });
            },
          },
          {
            max: 60,
            message: "El email no debe exceder los 60 caracteres",
          },
        ]}
        hasFeedback
      >
        <Input
          placeholder="Ingresar el email de la ong"
          disabled={props.modo === "edicion"}
        />
      </Form.Item>

      {props.modo === "registro" ? (
        <>
          <Form.Item
            name="password"
            label="Contraseña"
            className="obligatorio"
            rules={[
              {
                required: true,
                message: "Por favor, ingresar una contraseña",
              },
              {
                min: 6,
                message: "La contraseña debe tener al menos 6 caracteres.",
              },
              {
                max: 15,
                message: "La contraseña no debe exceder los 15 caracteres.",
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Ingresar contraseña" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirmar contraseña"
            className="obligatorio"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Por favor, confirmar contraseña",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Las contraseñas ingresadas no coinciden."
                  );
                },
              }),
              {
                min: 6,
                message: "La contraseña debe tener al menos 6 caracteres.",
              },
              {
                max: 15,
                message: "La contraseña no debe exceder los 15 caracteres.",
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Confirmar contraseña" />
          </Form.Item>
        </>
      ) : (
        ""
      )}

      <Form.Item name="foto" label="Logo" rules={[]}>
        <UploadImage
          uploadForm={(url: string) => {
            form.setFieldsValue({ foto: url });
          }}
          imageUrl={data[nombre] ? data[nombre]["foto"] : undefined}
        />
      </Form.Item>
      <Form.Item
        name="description"
        label="Descripción"
        className="obligatorio"
        rules={[
          {
            required: true,
            message: "Por favor, ingresar una descripción para la Ong",
            whitespace: true,
          },
          {
            max: 500,
            message: "La descripción no debe exceder los 500 caracteres",
          },
        ]}
      >
        <TextArea
          rows={4}
          placeholder="Tareas que realiza, beneficiarios, otros."
        />
      </Form.Item>
      <Form.Item
        name="website"
        label="Sitio Web"
        rules={[
          { pattern: websiteRE, message: "La URL del sitio web no es válida" },
          {
            max: 125,
            message: "El sitio web no debe exceder los 125 caracteres",
          },
        ]}
      >
        <Input placeholder="Sitio web" />
      </Form.Item>
      <Form.Item
        name="facebook"
        label="Facebook"
        rules={[
          { pattern: facebookRE, message: "La URL de Facebook no es válida" },
          {
            max: 125,
            message:
              "La dirección de facebook no debe exceder los 125 caracteres",
          },
        ]}
      >
        <Input placeholder="facebook" />
      </Form.Item>
      <Form.Item
        name="instagram"
        label="Instagram"
        rules={[
          {
            pattern: instagramRE,
            message: "La URL de Instagram no es válida",
          },
          {
            max: 125,
            message:
              "La dirección de instagram no debe exceder los 125 caracteres",
          },
        ]}
      >
        <Input placeholder="instagram" />
      </Form.Item>
      <Form.Item
        name="twitter"
        label="Twitter"
        rules={[
          { pattern: twitterRE, message: "La URL de Twitter no es válida" },
          {
            max: 125,
            message:
              "La dirección de twitter no debe exceder los 125 caracteres",
          },
        ]}
      >
        <Input placeholder="twitter" />
      </Form.Item>
      <Form.Item name="gallery" label="Fotos de la organización" rules={[]}>
        <UploadGallery
          uploadForm={(gallery: any[]) => {
            form.setFieldsValue({ gallery: gallery });
          }}
          fileList={
            props.modo !== "edicion"
              ? data[nombre] && data[nombre]["gallery"]
                ? data[nombre]["gallery"].map((foto: any) => {
                  return {
                    uid: foto.id,
                    status: "done",
                    url: foto.fotoContent,
                    name: `Image ${foto.id}`,
                  };
                })
                : undefined
              : data[nombre] && data[nombre]["gallery"]
                ? data[nombre]["gallery"].map((foto: any) => {
                  return {
                    uid: foto.id,
                    status: "done",
                    url: foto.fotoContent,
                    name: `Image ${foto.id}`,
                  };
                })
                : undefined
          }
        />
      </Form.Item>
    </FW>
  );
};

export default DatosPrincipales;
