import React, { useEffect, useState } from "react";
import navBarLogo from "../../../../images/new-home/NavBar.png";
import { NavLink, useNavigate } from "react-router-dom";

import AuthService from "../../../../services/auth/AuthService";

import "./menu.css";
import { Button } from "antd";
import useCSVEvents from "../../services/useCSVEvents";

const styleRouteActive = (isActive: boolean): { [key: string]: string } => {
  return isActive
    ? { textDecoration: "none", color: "rgba(255, 128, 11, 1)" }
    : {};
};

const Menu = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const events = useCSVEvents();

  const downloadCSV: any = () => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(events));
    element.setAttribute('download', "CSV Oportunidades");

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

  }

  const logout = () => {
    AuthService.logOutBackOffice();
    const state = { from: { pathname: '/backoffice', search: '', hash: '', state: null, key: 'default' } };
    navigate("/backoffice/login", { state: state });
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <a className="logo" href="/backoffice">
        <img src={navBarLogo} />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav">
          <div className="dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="operacionesMenu"
              data-toggle="dropdown"
            >
              Operaciones
            </a>
            <ul className="dropdown-menu" aria-labelledby="operacionesMenu">
              <li>
                <NavLink

                  to="ongs"
                  style={({ isActive }) => styleRouteActive(isActive)}
                >
                  {windowSize[0] < 992 && (
                    <span data-toggle="collapse" data-target="#navbarCollapse">
                      ONGs
                    </span>
                  )}
                  {windowSize[0] >= 992 && <span>ONGs</span>}
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="operacionesVoluntarios"
                  style={({ isActive }) => styleRouteActive(isActive)}
                >
                  {windowSize[0] < 992 && (
                    <span data-toggle="collapse" data-target="#navbarCollapse">
                      Voluntarios
                    </span>
                  )}
                  {windowSize[0] >= 992 && <span>Voluntarios</span>}
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="intereses"
                  style={({ isActive }) => styleRouteActive(isActive)}
                >
                  {windowSize[0] < 992 && (
                    <span data-toggle="collapse" data-target="#navbarCollapse">
                      Intereses
                    </span>
                  )}
                  {windowSize[0] >= 992 && <span>Intereses</span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="donaciones"
                  style={({ isActive }) => styleRouteActive(isActive)}
                >
                  {windowSize[0] < 992 && (
                    <span data-toggle="collapse" data-target="#navbarCollapse">
                      Donaciones
                    </span>
                  )}
                  {windowSize[0] >= 992 && <span>Donaciones</span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="administradores"
                  style={({ isActive }) => styleRouteActive(isActive)}
                >
                  {windowSize[0] < 992 && (
                    <span data-toggle="collapse" data-target="#navbarCollapse">
                      Administradores
                    </span>
                  )}
                  {windowSize[0] >= 992 && <span>Administradores</span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="cuenta"
                  style={({ isActive }) => styleRouteActive(isActive)}
                >
                  {windowSize[0] < 992 && (
                    <span data-toggle="collapse" data-target="#navbarCollapse">
                      Administradores
                    </span>
                  )}
                  {windowSize[0] >= 992 && <span>Cuenta</span>}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="datosMenu"
              data-toggle="dropdown"
            >
              Inteligencia de datos
            </a>
            <ul className="dropdown-menu" aria-labelledby="datosMenu">
              <li>
                <Button className="dropdown-link" onClick={() => downloadCSV()}>
                  {windowSize[0] < 992 && (
                    <span data-toggle="collapse" data-target="#navbarCollapse">
                      Oportunidades
                    </span>
                  )}
                  {windowSize[0] >= 992 && <span>Oportunidades</span>}
                </Button>
              </li>
              <li>
                <NavLink
                  to="datosVoluntarios"
                  style={({ isActive }) => styleRouteActive(isActive)}
                >
                  {windowSize[0] < 992 && (
                    <span data-toggle="collapse" data-target="#navbarCollapse">
                      Voluntarios
                    </span>
                  )}
                  {windowSize[0] >= 992 && <span>Voluntarios</span>}
                </NavLink>
              </li>
            </ul>
          </div>
          <NavLink
            to="estadisticas"
            style={({ isActive }) => styleRouteActive(isActive)}
          >
            {windowSize[0] < 992 && (
              <span data-toggle="collapse" data-target="#navbarCollapse">
                Estadisticas
              </span>
            )}
            {windowSize[0] >= 992 && <span>Estadisticas</span>}
          </NavLink>
        </div>
        <button onClick={logout} className="btn btn-primary logout-button">
          <i className="fa fa-sign-out" />Log Out
        </button>
      </div>
    </nav>
  );
};

export default Menu;
