import React, { useEffect } from "react";
import { Form, Input, Select } from "antd";
import { Requeriment } from "../../utils/models";

const CuentaBancaria = (props: any) => {
  const nombre: string = "cuenta-bancaria-form";
  const requerimientos: Requeriment[] = [
    {
      tab_name: "referente-de-donaciones-form",
      requeriments: ["recibir_soporte"],
    },
    {
      tab_name: "donaciones-form",
      requeriments: ["44"],
    },
  ];
  const data: any = props.data;
  const tituloForm = "Cuenta bancaria"
  const [form] = Form.useForm();
  const actualizarDatos = props.actualizarDatos;

  const setActiveTabName = props.setActiveTabName;
  useEffect(() => {
    setActiveTabName(nombre)
  }, [])

  const procesarDatos = (valores: any) => {
    return valores;
  }

  const FW = props.fw;

  return (
    <FW
      nombre={nombre}
      tituloForm={tituloForm}
      data={data}
      form={form}
      actualizarDatos={actualizarDatos}
      procesarDatos={procesarDatos}
      requerimientos={requerimientos}
    >
      <Form.Item
        name="numeroCuenta"
        label="Número de cuenta"
      >
        <Input placeholder="Ingresar número de cuenta" />
      </Form.Item>
      <Form.Item
        name="tipoDeCuenta"
        label="Tipo de cuenta"
      >
        <Select
          defaultValue={{ value: '', label: 'Seleccionar el tipo de cuenta' }}
        >
          <Select.Option value="">Seleccionar el tipo de cuenta</Select.Option>
          <Select.Option value="CC">Cuenta Corriente</Select.Option>
          <Select.Option value="CA">Caja de Ahorro</Select.Option>
          <Select.Option value="OTRO">Otra</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="banco"
        label="Banco"
      >
        <Input placeholder="Ingresar el banco" />
      </Form.Item>
      <Form.Item
        name="website"
        label="Link de donaciones"
      >
        <Input placeholder="Ingresar el link para recibir donaciones" />
      </Form.Item>

      { /* TODO Esconder campo */}
      {props.modo === 'edicion' ?
        <div className="idField">
          <Form.Item
            name='id'
            label='id BankingInformation'
          >
            <Input type='number' placeholder='id BankingInformation' />
          </Form.Item>

          <Form.Item
            name='ongId'
            label='ongId'
          >
            <Input type='number' placeholder='ongId' />
          </Form.Item>
        </div>
        : ''
      }
    </FW>
  );
}

export default CuentaBancaria;
