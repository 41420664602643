import React from "react";
import "./contact.css";
import Form from "./components/form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Contact = (props: any) => {
  return (
    <div id="h-contact">
      <div className="title">Contacto</div>
      <Form />
      <hr />
      <div className="links">
        <a
          href="https://www.facebook.com/involucrate.uy"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://twitter.com/Involucrate_uy"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          href="https://www.instagram.com/involucrate.uy/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
    </div>
  );
};

export default Contact;
