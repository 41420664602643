export function formatDate(stringDate: string) {
  const date = new Date(stringDate);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Sumar 1 ya que los meses van de 0 a 11
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Añadir ceros iniciales si es necesario
  const formatDay = day < 10 ? `0${day}` : day;
  const formatMonth = month < 10 ? `0${month}` : month;
  const formatHours = hours < 10 ? `0${hours}` : hours;
  const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formatDay}/${formatMonth}/${year} - ${formatHours}:${formatMinutes}`;
}