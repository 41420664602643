// CuentaBancaria Regex
export const numeroCuentaPattern = new RegExp(/^[0-9]+$/);
export const donacionesPattern = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/);

//Datos básicos regex
export const websiteRE = /^((http(s)?:\/\/)?(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$)/;
export const facebookRE = new RegExp('(?:(?:http|https)://)?(?:www.)?facebook.com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?');
export const instagramRE = new RegExp('((?:(?:http|https)://)?(?:www\.)?instagram\.com\/([^/?#&]+)).*');
export const twitterRE = new RegExp('(?:(?:(?:(?:http|https):\\/\\/)?(?:www.)?twitter.com\\/)|@)[\\w\\d\\_\\-]+');

//Sedes regex
export const postalRE = new RegExp("^[0-9]+$");

//Banco regex
export const accountNumberRE = new RegExp("^[0-9]+$");

//Persona referente regex
export const referencePerson = /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]+$/u;

export enum FileStatus {
    remove = 'removed',
    done = 'done',
    upload = 'uploading'
}

export enum TitleRegister {
    text = 'Registrá tu ONG',
}

export enum SubTitleRegister {
    success = '¡Ya creamos tu cuenta!',
    failed = 'Hubo un error :(',
}

export enum TextRegister {    
    failed = 'No pudimos realizar el registro, por favor inténtalo de nuevo mas tarde.',
}
