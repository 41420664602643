import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import NormalMap from "../../../../../components/donate/map/normal_map";
import useMarkers from "../../../../../components/donate/map/useMarkers";

import "./index.css";


const DonateMap = () => {
  const { subcategories } = useParams();
  const navigate = useNavigate();

  //Lista de las sub categorías seleccionadas
  const [selected, updateSelected] = useState(subcategories!.split(
    "~"
  ) as string[]);

  //Estados para los marcadores (se seleccionan en search y se usan en map)
  const markers = useMarkers(selected);

  const navigateToDonar = () => {
    navigate("/donar");
  };

  useEffect(() => {
    try {
      for (let i = 0; i < selected.length; i++) {
        const item = parseInt(selected[i], 10);
        if (isNaN(item) && !isFinite(item)) {
          throw new Error("Error! The value is not numeric");
        }
      }
    } catch (error) {
      navigateToDonar();
    }
  }, []);

  return (
    <div id="donate-map">
      <div className="donate-content">
        <div className="map-page">
          <Button
            className="ant-btn ant-btn-primary regresar-a-busqueda"
            onClick={navigateToDonar}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>¿Qué vas a donar?</span>
          </Button>
          <NormalMap
            events={markers}
          />
        </div>
      </div>
    </div>
  );
};

export default DonateMap;
