import React from "react";
import MainSection from "../../../../components/login/main-section/mainSection";
import Oportunidades from "../../../../components/login/oportunidades/oportunidades";
import Contact from "../../../../components/login/contact/contact";
type LoginProps = {
  redirectRoute?: string;
}

const Login = ({ redirectRoute }: LoginProps) => {
  return (
    <div id="login">
      <MainSection />
      <Oportunidades />
      <Contact />
    </div>
  );
};

export default Login;
