export type Config = {
  data?: any;
  auth?: any;
  service: string;
  method: string;
};

/* export type Data = {
  tipo: string;
}; */

let ajax = (info: Config) => {
  let formData = undefined;
  if (info.data) {
    formData = JSON.stringify(info.data);
  }

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: info.auth
      ? info.auth
      : "Bearer " + localStorage.getItem("jwt"),
  };

  var url = info.service;
  return fetch(url, {
    method: info.method,
    headers,
    body: formData,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (ex) {
      console.log("Error en la llamada al backend", ex);
      return ex;
    });
};

export default ajax;
