import React, { useEffect } from "react";
import { Form, Input, Select } from "antd";
import { Requeriment } from "../../utils/models";

const CambiarContrasenha = (props: any) => {
  const nombre: string = "cambiar-contraseña-form";
  const requerimientos: Requeriment[] = [];
  const data: any = props.data;
  const tituloForm = "Cambiar contraseña";
  const [form] = Form.useForm();
  const actualizarDatos = props.actualizarDatos;

  const setActiveTabName = props.setActiveTabName;
  useEffect(() => {
    setActiveTabName(nombre);
  }, []);

  const procesarDatos = (valores: any) => {
    return valores;
  };

  var debounce = require("debounce-promise");

  const verifyCurrentPassword = () => {
    // TODO Checkear si la contraseña actual es correcta
    return true;
  };

  var passwordRequest = debounce(verifyCurrentPassword, 500);

  const FW = props.fw;

  return (
    <FW
      nombre={nombre}
      tituloForm={tituloForm}
      data={data}
      form={form}
      actualizarDatos={actualizarDatos}
      procesarDatos={procesarDatos}
      requerimientos={requerimientos}
    >
      {/** 
       * TODO: Revisar si la contraseña es igual a la actual
      <Form.Item
        name="old_password"
        label="Contraseña actual"
        className="obligatorio"
        rules={[
          {
            required: true,
            message: "Por favor, ingrese su contraseña actual.",
          },
          {
            min: 6,
            message: "La contraseña debe tener al menos 6 caracteres.",
          },
          {
            max: 15,
            message: "La contraseña no debe exceder los 15 caracteres.",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return new Promise((resolve, reject) => {
                passwordRequest(value)
                  .then((response: any) => {
                    resolve(response);
                  })
                  .catch((error: any) => {
                    reject(error);
                  });
              });
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Ingresar contraseña" />
      </Form.Item>
      */}
      <Form.Item
        name="password"
        label="Contraseña"
        className="obligatorio"
        rules={[
          {
            min: 6,
            message: "La contraseña debe tener al menos 6 caracteres.",
          },
          {
            max: 15,
            message: "La contraseña no debe exceder los 15 caracteres.",
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Ingresar contraseña" />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Confirmar contraseña"
        className="obligatorio"
        dependencies={["password"]}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                "Las contraseñas ingresadas no coinciden."
              );
            },
          }),
          {
            min: 6,
            message: "La contraseña debe tener al menos 6 caracteres.",
          },
          {
            max: 15,
            message: "La contraseña no debe exceder los 15 caracteres.",
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Confirmar contraseña" />
      </Form.Item>
    </FW>
  );
};

export default CambiarContrasenha;
