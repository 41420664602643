import React, { useEffect, useState, Fragment } from "react";
import { Checkbox, Form, Input, Modal } from "antd";
import PhoneNumberInput from "../../../PhoneNumberInput/PhoneNumberInput";
import {
  validatePhoneNumber,
  countryOptions,
  getPhoneNumberAndCountryPrefix,
  addCountryPrefixNumber
} from "../../../../utils/phoneNumberConstants";
import { referencePerson } from "../../utils/constants";
import "./index.css";
import { Requeriment } from "../../utils/models";

const ReferenteVoluntariado = (props: any) => {
  const nombre: string = "referente-de-voluntariado-form";
  const requerimientos: Requeriment[] = [];
  const data: any = props.data;
  const tituloForm = "Referente de voluntariado";
  const [form] = Form.useForm();
  const actualizarDatos = props.actualizarDatos;

  const setActiveTabName = props.setActiveTabName;
  useEffect(() => {
    setActiveTabName(nombre)
  }, [])

  const procesarDatos = (valores: any) => {
    return {
      ...valores,
      telefono: addCountryPrefixNumber(valores.countryTelefono, valores.telefono),
      numeroDeContacto: addCountryPrefixNumber(valores.countryNumeroDeContacto, valores.numeroDeContacto),
      recibir_soporte: receiveVolunteers
    }
  }

  const FW = props.fw;

  const rdForm = props.data["referente-de-donaciones-form"];

  const countryTelefono = Form.useWatch("countryTelefono", form);
  const countryNumeroDeContacto = Form.useWatch(
    "countryNumeroDeContacto",
    form
  );

  const [receiveVolunteers, setReceiveVolunteers] = useState<boolean>(
    data[nombre] ? data[nombre]["recibir_soporte"] : false
  );

  //Variable para indicar si se usa o no la info del referente de donaciones
  const [usarRDData, setUsarRDData] = useState<boolean>(false);

  const [modalOpen, setModalOpen] = useState(
    !data[nombre] || !data[nombre]["recibir_soporte"]
  );

  useEffect(
    () => {
      const formdata: any = {
        email: "",
        personaReferente: "",
        countryNumeroDeContacto: "",
        countryTelefono: "",
        numeroDeContacto: "",
        telefono: ""
      };

      if (data[nombre]) {
        formdata.email = data[nombre]["email"];
        formdata.personaReferente = data[nombre]["personaReferente"];
        formdata.countryNumeroDeContacto = getPhoneNumberAndCountryPrefix(data[nombre]["numeroDeContacto"], 0);
        formdata.numeroDeContacto = getPhoneNumberAndCountryPrefix(data[nombre]["numeroDeContacto"], 1);
        formdata.countryTelefono = getPhoneNumberAndCountryPrefix(data[nombre]["telefono"], 0);
        formdata.telefono = getPhoneNumberAndCountryPrefix(data[nombre]["telefono"], 1);
      }

      if (usarRDData) {
        formdata.email = rdForm["email"];
        formdata.personaReferente = rdForm["personaReferente"];
        formdata.countryNumeroDeContacto = getPhoneNumberAndCountryPrefix(rdForm["numeroDeContacto"], 0);
        formdata.numeroDeContacto = getPhoneNumberAndCountryPrefix(rdForm["numeroDeContacto"], 1);
        formdata.countryTelefono = getPhoneNumberAndCountryPrefix(rdForm["telefono"], 0);
        formdata.telefono = getPhoneNumberAndCountryPrefix(rdForm["telefono"], 1);
        setUsarRDData(false)
      }

      if (!receiveVolunteers) {
        formdata.email = "";
        formdata.personaReferente = "";
        formdata.countryNumeroDeContacto = "";
        formdata.countryTelefono = "";
        formdata.numeroDeContacto = "";
        formdata.telefono = "";
        form.resetFields();
      }

      form.setFieldsValue({
        email: formdata.email,
        personaReferente: formdata.personaReferente,
        countryNumeroDeContacto: formdata.countryNumeroDeContacto,
        countryTelefono: formdata.countryTelefono,
        numeroDeContacto: formdata.numeroDeContacto,
        telefono: formdata.telefono
      });

    },
    [receiveVolunteers]
  );

  const update_form = () => {
    setModalOpen(false);
    setUsarRDData(true);
    setReceiveVolunteers(true);
  };

  return (
    <FW
      nombre={nombre}
      tituloForm={tituloForm}
      data={data}
      form={form}
      actualizarDatos={actualizarDatos}
      procesarDatos={procesarDatos}
      requerimientos={requerimientos}
    >
      {props.data["referente-de-donaciones-form"] &&
        props.data["referente-de-donaciones-form"]["recibir_soporte"] && (
          <Modal
            title="Copiar referente de donaciones"
            visible={modalOpen}
            onOk={update_form}
            okText="Aceptar"
            cancelText="Cancelar"
            onCancel={() => setModalOpen(false)}
            centered={true}
            className="copiar-referente-de-donaciones-modal"
          >
            <div>
              ¿Quieres reutilizar los datos del referente de donaciones?
            </div>
          </Modal>
        )}
      <Checkbox

        disabled={false}
        checked={receiveVolunteers}
        onChange={(e) => setReceiveVolunteers(e.target.checked)}
      >
        ¿Desean recibir personas voluntarias?
      </Checkbox>
      <div className="caption">
        Ingresar los datos de la persona referente del voluntariado
      </div>
      {props.modo === "edicion" ? (
        <Form.Item label="idReferente" name="idReferente" className="idField">
          <Input disabled={!receiveVolunteers} placeholder="idReferente" />
        </Form.Item>
      ) : (
        ""
      )}
      <Form.Item
        name="personaReferente"
        className={receiveVolunteers ? "obligatorio" : ""}
        label="Persona referente"
        rules={[
          {
            required: !!receiveVolunteers,
            message:
              "Por favor, ingresar el nombre de una persona referente.",
            whitespace: true,
          },
          { type: "string" },
          {
            max: 50,
            message:
              "El nombre de la persona referente no debe exceder los 50 caracteres",
          },
          {
            pattern: referencePerson,
            message: "Por favor, ingresar sólo letras",
          },
        ]}
      >
        <Input disabled={!receiveVolunteers} placeholder="Ingresar el nombre de la persona referente" />
      </Form.Item>

      <PhoneNumberInput
        label="Teléfono"
        form={form}
        validateCallback={(phoneNumber: string) =>
          validatePhoneNumber(phoneNumber, countryTelefono)
        }
        validateRequired={() => { return !!receiveVolunteers }}
        formInfoName="telefono"
        countryInfoName="countryTelefono"
        options={countryOptions}
        isUpdated={receiveVolunteers}
        disabled={!receiveVolunteers}
      />

      <PhoneNumberInput
        label="Número de Contacto de Whatsapp"
        form={form}
        validateCallback={(phoneNumber: string) =>
          validatePhoneNumber(phoneNumber, countryNumeroDeContacto)
        }
        validateRequired={() => { return !!receiveVolunteers }}
        formInfoName="numeroDeContacto"
        countryInfoName="countryNumeroDeContacto"
        options={countryOptions}
        isUpdated={receiveVolunteers}
        disabled={!receiveVolunteers}
      />

      <Form.Item
        name="email"
        label="E-mail"
        className={receiveVolunteers ? "obligatorio" : ""}
        rules={[
          {
            required: !!receiveVolunteers,
            message: "Por favor, ingresar email.",
          },
          {
            type: "email",
            message: "El email ingresado no es válido",
          },
          { max: 60, message: "El email no debe exceder los 60 caracteres" },
        ]}
      >
        <Input disabled={!receiveVolunteers} placeholder="Ingresar e-mail" />
      </Form.Item>

      {props.modo === "edicion" ? (
        <Form.Item
          className="idReferente"
          name="idReferente"
          label="idReferente"
        >
          <Input disabled={!receiveVolunteers} type="number" placeholder="idReferente" />
        </Form.Item>
      ) : (
        ""
      )}
    </FW>
  );
};

export default ReferenteVoluntariado;
