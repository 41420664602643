export const getNews = "https://news.involucrate.uy/wp-json/wp/v2/posts";
export const getPhotoByFeaturedMedia =
  "https://news.involucrate.uy/wp-json/wp/v2/media/";
export const getCategories = "/api2/donations/filters";
export const putDonaciones = "/api2/ong/donaciones";
export const patchPassword = "/api2/ong/cambiarPass";
export const putONG = "/api2/ong/editarOng";
export const getMarkers =
  "/api2/donations/near?lat=-34.90111269999998&lng=-56.164531399999994&radio=571000";
export const getONGs = "/api2/ong";
export const getOngById = "/api2/ong/id/";
export const getEventById = "/api2/eventos/id/";
export const receiveDonations = "/api2/donations/sedeId/";
export const getVolunteersFilters = "/api2/eventos/filters";
export const getEvents =
  "/api2/eventos/near?lat=-34.90111269999998&lng=-56.164531399999994&radio=571000";
export const getAvailableEmail = "/api2/ong/available/";
export const postInvolucrarme = "/api2/inscripciones";
export const postVoluntario = "/api2/voluntarios";
export const getCSVEvents = "/api2/backOffice/csv/events";
export const auth = "/auth2/";
export const mapAPI =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDxUH0ojHnYS4mRwAAL3R7gN1Mxo33FYRk&libraries=places";
export const postONG = "/auth2/register/ong"


//Datos básicos regex
export const websiteRE = /^((http(s)?:\/\/)?(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$)/;
export const facebookRE = new RegExp('(?:(?:http|https)://)?(?:www.)?facebook.com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?');
export const instagramRE = new RegExp('(https?:\/\/(?:www\.)?instagram\.com\/([^/?#&]+)).*');
export const twitterRE = new RegExp('(?:(?:(?:(?:http|https):\\/\\/)?(?:www.)?twitter.com\\/)|@)[\\w\\d\\_\\-]+');

export const backOfficeUrl = "/backoffice";
