import React, { useState, useEffect } from "react";
import { Config } from "../../../../utils/ajaxAction";
import { getCategories } from "../../../../utils/constants";
import ajaxAction from "../../../../utils/ajaxAction";
import { Form, Collapse, Checkbox } from "antd";
import { Requeriment } from "../../utils/models";
const { Panel } = Collapse;

const Donaciones = (props: any) => {

  var config: Config = {
    service: getCategories,
    method: "GET",
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    ajaxAction(config)
      .then(async (response) => {
        const data = await response.json();
        setCategories(data[0].categorias);
      })
      .catch((err) => console.log(err));
  }, []);

  const nombre: string = "donaciones-form";
  const requerimientos: Requeriment[] = [
    {
      tab_name: "referente-de-donaciones-form",
      requeriments: ["recibir_soporte"],
    },
  ];
  const data: any = props.data;
  const tituloForm = "Donaciones"
  const [form] = Form.useForm();
  const actualizarDatos = props.actualizarDatos;

  const setActiveTabName = props.setActiveTabName;
  useEffect(() => {
    setActiveTabName(nombre)
  }, [])

  const procesarDatos = (valores: any) => {
    //Get values as a lists
    const asArray = Object.entries(valores);
    //Filters only when booleans
    const filtered = asArray.filter(
      ([key, value]) => !(value === undefined || !value)
    );

    //Here we only get the elements
    return Object.fromEntries(filtered);
  }

  const FW = props.fw;

  return (
    <FW
      nombre={nombre}
      tituloForm={tituloForm}
      data={data}
      form={form}
      actualizarDatos={actualizarDatos}
      procesarDatos={procesarDatos}
      requerimientos={requerimientos}
    >
      <div className="caption">
        Selecciona los articulos que tu ONG necesita
      </div>
      <Collapse accordion>
        {categories.map((categoria: any) => (
          <Panel
            header={categoria.nombre}
            key={categoria.id}
            forceRender={true}
          >
            {categoria.subcategorias.map((subCategoria: any) => (
              <Form.Item
                key={subCategoria.id}
                name={subCategoria.id}
                valuePropName="checked"
              >
                <Checkbox>{subCategoria.nombre}</Checkbox>
              </Form.Item>
            ))}
          </Panel>
        ))}
      </Collapse>
    </FW>
  );
};

export default Donaciones;
