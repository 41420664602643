import React, { useEffect } from "react";
import { Form } from "antd";
import { Requeriment } from "../../utils/models";

const Guardar = (props: any) => {
  const nombre: string = "guardar-form";
  const requerimientos: Requeriment[] = [];
  const data: any = props.data;
  const [form] = Form.useForm();
  const actualizarDatos = props.actualizarDatos;

  const setActiveTabName = props.setActiveTabName;
  useEffect(() => {
    setActiveTabName(nombre)
  }, [])

  const procesarDatos = (valores: any) => {
    return valores
  }

  const FW = props.fw;

  return (
    <FW
      nombre={nombre}
      data={data}
      form={form}
      actualizarDatos={actualizarDatos}
      procesarDatos={procesarDatos}
      requerimientos={requerimientos}
    >
      <div className="caption">Terminaste de registrar los datos de tu ong, al darle guardar terminarás el proceso</div>
    </FW>
  );
}

export default Guardar;
