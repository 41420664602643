import { VolunteerCategory } from "../entities/VolunteerFilter";

export function volunteerCategoryDtoToEntity(element: any): VolunteerCategory {
  return {
    id: element.id,
    name: element.nombre,
  };
}

export function arrayVolunteerCategoryDtoToEntity(elements: any[]): VolunteerCategory[] {
  let response: VolunteerCategory[] = [];
  elements.map((element) => {
    response.push(volunteerCategoryDtoToEntity(element));
  });
  return response;
}
