import React, { useState, Fragment } from 'react';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import SedeForm from "./sedeForm";

const SedeModal = (props: any) => {

  const formName = props.formName;
  const onFinishForm = props.onFinishForm;
  const buttonStyle = props.buttonStyle;
  const buttonMessage = props.buttonMessage;
  const sede = props.sede;
  const disabled = props.disabled;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    //setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Button
        type="primary"
        onClick={showModal}
        style={buttonStyle}
        disabled={disabled}
        className={disabled ? 'btn-disabled' : 'btn-enabled'}
      >
        {buttonMessage}
      </Button>
      <Modal
        key={`${formName}-${sede.direccion}-${isModalVisible}`}
        title={`${buttonMessage} Sede`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ form: formName, htmlType: 'submit' }}
        className="modalStyle"
        okText="Guardar"
        cancelText="Cancelar"
      >
        <SedeForm
          name={formName}
          sede={sede}
          onFinishForm={(sede: any) => {
            onFinishForm(sede);
            setIsModalVisible(false);
          }}
          modo={props.modo}
        />
      </Modal>
    </Fragment>
  );
};

export default SedeModal;
