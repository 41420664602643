import React, { useState } from "react";
import { User } from "../../types/User";
import { Alert, Button, Modal, Space, Table, Tooltip, message } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import UserForm from "../forms/UserForm";
import fetcher from "../../../../../../../helpers/fetcher";
import useSWR, { mutate } from "swr";

const UserTable: React.FC = () => {
  // Fetch data from the API using SWR
  const { data, error } = useSWR<User[]>('/api2/backoffice/all', fetcher);

  // Initialize state variables
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);



  // Define the columns for the table
  const statusAlert = {
    'ENABLED': <Alert message='Activo' type='success' showIcon style={{ width: 'auto', whiteSpace: 'nowrap', height: 32 }} />,
    'DELETED': <Alert message='Eliminado' type='error' showIcon style={{ width: 'auto', whiteSpace: 'nowrap', height: 32 }} />,
    'DISABLED': <Alert message='Desactivado' type='warning' showIcon style={{ width: 'auto', whiteSpace: 'nowrap', height: 32 }} />,
  }
  const columns = [

    {
      title: 'Usuario',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (state: 'ENABLED' | 'DELETED' | 'DISABLED') => (
        <span>
          {statusAlert[state]}
        </span>
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',

      render: (_: any, record: User) => (
        <Space size="small">
          <Tooltip title="Editar cuenta del usuario">
            <Button onClick={() => handleEdit(record)}>
              Editar
            </Button>
          </Tooltip>
          <Tooltip title="Eliminar cuenta del usuario">
            <Button onClick={() => handleDelete(record.id)} danger>
              Eliminar
            </Button>
          </Tooltip>
          <Tooltip title="Enviar un mail de restablecimiento de contraseña al correo del usuario">
            <Button onClick={() => handleReset(record)}>
              Restablecer
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const handleAdd = () => {
    setEdit(false);
    setEditingUser({ id: 0, name: '', email: '', status: 'ENABLED' });
    setVisible(true);
  };

  const handleEdit = (user: User) => {
    setEdit(true);
    setEditingUser(user);
    setVisible(true);
  };

  const handleDelete = async (id: number) => {
    try {
      const datum = await fetcher(`/api2/backoffice/id/${id}`, "DELETE");
      mutate('/api2/backoffice/all');
      message.success('Usuario eliminado');
    } catch (error) {
      message.error('Ocurrió un error');
      console.error(error.message);
    }
  };


  const handleReset = async (user: User) => {
    try {
      user.password = "1234"
      const datum = await fetcher(`/api2/backoffice/reset-password`, "PATCH", user);
      message.success('Contraseña restablecida');
    } catch (error) {
      message.error('Ocurrió un error');
      console.error(error.message);
    }
  }
  // Function to save changes to a user (edit or add)
  const handleSave = async (user: User) => {
    try {
      if (edit) {
        const datum = await fetcher(`/api2/backoffice/`, "PUT", user);
        message.success('Usuario editado');
      } else {
        const datum = await fetcher(`/api2/backoffice/`, "POST", user);
        message.success('Se agregó la cuenta de usuario');
      }
      mutate('/api2/backoffice/all');
      setVisible(false);
    } catch (error) {
      console.error(error.message);
      message.error('Ocurrió un error');
    }
  };

  const handleCancel = () => {
    setEditingUser(null);
    setVisible(false);
  };

  return (
    <>
      <Space style={{ display: "flex", justifyContent: "center" }}>
      <Tooltip title="Crear una nueva cuenta de usuario backoffice">
        <Button
          onClick={handleAdd} type="primary"
          icon={<UserAddOutlined />}
          style={{ marginBottom: 16 }}
        >
          Crear
        </Button>
        </Tooltip>
      </Space>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        sticky
        style={{ paddingLeft: 30, paddingRight: 30, marginLeft: 30, marginRight: 30 }}
      />
      <Modal
        title={edit ? 'Editar Usuario' : 'Agregar Usuario'}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <UserForm user={editingUser} onSave={handleSave} onCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default UserTable;