import React from 'react';
import { Input as InputLib, InputProps } from 'antd';

type TextAreaProps=any;

type CustomInputProps = InputProps & {
    highlighted?: boolean;
};

type CustomTextAreaProps = TextAreaProps & { // Use CustomTextAreaProps
    highlighted?: boolean;
};


const Input: React.FC<CustomInputProps> = (
    {
        defaultValue,
        highlighted = true,
        value,
        ...props
    }
) => {
    const isEmpty = !value && (defaultValue === undefined || defaultValue === null);
    const showSuffix = highlighted && isEmpty;

    return (
        <InputLib
            className={showSuffix ? "highlighted-field" : ""}
            defaultValue={defaultValue}
            value={value}
            suffix={showSuffix ? "🖊️" : undefined}
            {...props}
        />
    );
};
const TextArea: React.FC<CustomTextAreaProps> = (
    {
        defaultValue,
        highlighted = true,
        suffix = (!defaultValue && highlighted) ? "🖊️" : "",
        value,
        ...props
    }
) => (
    <InputLib.TextArea
        className={(!defaultValue && !value && highlighted) ? "highlighted-field" : ""}
        defaultValue={defaultValue}
        suffix={suffix}
        value={value}
        {...props}
    />
);

export { Input, TextArea };
