import L from 'leaflet';
import React, { useEffect } from 'react';
import { customIcon } from '../../../utils/const/iconConfig';
import { createTileLayer } from '../../../utils/const/mapUtils';
import { Evento } from '../../../utils/entities/Evento';

import 'leaflet/dist/leaflet.css';

interface MapProps {
  evento: Evento;
}

const MiniMap = ({ evento }: MapProps) => {
  useEffect(() => {
    const map = L.map('map', {
      center: evento ? [evento.coords.x, evento.coords.y] : [-34.8833, -56.1667],
      zoom: 12,
      minZoom: 8,
      maxZoom: 15,
    });

    map.attributionControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');
    createTileLayer().addTo(map);

    L.marker(evento ? [evento.coords.x, evento.coords.y] : [-34.8833, -56.1667], { icon: customIcon }).addTo(map);
  
    return () => {
      map.remove();
    };
  }, [evento]);

  return <div id="map" style={{ height: "128px", width: "100%" }} />;
};

export default MiniMap;