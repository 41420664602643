import ajaxAction from "../../../utils/ajaxAction";
import { putDonaciones, putONG, patchPassword } from "../../../utils/constants";

const mapReferenteDonaciones = (referenteDonaciones: any, ongId: number) => {
  if (
    referenteDonaciones === undefined ||
    referenteDonaciones.recibir_soporte === false
  ) {
    return {
      ongId: ongId,
      idReferente: referenteDonaciones.idReferente,
      personaReferente: "",
      telefono: "",
      email: "",
      numeroDeContacto: "",
      tipo: "donaciones",
      recibir_soporte: false
    };
  } else {
    return {
      ongId: ongId,
      idReferente: referenteDonaciones.idReferente,
      personaReferente: referenteDonaciones.personaReferente,
      telefono: referenteDonaciones.telefono,
      email: referenteDonaciones.email,
      numeroDeContacto: referenteDonaciones.numeroDeContacto,
      tipo: "donaciones",
      recibir_soporte: referenteDonaciones.recibir_soporte
    };
  }
};

const mapReferenteVolundatiado = (referenteVoluntariado: any) => {
  if (
    referenteVoluntariado === undefined ||
    referenteVoluntariado.recibir_soporte === false
  ) {
    return {
      idReferente: referenteVoluntariado.idReferente,
      personaReferente: "",
      telefono: "",
      email: "",
      numeroDeContacto: "",
      tipo: "voluntariado",
      recibir_soporte: false
    };
  } else {
    return {
      idReferente: referenteVoluntariado.idReferente,
      personaReferente: referenteVoluntariado.personaReferente,
      telefono: referenteVoluntariado.telefono,
      email: referenteVoluntariado.email,
      numeroDeContacto: referenteVoluntariado.numeroDeContacto,
      tipo: "voluntariado",
      recibir_soporte: referenteVoluntariado.recibir_soporte
    };
  }
};

const mapDonaciones = (donaciones: any) => {
  if (donaciones === undefined) {
    return []
  } else {
    return Object.keys(donaciones).map((donacionId: any) => {
      return ({ id: donacionId, description: "" })
    });
  }
}

const mapBankingInformation = (bankingInformation: any) => {
  if (bankingInformation === undefined) {
    return {
      id: "",
      bankName: "Sin Banco",
      accountNumber: 0,
      accountType: "",
      accountName: "",
      currencyDollar: null,
      linkToDonations: ""
    }
  } else {
    return {
      id: bankingInformation.id,
      ongId: bankingInformation.ongId,
      bankName: bankingInformation.banco,
      accountNumber: bankingInformation.numeroCuenta,
      accountType: bankingInformation.tipoDeCuenta,
      accountName: "",
      currencyDollar: null,
      linkToDonations: bankingInformation.website
    }
  }
}

const mapOportunidades = (oportunidades: any) => {
  if (oportunidades === undefined) {
    return []
  } else {
    const oportunidadesMapped: Array<any> = []
    oportunidades.forEach((oportunidad: any) => {
      const oportunidadMapped: any = {}
      oportunidadMapped.id = oportunidad.id
      oportunidadMapped.titulo = oportunidad.titulo
      oportunidadMapped.descripcion = oportunidad.descripcion
      oportunidadMapped.emergenciaId = null
      oportunidadMapped.tipoInicioId = 1
      oportunidadMapped.tipoHorarioId = 1
      oportunidadMapped.tipoDuracionId = 1
      oportunidadMapped.fecha_desde = null
      oportunidadMapped.fecha_hasta = null
      oportunidadMapped.dias_lunes = oportunidad.dias.includes("dias_lunes")
      oportunidadMapped.dias_martes = oportunidad.dias.includes("dias_martes")
      oportunidadMapped.dias_miercoles = oportunidad.dias.includes("dias_miercoles")
      oportunidadMapped.dias_jueves = oportunidad.dias.includes("dias_jueves")
      oportunidadMapped.dias_viernes = oportunidad.dias.includes("dias_viernes")
      oportunidadMapped.dias_sabado = oportunidad.dias.includes("dias_sabado")
      oportunidadMapped.dias_domingo = oportunidad.dias.includes("dias_domingo")
      oportunidadMapped.turnos_maniana = oportunidad.horario.includes("turnos_maniana")
      oportunidadMapped.turnos_tarde = oportunidad.horario.includes("turnos_tarde")
      oportunidadMapped.turnos_noche = oportunidad.horario.includes("turnos_noche")
      oportunidadMapped.tiempo_unavez = false
      oportunidadMapped.tiempo_ocasional = false
      oportunidadMapped.tiempo_mediotiempo = false
      oportunidadMapped.tiempo_tiempocompleto = false
      oportunidadMapped.presencia_virtual = false
      oportunidadMapped.presencia_fisica = false
      oportunidadMapped.status = "ENABLED"
      oportunidadMapped.sede = oportunidad.sede
      oportunidadMapped.filtros = oportunidad.categorias
      oportunidadesMapped.push(oportunidadMapped)
    })
    return oportunidadesMapped
  }

}

export const updateOngDonaciones = (data: any) => {
  const donaciones = mapDonaciones(data["donaciones-form"]);
  let sedes = [data["sede-principal-form"], ...data["sedes-form"]];
  sedes = sedes.map((sede: any) => {
    return parseInt(sede.id);
  })

  //Consolidado
  const donacionesData = {
    'donaciones': donaciones,
    'sedesDonaciones': sedes
  };
  return ajaxAction({
    method: 'PUT',
    data: donacionesData,
    auth: "Bearer " + localStorage.getItem("ong-jwt"),
    service: putDonaciones,
  })
};

export const updateONGPassword = (data: any) => {
  const contrasenha = data["cambiar-contraseña-form"].password;
  const email = data["datos-principales-form"].email;

  let updatePasswordData = {
    email: email,
    password: contrasenha
  }
  return ajaxAction({
    method: 'PATCH',
    data: updatePasswordData,
    auth: "Bearer " + localStorage.getItem("ong-jwt"),
    service: patchPassword,
  })
}

export const updateONG = (data: any, ongId: number) => {
  //Sección de ONG
  const ong: any = {
    id: ongId,
    name: data["datos-principales-form"].name,
    cuit: "",
    email: data["datos-principales-form"].email,
    description: data["datos-principales-form"].description,
    phone: "",
    tipoEntidadId: null,
    nombreResponsable: "",
    apellidoResponsable: "",
    cargoResponsable: "",
    claseMisionId: 1,
    facebook: data["datos-principales-form"].facebook,
    instagram: data["datos-principales-form"].instagram,
    twitter: data["datos-principales-form"].twitter,
    website: data["datos-principales-form"].website,
    foto: data["datos-principales-form"].foto,
    re_bebes: false,
    re_primerainfancia: false,
    re_ninios: false,
    re_adolescentes: false,
    re_jovenes: false,
    re_adultos: false,
    re_terceraedad: false,
    re_noaplica: false,
    referenteDonaciones: mapReferenteDonaciones(data["referente-de-donaciones-form"], ongId),
    referenteVoluntariado: mapReferenteVolundatiado(data["referente-de-voluntariado-form"]),
    bankInformation: mapBankingInformation(data.cuentaBancaria),
    link_collaborate: "prueba.com",
    show_banking_information: true
  }

  //Seccion de sedes
  data["sede-principal-form"].coordinates = {
    lat: data["sede-principal-form"].coordenadas.lat,
    lng: data["sede-principal-form"].coordenadas.lng
  }

  let sedes = [data["sede-principal-form"], ...data["sedes-form"]];
  sedes = sedes.map((sede: any) => {
    return ({
      id: sede.id,
      ongId: ongId,
      allowsDonations: true,
      telefonoFijo: "1111111111",
      horarios: "Completo",
      nombreResponsableSede: "Nombre de Prueba",
      apellidoResponsableSede: "Apellido de Prueba",
      celular: "1111111111",
      mail: "prueba@gmail.com",
      ubicacion: {
        id: sede.ubicacionId,
        allowsDonations: true,
        calle: "",
        codigoPostal: "",
        numero: "",
        localidad: "",
        provincia: "",
        pisoDepto: sede.pisoDepto ?? "",
        telefonoFijo: "",
        horarios: "",
        nombreResponsableSede: "",
        apellidoResponsableSede: "",
        celular: "",
        mail: "",
        coordenadas: {
          lat: sede.coordenadas.lat,
          lng: sede.coordenadas.lng
        },
        place_id: sede.place_id,
        descripcion: sede.direccion
      },
    })
  })

  //Consolidado
  const ongData = {
    email: data["datos-principales-form"].email,
    ong: ong,
    sedes: sedes,
    fotos: data["datos-principales-form"].gallery,
    oportunidades: mapOportunidades(data["oportunidades-form"])
  };

  console.log(ongData)

  return ajaxAction({
    method: 'PUT',
    data: { ongData, token: localStorage.getItem("ong-jwt") },
    service: putONG,
  })
}
