import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import OtherBusinessForm from './OtherBusinessForm';

interface EditarSede {
    formName: string,
    buttonText: string,
    formTitle: string,
    sede: any,
    onFinishForm(): void
}

const EditOtherBusiness:React.FC<EditarSede> = (props: any) => {

    const buttonText:string = props.buttonText;
    const formName: string = props.formName;
    const formTitle: string = props.formTitle;
    const sede: any = props.sede;

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };


    return(
        <>
            <Button onClick={()=> showModal()}>{buttonText}</Button>
            <Modal
                width="50%"
                key={`${formName}-form`}
                title={`${formTitle}`}
                visible={isModalVisible}
                footer={null}
                closable={false}
            >
                <OtherBusinessForm otraSede={sede}  hideModal={hideModal} />
            </Modal>
        </>
    )

}

export default EditOtherBusiness