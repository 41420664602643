import React from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Stats from "./components/backoffice/components/stats";
import Login from "./views/frontoffice/components/login";
import Contact from "./views/frontoffice/components/contact/contact";
import DonateMap from "./views/frontoffice/components/donate/mapa";
import DonateOportunity from "./views/frontoffice/components/donate/oportunity";
import DonateSearch from "./views/frontoffice/components/donate/search";
import Faq from "./views/frontoffice/components/faq/faq";
import RegistroONG from "./views/frontoffice/components/ongRegistro/index";
import EdicionONG from "./views/frontoffice/components/ongEdicion/index";
import RecuperarPassONG from "./views/frontoffice/components/recuperarPass/recuperarPassONG";
import { TermsAndConditions } from "./views/terms/index";
import VolunteeringEvent from "./views/frontoffice/components/volunteering/evento";
import VolunteeringMap from "./views/frontoffice/components/volunteering/mapa";
import VolunteeringSearch from "./views/frontoffice/components/volunteering/search";
import FrontOffice from "./views/frontoffice/index";
import Home from "./views/frontoffice/components/home/home";
import { ViewAllowed } from "./auth/view-allowed";
import AuthService from "./services/auth/AuthService";
import BO_NUEVA_ONG from "./views/backoffice/components/operaciones/ong/nuevaong";
import BackOffice from "./views/backoffice/index";
//import { ErrorPage } from "./views/error-page";
import BO_EditarONG from "./views/backoffice/components/operaciones/ongs/EditarONG";
import BO_OPORTUNIDADES from "./views/backoffice/components/datos/oportunidades/oportunidades";
import BO_DATOS_VOLUNTARIOS from "./views/backoffice/components/datos/voluntarios/voluntarios";
import BO_ESTADISTICAS from "./views/backoffice/components/estadisticas/estadisticas";
import BO_LOGIN from "./views/backoffice/components/login/login";
import BO_ADMINISTRADORES from "./views/backoffice/components/operaciones/administradores/administradores";
import BO_DONACIONES from "./views/backoffice/components/operaciones/donaciones/donaciones";
import BO_INTERESES from "./views/backoffice/components/operaciones/intereses/intereses";
import BO_ONGS from "./views/backoffice/components/operaciones/ongs/ongs";
import Cuenta from "./views/backoffice/components/operaciones/cuenta/Cuenta";
import NotFound from "./views/Errors/NotFound";
// import BO_OPERACIONES_VOLUNTARIOS from "./views/backoffice/components/operaciones/voluntarios/voluntarios";

function App(): JSX.Element {
  const router = createBrowserRouter([
    {
      path: "/terminos-y-condiciones",
      element: <TermsAndConditions />,
    },

    {
      path: "/backoffice/login",
      element: <BO_LOGIN />,
    },
    {
      path: "/recuperarPassONG",
      element: (
        <ViewAllowed redirectRoute="/editarONG">
          <RecuperarPassONG />
        </ViewAllowed>
      ),
      loader: async (): Promise<{ isViewAllowed: boolean }> => {
        const jwt = await AuthService.loggedInFrontOffice();
        return { isViewAllowed: jwt !== null };
      },
    },
    {
      path: "/",
      element: <FrontOffice />,
      children: [
        {
          index: true,
          element: <Navigate to="/mapa/" replace />,
        },
        {
          path: "mapa",
          element: <Home />,
        },
        {
          path: "mapa/login",
          element: (
            <ViewAllowed redirectRoute="/editarONG">
              <Login />
            </ViewAllowed>
          ),
          loader: async (): Promise<{ isViewAllowed: boolean }> => {
            const jwt = await AuthService.loggedInFrontOffice();
            return { isViewAllowed: jwt === null };
          },
        },
        {
          path: "/editarONG",
          element: (
            <ViewAllowed redirectRoute="/mapa/login">
              <EdicionONG />
            </ViewAllowed>
          ),
          loader: async (): Promise<{ isViewAllowed: boolean }> => {
            const jwt = await AuthService.loggedInFrontOffice();
            return { isViewAllowed: jwt !== null };
          },
        },
        {
          path: "faq",
          element: <Faq />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "donar",
          element: <DonateSearch />,
        },
        {
          path: "donar/:subcategories",
          element: <DonateMap />,
        },
        {
          path: "oportunidad/:ongId/:sedeId",
          element: <DonateOportunity />,
        },
        {
          path: "voluntariado",
          element: <VolunteeringSearch />,
        },
        {
          path: "voluntariado/:filtros",
          element: <VolunteeringMap />,
        },
        {
          path: "evento/:eventoId",
          element: <VolunteeringEvent />,
        },
        {
          path: "registroONG",
          element: <RegistroONG />,
        },
      ],
    },
    {
      path: "/backoffice",
      element: (
        <ViewAllowed redirectRoute="/backoffice/login">
          <BackOffice />
        </ViewAllowed>
      ),
      loader: async (): Promise<{ isViewAllowed: boolean }> => {
        const jwt = await AuthService.loggedInBackOffice();
        return { isViewAllowed: jwt !== null };
      },
      children: [
        {
          path: "stats",
          element: <Stats />,
        },
        {
          path: "ongs/:ongId",
          element: <BO_EditarONG />,
        },
        {
          path: "ongs",
          element: <BO_ONGS />,
        },
        // {
        //   path: "operacionesVoluntarios",
        //   element: <BO_OPERACIONES_VOLUNTARIOS />,
        // },
        {
          path: "ong/nuevaong",
          element: <BO_NUEVA_ONG />,
        },
        {
          path: "intereses",
          element: <BO_INTERESES />,
        },
        {
          path: "donaciones",
          element: <BO_DONACIONES />,
        },
        {
          path: "cuenta",
          element: <Cuenta />,
        },
        {
          path: "administradores",
          element: <BO_ADMINISTRADORES />,
        },
        {
          path: "oportunidades",
          element: <BO_OPORTUNIDADES />,
        },
        {
          path: "datosVoluntarios",
          element: <BO_DATOS_VOLUNTARIOS />,
        },
        {
          path: "estadisticas",
          element: <BO_ESTADISTICAS />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
