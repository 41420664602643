import React from "react";
import { Category } from "../../../utils/entities/Category";
import { Subcategorie } from "../../../utils/entities/Subcategorie";
import { Collapse } from "antd";
import { Form, Button, Checkbox } from "antd";
import './filters.css';

function Filters(props: any) {
  const categories=props.categories;
  const onFinishForm = props.onFinishForm;

  return (
    <Form
      className="filters"
      autoComplete="off"
      name = "searchSubcaterogies"
      onFinish={(values:any)=>{onFinishForm(values)}}
    >
      <div className="titulo">¿Qué vas a donar?</div>
      <Collapse className="categories">
      {categories.map(function (value: Category, index:number) {
        return (
          <Collapse.Panel header={value.name} key={"heading" + value.name + value.id}>
            {value.subcategories?.map(function (sub: Subcategorie, index) {
              return (
                <Form.Item name={value.name + "-" + sub.name} valuePropName="checked" key={index} >
                  <Checkbox>{sub.name}</Checkbox>
                </Form.Item>
              );
            })}
          </Collapse.Panel>
        )
      })}
      </Collapse>
      <Form.Item>
        <Button className="ant-btn ant-btn-primary sticky-button" type="primary" htmlType="submit">Buscar</Button>
      </Form.Item>
    </Form>
  );
}
export default Filters;
