import React from "react";
import { Form, Button, Checkbox } from "antd";
import { VolunteerCategory } from "../../../utils/entities/VolunteerFilter";
import './filters.css';

function Filters(props: any) {
  const filters = props.filters;
  const onFinishForm = props.onFinishForm;

  return (
    <Form
      className="filters"
      autoComplete="off"
      name="searchSubcaterogies"
      onFinish={(values: any) => {
        onFinishForm(values);
      }}
    >
      <div className="titulo">¿Que causas buscas?</div>
      <div className="filtros">
        {filters.map(function(value: VolunteerCategory, index: number) {
          return (
            <Form.Item name={value.name} valuePropName="checked" key={index}>
              <Checkbox>{value.name}</Checkbox>
            </Form.Item>
          );
        })}
      </div>
      <Form.Item>
        <Button
          className="ant-btn ant-btn-primary sticky-button "
          type="primary"
          htmlType="submit"
        >
          Buscar
        </Button>
      </Form.Item>
    </Form>
  );
}
export default Filters;
