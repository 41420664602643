import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ajaxAction, { Config } from "../../../utils/ajaxAction";
import { receiveDonations } from "../../../utils/constants";

interface Receive {
  category?: string;
  subCategory?: string;
}

interface OtrasDonacionesProps {
  sedeId: number;
}

const OtrasDonaciones = (props: OtrasDonacionesProps) => {
  const sedeId = props.sedeId;

  const [donations, setDonations] = useState([] as Receive[]);

  useEffect(
    () => {
      const configDonations: Config = {
        service: `${receiveDonations}${sedeId}`,
        method: "GET",
        auth: "",
      };

      ajaxAction(configDonations)
        .then(async (response) => {
          const data = await response.json();
          let rec: Receive[] = data?.categorias?.map((c: any) => ({ category: c.categoria, subCategory: c.subcategoria })) || [];
          rec = _.sortBy(
            rec,
            "category"
          );
          setDonations(rec);
        })
        .catch((err) => console.log(err));
    },
    [sedeId]
  );

  return (
    <div className="otras-donaciones">
      <div className="subtitulo">Otras donaciones que recibe</div>
      <div className="subcategorias">
        {donations.map((sub: Receive) => (
          <div
            className="subcategorie"
            key={`${sub.category}-${sub.subCategory}`}
          >
            <div className="icon">
              <FontAwesomeIcon icon={faCircle} />
            </div>
            <div className="texto">
              {sub.category} ({sub.subCategory})
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtrasDonaciones;
