import { Button } from 'antd';
import React from 'react';
import { CardI } from '../models';

function Card(props: { card: CardI }) {
  const card: CardI = props.card;
  return (
    <div className="carta">
      <img className={card.imagen} />
      <div>{card.titulo}</div>
      <div>{card.texto}</div>
      <Button type="primary" ghost>
        <a {...card.linkAnchorProps}>Saber más</a>
      </Button>
    </div>
  );
}

export default Card;
