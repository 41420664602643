import { Col, Space, Row, Form, Button } from 'antd';
import UploadGallery from '../../../../../../../../components/ongs/forms/datosPrincipales/components/uploadGallery';
import React from 'react';
import { useSharedSWR } from '../../../contexts/SWRContext';
import fetcher from '../../../../../../../../helpers/fetcher';
var debounce = require("debounce-promise");

const Photographs = () => {
    const { data, mutate } = useSharedSWR();
    const [form] = Form.useForm();
    const {
        id,
        fotos_ongs
    } = data;
    

    const handlePostPhoto = async (values: any) => {
        const body = { ongId: id, fotos: values.fotos_ongs }; // ejemplo foto:'./storage/10b4f4d8dc2ae366e37a743851052b8236a56b2eb8ca997cb6accd04cd98a00aa07a.jpeg'
        console.log("con" + JSON.stringify(values.fotos_ongs))
        try {
            const datum = await fetcher(
                `/api2/ong/bo/fotos`,
                "PATCH",
                body
            );
            mutate!(datum)
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Error: ${error.message}`)
            }
        }

    }

    return (
        <Form
            name="photographs"
            autoComplete="off"
            form={form}
            initialValues={fotos_ongs}
            onFinish={(values) => handlePostPhoto(values)}
        >

            <Row align="stretch">
                <Space size={[24, 24]} align="baseline" wrap>
                    <Row>
                        <Col>
                            <Row>
                                <Form.Item name="fotos_ongs" label="Fotos de la organización" rules={[]}>
                                    <UploadGallery
                                        uploadForm={(fotos_ongs: any[]) => {
                                            form.setFieldsValue({ fotos_ongs: fotos_ongs });
                                        }}


                                        fileList={
                                            fotos_ongs
                                            && fotos_ongs.map((foto_ong: { id: number, foto_blob_bu: string }) => {
                                                return {
                                                    uid: foto_ong.id,
                                                    status: "done",
                                                    url: `data:image/png;base64, ${foto_ong.foto_blob_bu}`,
                                                    name: `Image ${foto_ong.id}`,
                                                };
                                            })

                                        }
                                    />
                                </Form.Item>
                            </Row>
                        </Col>


                    </Row>
                </Space>
                <Space style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <Button onClick={() => form.submit()}>
                        Guardar
                    </Button>
                    <Button onClick={() => form.resetFields()}>
                        Cancelar
                    </Button>
                </Space>
            </Row>
        </Form>
    )
}

export default Photographs;