import React from 'react';
import { Form, Button, Row, Col, Space } from 'antd';
import { Input, TextArea } from '../Input';
import UploadImage from '../../../../../../../../components/ongs/forms/datosPrincipales/components/uploadImage';
import { facebookRE, instagramRE, twitterRE, websiteRE } from '../../../../../../../../utils/constants';
import { getAvailableEmail } from '../../../../../../../../utils/constants';
import { useSharedSWR } from '../../../contexts/SWRContext';
import fetcher from '../../../../../../../../helpers/fetcher';
import ajaxAction from '../../../../../../../../utils/ajaxAction';
var debounce = require("debounce-promise");

const ProfileData = () => {
    const { data, mutate } = useSharedSWR();
    const [form] = Form.useForm();
    const nombre = 'datos-principales';
    const {
        id,
        apellidoResponsable,
        cargoResponsable,
        cuit,
        description,
        email,
        facebook,
        instagram,
        name,
        nombreResponsable,
        phone,
        photo,
        re_bebes,
        re_primerainfancia,
        re_ninios,
        re_adolescentes,
        re_jovenes,
        re_adultos,
        re_terceraedad,
        re_noaplica,
        tipoEntidadId,
        twitter,
        website,
        fotos_ongs
    } = data;

    const profileData = {
        apellidoResponsable: apellidoResponsable!,
        cargoResponsable: cargoResponsable!,
        cuit: cuit!,
        description: description!,
        email: email!,
        facebook: facebook!,
        instagram: instagram!,
        name: name!,
        nombreResponsable: nombreResponsable!,
        phone: phone!,
        photo: photo!,
        re_bebes: re_bebes!,
        re_primerainfancia: re_primerainfancia!,
        re_ninios: re_ninios!,
        re_adolescentes: re_adolescentes!,
        re_jovenes: re_jovenes!,
        re_adultos: re_adultos!,
        re_terceraedad: re_terceraedad!,
        re_noaplica: re_noaplica!,
        website: website!,
        tipoEntidadId: tipoEntidadId!,
        twitter: twitter!,
        fotos_ongs: fotos_ongs!
    }

    const verifyEmail = (email: string) => {
        var config = {
            service: getAvailableEmail,
            method: "POST",
            data: { email },
        };
        return new Promise((resolve, reject) => {
            ajaxAction(config)
                .then(async (response) => {
                    const data = await response.json();
                    if (data.available) {
                        resolve("Email disponible");
                    } else {
                        reject(
                            "Este email ya se encuentra en uso, intentar con otro diferente"
                        );
                    }
                })
                .catch((err) => console.log(err));
        });
    };

    var request = debounce(verifyEmail, 500);

    const handleEditOng = async (values: any) => {
        const body = { id, ...values };
        try {
            const datum = await fetcher(`/api2/ong/bo/info/${id}`, "PATCH", body);
            mutate!();
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Error: ${error.message}`);
            }
        }
        console.log(
            "🚀 ~ file: ProfileData.tsx:87 ~ handleEditOng ~ body:",
            body
        );
    };

    const tipoEntidades = [
        { id: 0, name: "Elegir una opción" },
        { id: 1, name: "Asociación civil" },
        { id: 2, name: "Fundación" },
        { id: 3, name: "Institución Educativa" },
        { id: 4, name: "Hospital" },
        { id: 5, name: "Comedor" },
        { id: 6, name: "ONG Informal" },
        { id: 7, name: "Hogar" },
        { id: 8, name: "Otra" },
    ];

    return (

        <Form
            name={nombre}
            autoComplete="off"
            form={form}
            initialValues={profileData}
            onFinish={(values) => handleEditOng(values)}
        >

            <Row gutter={[0, 0]} wrap={false} align="stretch" className="container">
                <Col span={8}>
                    <Form.Item
                        label="Nombre de la ONG"
                        name="name"
                        className="obligatorio"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor, ingresar el nombre de la ONG.',
                                whitespace: true,
                            },
                            { type: 'string' },
                            { max: 50, message: 'El nombre no debe exceder los 50 caracteres' },
                        ]}
                        hasFeedback
                    >
                        <Input placeholder="Ingrese un nombre" />
                    </Form.Item>

                    <Form.Item
                        label="Teléfono"
                        name="phone"
                        className="obligatorio"
                        rules={[]}
                        hasFeedback
                    >
                        <Input placeholder="Ingrese un teléfono" type="telephone" />
                    </Form.Item>

                    <Form.Item
                        label="Sitio Web"
                        name="website"
                        className="obligatorio"
                        rules={[
                            { pattern: websiteRE, message: "La URL del sitio web no es válida" },
                            {
                                max: 125,
                                message: "El sitio web no debe exceder los 125 caracteres",
                            },
                        ]}
                        hasFeedback
                    >
                        <Input placeholder="Ingrese un sitio web" />
                    </Form.Item>
                </Col>


                <Col span={8}>
                    <Form.Item
                        label={(
                            <span>
                                E-mail&nbsp;
                                <span style={{ fontSize: "0.6rem", color: "gray" }}>
                                    El mismo será requerido luego para iniciar sesión
                                </span>
                            </span>
                        )}
                        name="email"
                        className="obligatorio"
                        rules={[
                            {
                                required: true,
                                message: "Por favor, ingresar un email.",
                                whitespace: true,
                            },
                            {
                                type: "email",
                                message: "El email ingresado no es válido",
                            },
                            {
                                max: 60,
                                message: "El email no debe exceder los 60 caracteres",
                            },
                        ]}
                        hasFeedback
                    >
                        <Input
                            type='email'
                            placeholder="Ingrese un email"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Link Facebook"
                        name="facebook"
                        rules={[
                            { pattern: facebookRE, message: "La URL de Facebook no es válida" },
                            {
                                max: 125,
                                message: "La dirección de facebook no debe exceder los 125 caracteres",
                            },
                        ]}
                    >
                        <Input placeholder="Facebook" />
                    </Form.Item>

                    <Form.Item
                        label="Link de Instagram"
                        name="instagram"
                        rules={[
                            {
                                pattern: instagramRE,
                                message: "La URL de Instagram no es válida",
                            },
                            {
                                max: 125,
                                message:
                                    "La dirección de instagram no debe exceder los 125 caracteres",
                            },
                        ]}
                    >
                        <Input placeholder="Instagram" />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Link de Twitter"
                        name="twitter"
                        rules={[
                            { pattern: twitterRE, message: "La URL de Twitter no es válida" },
                            {
                                max: 125,
                                message:
                                    "La dirección de twitter no debe exceder los 125 caracteres",
                            },
                        ]}
                    >
                        <Input placeholder="Twitter" />
                    </Form.Item>

                    <Form.Item
                        label="Descripción"
                        name="description"
                        className="obligatorio"
                        rules={[
                            {
                                required: true,
                                message: "Por favor, ingresar una descripción para la Ong",
                                whitespace: true,
                            },
                            {
                                max: 1000,
                                message: "La descripción no debe exceder los 500 caracteres",
                            },
                        ]}
                    >
                        <TextArea rows={3} placeholder="Ingrese una descripción" />
                    </Form.Item>

                    <Form.Item
                        label="Logo"
                        name="foto"
                        rules={[]}
                    >
                        <UploadImage
                            uploadForm={(url: string) => {
                                console.log(url)
                                form.setFieldsValue({ foto: url });
                            }}
                            imageUrl={profileData.photo ? `data:image/png;base64, ${profileData.photo}` : undefined}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Space style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <Button onClick={() => form.submit()}>
                    Guardar
                </Button>
                <Button onClick={() => form.resetFields()}>
                    Cancelar
                </Button>
            </Space>
        </Form>
    );
}

export default ProfileData;