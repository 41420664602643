import React from "react";

import "./Footer.css";

function Footer() {
  const socialNetworks = [
    {
      id: 1,
      name: "Facebook",
      link: "https://www.facebook.com/involucrate.uy",
    },
    {
      id: 2,
      name: "Twitter",
      link: "https://twitter.com/Involucrate_uy",
    },
    {
      id: 3,
      name: "Instagram",
      link: "https://www.instagram.com/involucrate.uy/",
    },
  ];

  const footerItems = socialNetworks.map((item) => {
    return (
      <li className="footer-list-item" key={item.id}>
        <a className="footer-list-item-a" href={item.link} />
      </li>
    );
  });

  return (
    <footer className="footer">
      <div className="container">
        <p className="footer-p">&copy; Involucrate, Copyright 2018</p>
        <ul className="footer-list">{footerItems}</ul>
      </div>
    </footer>
  );
}

export default Footer;
