import React from "react";
import logo from "../../../images/new-home/aboutUs.png";

function About() {
  return (
    <div id="h-aboutUs">
      <img src={logo} />
      <div className="title">¿Quiénes Somos?</div>
      <div className="text">
        <div>
          <b>Involucrate</b> es un portal de voluntariado y donaciones que busca
          ser una plataforma de nexo y acercamiento.
          <div className="separador" />
          Somos un grupo de voluntarios convocados por el{" "}
          <b>Fondo para Emprendimientos Solidarios</b> y creemos en la
          importancia de la solidaridad, el compromiso y la acción en el
          desarrollo de la sociedad y en el desarrollo personal.
          <div className="separador" />
          De diferentes perfiles y diferentes edades, todos aportamos para
          generar un <b>nexo</b> entre las organizaciones y las personas que
          buscan el bien común.
          <div className="separador" />
          Somos un grupo abierto en continuo crecimiento que{" "}
          <b>busca la manera más efectiva de aportar positivamente</b> a la
          sociedad.
        </div>
      </div>
    </div>
  );
}

export default About;
