import React, { useState, useEffect, useRef } from "react";
import OportunidadModal from "./components/oportunidadModal";
import { Form, Button, Radio, Space } from "antd";
import { Requeriment } from "../../utils/models";

const Oportunidades = (props: any) => {
  const nombre: string = "oportunidades-form";
  const requerimientos: Requeriment[] = [
    {
      tab_name: "referente-de-voluntariado-form",
      requeriments: ["recibir_soporte"],
    },
  ];
  const data: any = props.data;
  const tituloForm = "Voluntariado";
  const [form] = Form.useForm();
  const actualizarDatos = props.actualizarDatos;

  const setActiveTabName = props.setActiveTabName;
  useEffect(() => {
    setActiveTabName(nombre)
  }, [])

  const procesarDatos = (valores: any) => {
    return oportunidades
  }

  const FW = props.fw;

  const [oportunidades, setOportunidades] = useState(data[nombre] ?? []);

  const [numOportunidades, setNumOportunidades] = useState(oportunidades.length);
  const oportunidadesMax: number = 5;
  const [selectedOportunidad, setSelectedOportunidad] = useState(oportunidadesMax);

  const sedes = props.data["sedes-form"];
  const sedePrincipal = props.data["sede-principal-form"];

  const addOportunidad = (oportunidad: any) => {
    setOportunidades((prevState: any) => prevState.concat(oportunidad));
    setNumOportunidades((oldNumOportunidades: any) => oldNumOportunidades + 1);
    setSelectedOportunidad(oportunidadesMax);
  }

  const updateOportunidad = (oportunidad: any) => {
    setOportunidades((prevState: any) => {
      prevState[selectedOportunidad] = oportunidad;
      return prevState;
    });
    setSelectedOportunidad(oportunidadesMax);
  }

  const deleteOportunidad = () => {
    setOportunidades((prevState: any) => {
      prevState.splice(selectedOportunidad, 1);
      return prevState;
    });
    setNumOportunidades((oldNumOportunidades: any) => oldNumOportunidades - 1);
    setSelectedOportunidad(oportunidadesMax);
  }

  const changeSelectedOportunidad = (e: any) => {
    setSelectedOportunidad(e.target.value);
  }

  const clearSSelectedOportunidad = (e: any) => {
    if (+e.target.value === +selectedOportunidad) {
      setSelectedOportunidad(oportunidadesMax);
    }
  }

  const saveDataButtonRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (props.modo === "edicion") {
      saveDataButtonRef!.current!.click();
    }
  }

  return (
    <FW
      nombre={nombre}
      tituloForm={tituloForm}
      data={data}
      form={form}
      actualizarDatos={actualizarDatos}
      procesarDatos={procesarDatos}
      requerimientos={requerimientos}
    >
      <div className="sedes-container">
        <div className="caption">Ingresar aquí las oportunidades de voluntariado</div>
        <Button
          ref={saveDataButtonRef}
          style={{ display: 'none' }}
          onClick={() => { }}
          htmlType="submit"
          form={nombre}
        >
        </Button>
        <div className="buttons-container">
          <div className="button-container" >
            {(numOportunidades >= oportunidadesMax) &&
              <OportunidadModal
                formName={`new-oportunidad-${numOportunidades}-form`}
                onFinishForm={(oportunidad: any) => {
                  addOportunidad(oportunidad);
                }}
                buttonMessage={"Agregar"}
                oportunidad={{}}
                disabled={true}
                sedes={sedes}
                sedePrincipal={sedePrincipal}
                modo={props.modo}
              />
            }
            {!(numOportunidades >= oportunidadesMax) &&
              <OportunidadModal
                formName={`new-oportunidad-${numOportunidades}-form`}
                onFinishForm={(oportunidad: any) => {
                  addOportunidad(oportunidad);
                  handleClick()
                }}
                buttonMessage={"Agregar"}
                oportunidad={{}}
                sedes={sedes}
                sedePrincipal={sedePrincipal}
                modo={props.modo}
              />
            }
          </div>
          <div className="button-container" >
            {(numOportunidades < 1 || selectedOportunidad >= oportunidadesMax) &&
              <OportunidadModal
                formName={`oportunidad-${selectedOportunidad}-form`}
                onFinishForm={(oportunidad: any) => {
                  updateOportunidad(oportunidad);
                }}
                buttonMessage={"Modificar"}
                oportunidad={oportunidades[selectedOportunidad] ?? {}}
                disabled={true}
                sedes={sedes}
                sedePrincipal={sedePrincipal}
                modo={props.modo}
              />
            }
            {!(numOportunidades < 1 || selectedOportunidad >= oportunidadesMax) &&
              <OportunidadModal
                formName={`oportunidad-${selectedOportunidad}-form`}
                onFinishForm={(oportunidad: any) => {
                  updateOportunidad(oportunidad);
                  handleClick()
                }}
                buttonMessage={"Modificar"}
                oportunidad={oportunidades[selectedOportunidad] ?? {}}
                sedes={sedes}
                sedePrincipal={sedePrincipal}
                modo={props.modo}
              />
            }
          </div>
          <div className="button-container" >
            {(numOportunidades < 1 || selectedOportunidad >= oportunidadesMax) &&
              <Button
                className="btn-disabled"
                type="primary"
                danger
                onClick={() => { deleteOportunidad() }}
                disabled={true}
              >
                Eliminar
              </Button>
            }
            {!(numOportunidades < 1 || selectedOportunidad >= oportunidadesMax) &&
              <Button
                type="primary"
                danger
                onClick={() => {
                  deleteOportunidad()
                  handleClick()
                }}
              >
                Eliminar
              </Button>
            }
          </div>
        </div>
        <Radio.Group onChange={changeSelectedOportunidad} value={selectedOportunidad}>
          <Space direction="vertical">
            {oportunidades.map((value: any, index: number) => {
              return (
                <Radio key={index} value={index} onClick={clearSSelectedOportunidad}>{value.titulo}</Radio>
              )
            })
            }
          </Space>
        </Radio.Group>
      </div>
    </FW>
  );
}

export default Oportunidades;
