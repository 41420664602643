import React from "react";
import "./oportunidades.css";
import illustration1 from "../../../images/new-login/Illustration1.png";
import illustration2 from "../../../images/new-login/Illustration2.png";
import illustration3 from "../../../images/new-login/Illustration3.png";
import illustration4 from "../../../images/new-login/Illustration4.png";
import illustration5 from "../../../images/new-login/Illustration5.png";

const Oportunidades = () => {
  return (
    <div id="oportunidades">
      <div className="titulo">¿Qué te ofrecemos?</div>
      <div className="lista">
        <div className="oportunidad-container o1">
          <div className="oportunidad">
            <img src={illustration1} />
            <div className="mensaje">Accesibilidad al voluntariado</div>
            <div className="contenido">
              La organización llegará a <b>más voluntarios</b> fuera de su red
              de contactos. Esto le permitirá encontrar candidatos más adecuados
              para cada tarea.
            </div>
          </div>
        </div>
        <div className="oportunidad-container o2">
          <div className="oportunidad">
            <img src={illustration2} />
            <div className="mensaje">Accesibilidad de donaciones</div>
            <div className="contenido">
              Sus necesidades materiales tendrán <b>más visibilidad</b>.
            </div>
          </div>
        </div>
        <div className="oportunidad-container o3">
          <div className="oportunidad">
            <img src={illustration3} />
            <div className="mensaje">Gestión de voluntarios</div>
            <div className="contenido">
              Las organizaciones tienen una página especial para
              <b>gestionar a los voluntarios</b> que se presentaron a sus
              llamados.
            </div>
          </div>
        </div>
        <div className="oportunidad-container o4">
          <div className="oportunidad">
            <img src={illustration4} />
            <div className="mensaje">Mayor visibilidad</div>
            <div className="contenido">
              Al aparecer en el mapa de organizaciones, será más fácil que otros
              agentes interesados <b>conozcan a tu organización</b> y tus
              objetivos.
            </div>
          </div>
        </div>
        <div className="oportunidad-container o5">
          <div className="oportunidad">
            <img src={illustration5} />
            <div className="mensaje">Comunidad de organizaciones</div>
            <div className="contenido">
              Motivamos el <b>compartir conocimiento</b> entre todas las
              organizaciones para solucionar problemas comunes y aprovechar las
              oportunidades de sinergias.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Oportunidades;
