//Constantes con los mensajes de las alertas

export const errorResponse = {
  typeAlert: "warning",
  msgAlert: "Ocurrió un error al enviar su consulta",
  titleAlert: "Algo salió mal",
};

export const successResponse = {
  typeAlert: "success",
  msgAlert: "Su consulta se ha enviado con éxito",
  titleAlert: "Enviada!",
};
