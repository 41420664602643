class VolunteerInfo {
  private _id: number;
  private _nombre: string;
  private _apellido: string;
  private _celular: string;
  private _email: string;
  private _interesVoluntariado: string;
  private _event_id: number;

  constructor(name: string, _apellido: string, phoneNumber: string, email: string, interesVoluntariado: string, event_id: number) {
    this._id = -1;
    this._nombre = name;
    this._apellido = _apellido;
    this._celular = phoneNumber;
    this._email = email;
    this._interesVoluntariado = interesVoluntariado;
    this._event_id = event_id;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get nombre(): string {
    return this._nombre;
  }

  set nombre(value: string) {
    this._nombre = value;
  }

  get celular(): string {
    return this._celular;
  }

  set celular(value: string) {
    this._celular = value;
  }

  get interesVoluntariado(): string {
    return this._interesVoluntariado;
  }

  set interesVoluntariado(value: string) {
    this._interesVoluntariado = value;
  }

  get apellido(): string {
    return this._apellido;
  }

  set apellido(value: string) {
    this._apellido = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get event_id(): number {
    return this._event_id;
  }

  set event_id(value: number) {
    this._event_id = value;
  }
}

export default VolunteerInfo;
