import { useState, useEffect } from "react";
import ajaxAction, { Config } from "../../../utils/ajaxAction";
import { getEventById } from "../../../utils/constants";
import { eventDtoToEntity } from "../../../utils/mappers/EventMapper";
import { Evento } from "../../../utils/entities/Evento";

const useEvento = (id: string) => {
  const [event, setEvent] = useState({} as Evento);

  useEffect(
    () => {
      const config: Config = {
        service: `${getEventById}${id}`,
        method: "GET",
        auth: "",
      };

      ajaxAction(config)
        .then(async (response: any) => {
          const data = await response.json();
          let dataMappeada = eventDtoToEntity(data);
          setEvent(dataMappeada);
        })
        .catch((err: any) => console.log(err));
    },
    [id]
  );

  return event;
};

export default useEvento;
