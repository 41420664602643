import React, { useState } from "react";
import "antd/dist/antd.css";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Modal, Upload, UploadFile } from "antd";
import { UploadChangeParam } from "../../../utils/models";
import { FileStatus } from "../../../utils/constants";

const dummyRequest = ({ file, onSuccess, }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    console.log("GetBase64", file)
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

const beforeUpload = (file: any) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error({
      type: "success",
      content: "Solo se puede subir un archivo JPG o PNG.",
      className: "ong-datos-principales",
      duration: 6,
    });
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error({
      type: "success",
      content: "La imagen debe ser menor a 2MB.",
      className: "ong-datos-principales",
      duration: 6,
    });
  }
  return isJpgOrPng && isLt2M;
};

const UploadGallery = (props: any) => {
  const uploadForm: Function = props.uploadForm;

  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const preList = props.fileList ? props.fileList : [];
  const [fileList, setFileList] = useState<UploadFile[]>(preList);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      console.log("HandlePreview!", file)
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async (info: UploadChangeParam<UploadFile>) => {    
    const file: UploadFile = info.file;
    const fileList: UploadFile[] = info.fileList;

    if (file.status !== FileStatus.remove && !beforeUpload(file)) {
      return;
    }

    if (file.status === FileStatus.upload) {
      setLoading(true);
    }

    if (file.status === FileStatus.done) {
      setLoading(false);
      const fotos: any[] = await obtenerFotos(fileList);

      uploadForm(fotos);
    } else if (file.status === FileStatus.remove) {      
      const fotos: any[] = await obtenerFotos(fileList);
      uploadForm(fotos);
    }    
    setFileList(fileList);
  };

  async function obtenerFotos(fileList: UploadFile<any>[]) {
    const doneList: UploadFile[] = fileList.filter(
      (file: UploadFile) => file.status === FileStatus.done
    );
    const fotos: any[] = await Promise.all(
      doneList.map((file: UploadFile) => {
        if (file.originFileObj) {
          return getBase64(file.originFileObj).then((result: any) => {
            return { id: '', fotoContent: result };
          });
        } else {
          return { id: file.uid, fotoContent: file.url };
        }
      })
    );
    return fotos;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Cargar</div>
    </div>
  );

  return (
    <>
      <Upload
        name={"file"}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={dummyRequest}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default UploadGallery;


