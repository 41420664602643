import React, { Fragment, useEffect, useState } from "react";
import navBarLogo from "../../../../images/new-home/NavBar.png";
import { useNavigate } from "react-router-dom";

import AuthService from "../../../../services/auth/AuthService";

import "./menu.css";
import { Link } from "react-router-dom";

const Menu = () => {
  const items = [
    { id: 1, name: "Donar", link: "/donar" },
    { id: 2, name: "Voluntariado", link: "/voluntariado" },
    { id: 3, name: "Mi ONG", link: "/editarONG" },
    { id: 4, name: "Contacto", link: "/contact" },
    { id: 5, name: "FAQ", link: "/faq" },
  ];

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const fetchJWT = async () => {
      const jwt = await AuthService.loggedInFrontOffice();
      if (jwt !== null) {
        setIsLoggedIn(true);
      }
    };
    fetchJWT();
  }, []);

  const logout = () => {
    AuthService.logOutFrontOffice();
    navigate("/mapa/");
    window.location.reload();
  };

  const login = () => {
    navigate("/mapa/login");
  };

  return (
    <Fragment>
      <div className="barra" />
      <nav className="navbar navbar-expand-lg navbar-light">
        <a className="logo" href="/">
          <img src={navBarLogo} alt="involucrate logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav">
            {items.map((item) => {
              return (
                <Link
                  style={{ color: "unset", height: "46px" }}
                  to={item.link}
                  key={item.id}
                >
                  {windowSize[0] < 992 && (
                    <span data-toggle="collapse" data-target="#navbarCollapse">
                      {item.name}
                    </span>
                  )}
                  {windowSize[0] >= 992 && <span>{item.name}</span>}
                </Link>
              );
            })}
          </div>
          {isLoggedIn && (
            <button
              onClick={logout}
              className="btn btn-primary my-button logout-button"
            >
              <i className="fa fa-sign-out" />
              <div>Salir</div>
            </button>
          )}
          {!isLoggedIn && (
            <button
              onClick={login}
              className="btn btn-primary my-button login-button"
            >
              <div>Ingresar</div>
            </button>
          )}
        </div>
      </nav>
    </Fragment>
  );
};

export default Menu;
