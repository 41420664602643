import React from "react";
import { Button } from "antd";

interface ButtonConf {
  retroceder: boolean;
  avanzar: boolean;
  guardar: boolean;
}

export const navigation = (props: any) => {
  const buttonConf: ButtonConf = props.buttonConf;
  const { form, guardar, retroceder, avanzar } = props;

  return (
    <div className="navigation-buttons">
      {buttonConf.avanzar && (
        <Button
          className="btn-navigacion avanzar"
          form={form}
          type="primary"
          htmlType="submit"
          onClick={() => avanzar()}
        >
          Avanzar
        </Button>
      )}
      {buttonConf.guardar && (
        <Button
          className="btn-navigacion guardar"
          form={form}
          type="primary"
          htmlType="submit"
          onClick={() => guardar()}
        >
          Guardar
        </Button>
      )}
      {buttonConf.retroceder && (
        <Button
          className="btn-navigacion retroceder"
          form={form}
          htmlType="submit"
          onClick={() => retroceder()}
        >
          Retroceder
        </Button>
      )}
    </div>
  );
};

export default navigation;
