import React from "react";
import { updateStatistics } from "../../../utils/services/StatisticsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Button } from "antd";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

interface RedesSocialesProps {
  ongId?: number;
  web?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  gallery?: string[];
}

const parse_addres = (domain: string, address?: string) => {
  if (address) {
    return address
      .replace("https://", "")
      .replace(`www.${domain}.com/`, "")
      .replace(`${domain}.com/`, "")
      .replace("@", "");
  }
  // Probar con la siguiente regex
  // return url.replace(/(http.:\/\/|www\.(facebook|twitter|instagram)\.com[^\/]*|\?.*)/g, '');
  return "";
};

function RedesSociales(props: RedesSocialesProps) {
  const { ongId, instagram, facebook, twitter, web } = props;

  if (facebook || instagram || twitter || web) {
    return (
      <div className="redes-sociales">
        <div className="subtitulo">Sitio web y redes sociales</div>
        <div className="redes-container">
          {facebook && (
            <Button
              className="ant-btn ant-btn-primary btn-red btn-facebook"
              onClick={() => {
                updateStatistics(ongId, "facebook", "voluntario");
              }}
              href={facebook}
              target="blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
              <span>/{parse_addres("facebook", facebook)}</span>
            </Button>
          )}
          {instagram && (
            <Button
              className="ant-btn ant-btn-primary btn-red btn-instagram"
              onClick={() => {
                updateStatistics(ongId, "instagram", "voluntario");
              }}
              href={instagram}
              target="blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
              <span>/{parse_addres("instagram", instagram)}</span>
            </Button>
          )}
          {twitter && (
            <Button
              className="ant-btn ant-btn-primary btn-red btn-twitter"
              onClick={() => {
                updateStatistics(ongId, "twitter", "voluntario");
              }}
              href={twitter}
              target="blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
              <span>/{parse_addres("twitter", twitter)}</span>
            </Button>
          )}
          {web && (
            <Button
              className="ant-btn ant-btn-primary btn-red btn-web"
              onClick={() => {
                updateStatistics(ongId, "web", "voluntario");
              }}
              href={web}
              target="blank"
            >
              <FontAwesomeIcon icon={faGlobe} />
              <span>{web}</span>
            </Button>
          )}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default RedesSociales;
