import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import VolunteerInfo from "../../../types/VolunteerInfo";
import { submitVolunteerInscription } from "../../../services/VolunteerInscriptionService";
import { Checkbox, Form, Input, Alert } from "antd";
import { MaskedInput } from "antd-mask-input";
import PhoneNumberInput from "../../PhoneNumberInput/PhoneNumberInput";
import {
  countryOptions,
  validatePhoneNumber,
} from "../../../utils/phoneNumberConstants";
import { useNavigate } from "react-router";

interface DetalleProps {
  eventoId: number;
  onFinishForm: () => void;
}

const FormInvolucrate = (props: DetalleProps) => {
  /*
    TODO:
    Arreglar la respuesta en el front
    Adaptar el backend al nuevo objeto  
    */

  const eventoId = props.eventoId;
  const onFinishForm = props.onFinishForm;

  const [form] = Form.useForm<{
    nombre: string;
    apellido: string;
    email: string;
    country: string;
    phoneNumber: string;
    interesVoluntariado: string;
    tyc: boolean;
  }>();

  const nombreValue = Form.useWatch("nombre", form);
  const apellidoValue = Form.useWatch("apellido", form);
  const emailValue = Form.useWatch("email", form);
  const country = Form.useWatch("country", form);
  const phoneNumberValue = Form.useWatch("phoneNumber", form);
  const interesVoluntariadoValue = Form.useWatch("interesVoluntariado", form);
  const [displayError, setDisplayError] = useState(false as boolean);
  const [displaySuccess, setDisplaySuccess] = useState(false as boolean);

  const [validationError, setValidationError] = useState(false as boolean);

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const addCountryPrefixNumber = (countryCode: string, number: string): string => {
    return (countryOptions.find(option => option.country === countryCode)?.startsWith || "") + number;
  };

  const sendForm = () => {
    const newVolunteer: VolunteerInfo = new VolunteerInfo(
      nombreValue,
      apellidoValue,
      addCountryPrefixNumber(country, phoneNumberValue),
      emailValue,
      interesVoluntariadoValue,
      eventoId
    );

    submitVolunteerInscription(newVolunteer)
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 201) {
          setDisplaySuccess(true);
        } else {
          setDisplayError(true);
        }
      })
      .catch((err) => {
        setDisplayError(true);
      });
  };

  const resetForm = () => {
    form.resetFields();
    setValidationError(false);
  };

  const onFinish = (errorInfo: any) => {
    setValidationError(false);
    sendForm();
    onFinishForm();
  };

  const onFinishFailed = (errorInfo: any) => {
    setValidationError(true);
  };

  return (
    <div id="involucrarme-modal">
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        initialValues={{ country: "UY" }}
      >
        <p ref={scrollRef}>
          Para involucrarte completa los siguientes datos para que se pongan en
          contacto contigo:
        </p>
        <Form.Item
          label="Nombre"
          name="nombre"
          rules={[
            { required: true, message: "Por favor ingrese su nombre" },
            {
              max: 255,
              message: "El nombre no debe exceder los 255 caracteres.",
            },
            {
              message: "Solo se permiten letras y espacios",
              pattern: new RegExp(/^[a-zA-ZñáäéíóúüÁÄÉÍÓÚÜ\s]*$/),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Apellido"
          name="apellido"
          rules={[
            { required: true, message: "Por favor ingrese su apellido" },
            {
              max: 255,
              message: "El apellido no debe exceder los 255 caracteres.",
            },
            {
              message: "Solo se permiten letras y espacios",
              pattern: new RegExp(/^[a-zA-ZñáäéíóúüÁÄÉÍÓÚÜ\s]*$/),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "El email ingresado no es válido",
            },
            { required: true, message: "Por favor ingrese su email" },
            {
              max: 255,
              message: "El email no debe exceder los 255 caracteres.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <PhoneNumberInput
          label="Número de teléfono"
          form={form}
          validateCallback={(phoneNumber: string) =>
            validatePhoneNumber(phoneNumber, country)
          }
          validateRequired={() => true}
          formInfoName="phoneNumber"
          countryInfoName="country"
          options={countryOptions}
          isUpdated={false}
          disabled={false}
        />

        <Form.Item
          name="interesVoluntariado"
          label="¿Por qué te interesa este voluntariado?"
          className="simpleLabel"
          rules={[
            {
              max: 140,
              message:
                "El interés de voluntariado no debe exceder los 140 caracteres",
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Ingresar un motivo" />
        </Form.Item>

        <Form.Item
          name="tyc"
          valuePropName="checked"
          wrapperCol={{ span: 16 }}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                    new Error("Tiene que aceptar los terminos y condiciones.")
                  ),
            },
          ]}
        >
          <Checkbox>
            <Link
              className="terminos"
              to="/terminos-y-condiciones"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver términos y condiciones
            </Link>
          </Checkbox>
        </Form.Item>
      </Form>

      {validationError && (
        <Alert
          message="Error"
          description="Por favor, complete todos los campos correctamente."
          type="error"
          showIcon
        />
      )}

      {displaySuccess && (
        <Alert
          message="Solicitud enviada"
          description="Se procesó con éxito su solicitud, en breve se pondrán en contacto contigo."
          type="success"
          showIcon
          closable
          afterClose={() => {
            setDisplaySuccess(false);
            resetForm();
          }}
        />
      )}
      {displayError && (
        <Alert
          message="No se pudo enviar su solicitud"
          description="Hubo un error inesperado al procesar su solicitud, inténtelo nuevamente o comuníquese con los responsables."
          type="error"
          showIcon
          closable
          afterClose={() => {
            setDisplayError(false);
            resetForm();
          }}
        />
      )}
    </div>
  );
};

export default FormInvolucrate;
