import React, { useEffect, useState } from "react";
import { Requeriment } from "../../utils/models";
import { Form, Input } from "antd";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { LatLng } from "../../utils/models";
import Map from "./components/map";

const getCoordinates = (location: any) => {
  const lat = location.lat();
  const lng = location.lng();
  return { lat: lat, lng: lng };
};

const getFormValues = (direccion: any) => {
  const coordenadas = getCoordinates(direccion.geometry.location);
  const place_id = direccion.place_id;

  return { coordenadas: coordenadas, place_id: place_id };
};

const defaultCoordenadas: LatLng = {
  lat: -34.8833,
  lng: -56.1667,
};

const Sede = (props: any) => {
  const nombre: string = "sede-principal-form";
  const requerimientos: Requeriment[] = [];
  const data: any = props.data;
  const tituloForm = "Sede principal";
  const [form] = Form.useForm();
  const actualizarDatos = props.actualizarDatos;

  const setActiveTabName = props.setActiveTabName;
  useEffect(() => {
    setActiveTabName(nombre)
  }, [])

  const procesarDatos = (valores: any) => {
    return {
      ...valores,
      direccion: direccion,
      coordenadas: coordenadas,
    }
  }

  const FW = props.fw;

  const options = {
    componentRestrictions: { country: ["uy"] },
  };

  const handleChange = (address: any) => {
    setDireccion(address);
  };

  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then((results) => {
        const values = getFormValues(results[0]);
        setCoordenadas(values.coordenadas);
        setDireccion(address);
        form.setFieldsValue({ direccion: address });
        return getLatLng(results[0]);
      })
      .catch((error) => console.error("Error", error));
  };

  const [direccion, setDireccion] = useState(data[nombre] ? data[nombre].direccion ?? "" : "");

  const [coordenadas, setCoordenadas] = useState(
    data[nombre] ? data[nombre].coordenadas ?? defaultCoordenadas : defaultCoordenadas
  );

  return (
    <FW
      nombre={nombre}
      tituloForm={tituloForm}
      data={data}
      form={form}
      actualizarDatos={actualizarDatos}
      procesarDatos={procesarDatos}
      requerimientos={requerimientos}
    >
      {props.modo === "edicion" ? (
        <Form.Item label="id" name="id" className="idField">
          <Input placeholder="id" />
        </Form.Item>
      ) : (
        ""
      )}
      <PlacesAutocomplete
        value={direccion}
        onSelect={handleSelect}
        onChange={handleChange}
        searchOptions={options}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            style={{
              position: "relative",
            }}
          >
            <Form.Item
              name="direccion"
              colon={false}
              hasFeedback
              label={
                <span>
                  Dirección (Puedes buscar tu dirección en el siguiente campo)
                </span>
              }
              rules={[
                {
                  validator(_, value) {
                    if (direccion === "") {
                      return Promise.reject("Por favor ingresar una dirección");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              style={{ marginBottom: " 0px" }}
            >
              <Input
                {...getInputProps({
                  placeholder: "Ingresar dirección...",
                })}
              />
            </Form.Item>
            <div
              className="autocomplete-dropdown-container"
              style={{
                position: "absolute",
                backgroundColor: "white",
                zIndex: 2,
                width: "100%",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index: number) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={index}
                  >
                    <div>{suggestion.description}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <Form.Item label="Place_id" name="place_id" hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item label="UbicacionId" name="ubicacionId" hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item name="pisoDepto" label="Información adicional" hasFeedback>
        <Input placeholder="Ingresar información adicional (Piso, Departamento...)" />
      </Form.Item>
      <Form.Item
        label={
          <span>
            Verificar si la ubicación es correcta, de lo contrario, corregir en el mapa:
          </span>
        }
      >
        <Map
          setCoordenadas={setCoordenadas}
          coordenadas={coordenadas}
        />
      </Form.Item>
    </FW>
  );
}

export default Sede;
