import { Alert, Button, Checkbox, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import navBarLogo from '../../../../images/new-home/NavBar.png';
import AuthService from '../../../../services/auth/AuthService';
import { backOfficeUrl } from '../../../../../src/utils/constants';

import './login.css';

const Login = () => {
  const [failure, setFailure] = useState<boolean>(false);
  const [failureText, setFailureText] = useState<string>('');
  const location = useLocation();  
  const from = location.state?.from?.pathname || backOfficeUrl;

  const onFinish = (values: any) => {
    AuthService.loginBackOffice(
      '/auth2/login/backoffice/',
      values.username,
      values.password
    ).then(([success, value]: [boolean, string]) => {
      if (success) {
        window.location.replace(from);
      } else {
        setFailure(true);
        setFailureText(value);
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div id="backoffice-login">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <a className="logo" href={backOfficeUrl}>
          <img src={navBarLogo} />
        </a>
      </nav>

      {failure && (
        <div style={{ marginBottom: '5px' }}>
          <Alert message={failureText} closable type="error" />
        </div>
      )}

      <div className="form-container">
        <div className="titulo">Login para administradores</div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col style={{ marginBottom: '5px' }}>
              <Form.Item
                label="Email"
                name="username"
                rules={[{ required: true, message: 'Ingresar email.' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col style={{ marginBottom: '5px' }}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Ingresar la contraseña.' }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{ marginBottom: '5px' }}>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{ marginBottom: '5px' }}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Log In
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Login;
