import React, { useRef, useState } from "react";
import "./ongs.css";
import useAllOngs from "../../../services/useAllOngs";
import { Params } from "../../../models/params";
import { OngBasic } from "../../../models/ongBasic";
import { useNavigate } from "react-router-dom";
import { Radio, Button, Input, Pagination, RadioChangeEvent } from "antd";

const arrStatusTypes = ["Pendiente", "Habilitada", "Deshabilitada"] as const;
type statusTypes = typeof arrStatusTypes[number];

const ONGs = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<statusTypes>(arrStatusTypes[0]);
  const tempSearch = useRef<string>("");
  const [search, setSearch] = useState<string>(tempSearch.current);
  const [page, setPage] = useState(1);
  const [selectedONG, setSelectedONG] = useState<number>(-1);
  const params: Params | undefined = useAllOngs(status, search, page - 1);

  const handleNavigationChange = (newCurrent: number, newPageSize: number) => {
    setPage(newCurrent);
    setSelectedONG(-1);
  };

  const handleSearch = () => {
    setSelectedONG(-1);
    setPage(1);
    setSearch(tempSearch.current);
  };

  const handleSearchChange = (event: any) => {
    tempSearch.current = event.target.value;
  };

  const handleStatusChange = (statusType: statusTypes) => {
    setSelectedONG(-1);
    setPage(1);
    setSearch("");
    setStatus(statusType);
  };

  const handleSelectedOngChange = (e: RadioChangeEvent) => {
    setSelectedONG(e.target.value);
  };

  const verONG = () => {
    navigate(`/backoffice/ongs/${selectedONG}`);
  };

  const nuevaONG = () => {
    navigate(`/backoffice/ong/nuevaong`);
  };

  return (
    <div id="ong-container">
      <div className="titulo">Listado de ONGs</div>
      {params && (
        <div className="table-container">
          <div className="lista-status">
            {arrStatusTypes.map((statusType: statusTypes, index: number) => {
              return (
                <div
                  key={statusType}
                  className="status-container"
                  onClick={() => handleStatusChange(statusType)}
                >
                  <div
                    className={
                      "status" + (status == statusType ? " selected" : "")
                    }
                  >
                    {statusType}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="input-container">
            <Input
              placeholder="Busca el nombre de tu ONG"
              onChange={handleSearchChange}
            />
            <Button
              onClick={() => {
                handleSearch();
              }}
            >
              Buscar
            </Button>
          </div>

          <div className="options-container">
            <Button
              disabled={selectedONG < 0}
              onClick={verONG}>
              Ver ONG
            </Button>
            <Button onClick={nuevaONG}>
              Nueva ONG
            </Button>
          </div>

          <div className="tabla-container">
            <table>
              <tbody>
                <tr className="header-row">
                  <th className="select-column" />
                  <th className="nombre-column">Nombre</th>
                  <th className="email-column">Email</th>
                  <th className="telefono-column">Teléfono</th>
                </tr>
                {params.ongs.map((ong: OngBasic, index: number) => {
                  return (
                    <tr key={ong.id}>
                      <td className="select-column">
                        <Radio.Group
                          onChange={handleSelectedOngChange}
                          value={selectedONG}
                        >
                          <Radio value={ong.id} />
                        </Radio.Group>
                      </td>
                      <td className="nombre-column">{ong.name}</td>
                      <td className="email-column">{ong.email}</td>
                      <td className="telefono-column">{ong.phone}</td>
                    </tr>
                  );
                })}</tbody>
            </table>
          </div>
          <div className="pagination-container">
            <Pagination
              current={page}
              showSizeChanger={false}
              total={params.totalItems}
              pageSize={10}
              onChange={handleNavigationChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ONGs;
