import React, { useEffect, useState } from "react";
import Title from "../../../../../components/volunteering/evento/title";


import "./index.css";
import { useNavigate, useParams } from "react-router";

import MiniMap from "../../../../../components/volunteering/evento/mini-map";

import Detalle from "../../../../../components/volunteering/evento/detalle";
import Description from "../../../../../components/volunteering/evento/description";
import Direccion from "../../../../../components/volunteering/evento/direccion";
import RedesSociales from "../../../../../components/volunteering/evento/redes_sociales";
import GaleriaVoluntariado from "../../../../../components/volunteering/evento/galeria_voluntariado";

import useEvento from "../../../../../components/volunteering/evento/useEvento";
import { Evento } from "../../../../../utils/entities/Evento";

import { Modal } from 'antd';
import FormInvolucrate from "../../../../../components/volunteering/evento/form_involucrate";
import { Ong } from "../../../../../utils/entities/Ong";
import useOng from "../../../../../components/volunteering/evento/useOng";

const Index = (props: any) => {
  const { eventoId } = useParams();
  const navigate = useNavigate();

  //Estados para los eventos (se seleccionan en search y se usan en map)
  const evento: Evento = useEvento(eventoId!);
  const ong: Ong = useOng(evento.ongId);

  useEffect(
    () => {
      try {
        if (isNaN(parseInt(eventoId!)) && !isFinite(parseInt(eventoId!))) {
          throw new Error("Error! The id is not numeric");
        }
      } catch (error) {
        console.log(error);
        navigateToPreviousPage();
      }
    },
    [eventoId]
  );

  const navigateToPreviousPage = () => {
    navigate(-1);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    //setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div id="event-oportunity">
      <div className="event-content">
        <div className="event-page">
          {evento?.id && <Title evento={evento} navigate_to_map={navigateToPreviousPage} />}
          {evento?.id && (ong?.photo || ong?.description) ? (
            <div className="event-subheader">
              {ong.photo && (
                <img alt={ong.name} src={ong.photo} className="event-foto" />
              )}
              {<Description ong={ong} />}
            </div>
          ) : (null)}
          {evento?.id && ong.galeria && <GaleriaVoluntariado fotos={ong.galeria} />}
          {evento?.id && <Detalle
            evento={evento}
            showModal={showModal}
          />}
          {evento?.id && <Direccion ongId={evento.ongId} />}
          {evento?.id && <MiniMap evento={evento} />}
          {evento?.id && <RedesSociales ongId={evento.ongId} />}
        </div>
      </div>
      <Modal
        key={`Involucrarme`}
        title={`Involucrate en ${evento.titulo}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ form: 'basic', htmlType: 'submit' }}
        className="modalStyle"
        okText="Involucrarme"
      >
        <FormInvolucrate
          eventoId={evento.id}
          onFinishForm={() => {
            setIsModalVisible(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default Index;
