import { useState, useEffect } from "react";
import { getVolunteersFilters } from "../../../utils/constants";
import ajaxAction, { Config } from "../../../utils/ajaxAction";
import { arrayVolunteerCategoryDtoToEntity } from "../../../utils/mappers/VolunteerCategoryMapper";
import { VolunteerCategory } from "../../../utils/entities/VolunteerFilter";

const configFilters: Config = {
  service: getVolunteersFilters,
  method: "GET",
};
const useFilters = () => {
  const [filters, setFilters] = useState([] as VolunteerCategory[]);

  useEffect(() => {
    ajaxAction(configFilters)
      .then(async (response) => {
        const data = await response.json();
        setFilters(arrayVolunteerCategoryDtoToEntity(data[0].categorias));
      })
      .catch((err) => console.log(err));
  }, []);

  return filters;
};

export default useFilters;
