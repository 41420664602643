import React, { useEffect, useState } from "react";
import { Form, Input, Select, Checkbox, Collapse } from "antd";
import ajaxAction, { Config } from "../../../../../utils/ajaxAction";
import { getVolunteersFilters } from "../../../../../utils/constants";
const { Option } = Select;

type DiasSemana = {
  name: string;
  value: string;
};

const diasDeLaSemana: DiasSemana[] = [
  { name: "dias_lunes", value: "Lunes" },
  { name: "dias_martes", value: "Martes" },
  { name: "dias_miercoles", value: "Miércoles" },
  { name: "dias_jueves", value: "Jueves" },
  { name: "dias_viernes", value: "Viernes" },
  { name: "dias_sabado", value: "Sabado" },
  { name: "dias_domingo", value: "Domingo" },
];

const config: Config = {
  service: getVolunteersFilters,
  method: "GET"
};

const OportunidadForm = (props: any) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (categories.length < 1) {
      ajaxAction(config)
        .then(async (response) => {
          const data = await response.json();
          setCategories(data[0].categorias);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const name = props.name;
  const oportunidad = props.oportunidad;

  const onFinishForm = props.onFinishForm;
  const sedes = props.sedePrincipal ? [props.sedePrincipal, ...props.sedes] : [];

  if (oportunidad.id) {
    const sedeIndexSelected = sedes.findIndex(sede => sede.id === oportunidad.sedeId);
    if (sedeIndexSelected !== -1) {
      oportunidad.sede = sedeIndexSelected.toString();
    }

    oportunidad.dias = diasDeLaSemana.reduce((acc, dia) => {
      if (oportunidad[dia.name] === true) {
        return acc = [...acc, dia.name];
      } else {
        return acc;
      }
    }, [] as string[]);
  }

  const [checkedValues, setCheckedValues] = useState([]);

  const getFormValues = (formValues: any) => {
    //Quitamos nulos
    for (var k in formValues) if (!formValues[k]) delete formValues[k];

    var filteredData: any = {
      ...formValues,
      categorias: Object.keys(formValues).filter((key: any) => !isNaN(key))
    };

    diasDeLaSemana.forEach(dia => {
      var checkedValue = checkedValues.find(value => value === dia.name);
      if (checkedValue) {
        filteredData[dia.name] = true;
      }
    })
    return filteredData;
  };

  const validarDias = (checkedValues: any) => {
    diasDeLaSemana.forEach((dia: any) => {
      let diaExist = checkedValues.find((value: any) => value === dia.value);
      if (diaExist) {
        form.setFieldValue(dia.name, true);
      } else {
        form.setFieldValue(dia.name, false);
      }
    });
    form.setFieldValue('dias', checkedValues);
    setCheckedValues(checkedValues);
  };

  return (
    <Form
      form={form}
      autoComplete="off"
      name={name}
      initialValues={oportunidad}
      onFinish={(values: any) => {
        const mappedValues = getFormValues(values)
        onFinishForm(mappedValues);
      }}
    >
      {props.modo === "edicion" ? (
        <Form.Item
          label="id"
          name="id"
          className="idField"
          style={{ display: "none" }}
        >
          <Input placeholder="id" />
        </Form.Item>
      ) : (
        ""
      )}

      <Form.Item
        name="titulo"
        label="Título"
        className="obligatorio"
        rules={[
          {
            required: true,
            message: "Por favor, ingresar el título de la oportunidad",
            whitespace: true,
          },
          { type: "string" },
          { max: 50, message: "El titulo no debe exceder los 50 caracteres" },
        ]}
      >
        <Input placeholder="Ingresar el título del voluntariado" />
      </Form.Item>
      <Form.Item
        name="descripcion"
        label="Descripción"
        className="obligatorio"
        rules={[
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.reject("La descripción es obligatoria");
              }
              if (value.length > 500) {
                return Promise.reject("La descripción no debe exceder los 500 caracteres");
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="sede"
        label="Sede"
        className="obligatorio"
        rules={[
          {
            required: true,
            message: "Por favor, seleccionar una opción",
          },
        ]}
      >
        <Select placeholder="Ingresar Sede">
          {sedes.map((sede: any, index: number) => (
            <Select.Option
              value={`${index}`}
              key={`${index} ${sede.direccion}`}
            >
              {sede.direccion}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="dias"
        label="Días"
        className="obligatorio"
        rules={[
          {
            required: true,
            message: "Por favor, seleccionar al menos una opción",
          }
        ]}
      >
        <Checkbox.Group
          onChange={validarDias}
          options={diasDeLaSemana.map((dia: any) => {
            return { label: dia.value, value: dia.name }
          })}
        />
      </Form.Item>
      <Form.Item
        name="horario"
        label="Horario"
        className="obligatorio"
        rules={[
          {
            required: true,
            message: "Por favor, seleccionar al menos una opción",
          },
        ]}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          showSearch={false}
          notFoundContent="Por favor, seleccionar un rango horario."
          placeholder="Seleccionar un rango horario"
        >
          <Option key="turnos_maniana">Mañana</Option>
          <Option key="turnos_tarde">Tarde</Option>
          <Option key="turnos_noche">Noche</Option>
        </Select>
      </Form.Item>
      <div>Categorías</div>
      <Collapse accordion>
        {categories.map((categoria: any) => (
          <Panel
            header={categoria.nombre}
            key={categoria.id}
            forceRender={true}
          >
            {categoria.subcategorias.map((subCategoria: any) => (
              <Form.Item
                key={subCategoria.id}
                name={subCategoria.id}
                valuePropName="checked"
              >
                <Checkbox>{subCategoria.nombre}</Checkbox>
              </Form.Item>
            ))}
          </Panel>
        ))}
      </Collapse>
    </Form>
  );
};

export default OportunidadForm;
