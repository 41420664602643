import React, { FC, useState, MouseEvent } from "react";
import "./donaciones.css";
import Layout from "./components/UI/Layout";
import AgregarModal from "./components/UI/modals/AgregarModal";
import { Col, List, Radio, RadioChangeEvent } from 'antd';
import 'antd/dist/antd.css';
import useSWR from "swr";
import { useForm } from "../../../../../hooks/useFrom";
import { Categoria } from "./types/Categoria";
import { Subcategoria } from "./types/Subcategoria";
import EditarModal from "./components/UI/modals/EditarModal";
import EliminarModal from "./components/UI/modals/EliminarModal";
import OperacionesButtonGroup from "./components/UI/buttons/OperacionesButtonGroup";
import fetcher from "../../../../../helpers/fetcher";
import useModal from "../../../../../hooks/useModal";

const Donaciones: FC = () => {
  const fallbackData = {
    fallbackData: [],
  };
  const { data, isLoading, mutate } = useSWR<Categoria[]>("/api2/donations/all", fetcher, fallbackData);
  const [selectedCategoria, setSelectedCategoria] = useState<Categoria | null>(null);
  const [selectedSubcategoria, setSelectedSubcategoria] = useState<Subcategoria | null>(null);

  const [addModalVisible, openAddModal, handleAddModalClose] = useModal();
  const [editModalVisible, openEditModal, handleEditModalClose] = useModal();
  const [deleteModalVisible, openDeleteModal, handleDeleteModalClose] = useModal();

  const [addSubcategoryModalVisible, openSubcategoryAddModal, handleSubcategoryAddModalClose] = useModal();
  const [editSubcategoryModalVisible, openSubcategoryEditModal, handleSubcategoryEditModalClose] = useModal();
  const [deleteSubcategoryModalVisible, openSubcategoryDeleteModal, handleSubcategoryDeleteModalClose] = useModal();

  const { formState, onInputChange, onResetForm } = useForm({});
  const anCategoryIsSelected: boolean = selectedCategoria !== null;
  const anSubcategoryIsSelected: boolean = selectedSubcategoria !== null;

  //Categories
  const handleAddModalOpen = () => {
    onResetForm();
    openAddModal();
  }

  const handleEditModalOpen = () => {
    onResetForm();
    openEditModal();
  }

  const handleDeleteModalOpen = () => {
    onResetForm();
    openDeleteModal();
  }

  //subcategories
  const handleSubcategoryAddModalOpen = () => {
    onResetForm();
    openSubcategoryAddModal();
  }
  const handleSubcategoryEditModalOpen = () => {
    onResetForm();
    openSubcategoryEditModal();
  }
  const handleSubcategoryDeleteModalOpen = () => {
    onResetForm();
    openSubcategoryDeleteModal();
  }

  const onCategoriaChange = (event: RadioChangeEvent) => {
    setSelectedCategoria(event.target.value);
    setSelectedSubcategoria(null);
  };

  const onSubcategoriaChange = (event: RadioChangeEvent) => {
    setSelectedSubcategoria(event.target.value);
  };

  const CategoriaItem = ({ id, nombre }: Categoria) => (
    <List.Item key={id}>
      <Radio
        value={{ id, nombre, }}
        checked={selectedCategoria?.id === id}
        onChange={onCategoriaChange}
      >
        {nombre}
      </Radio>
    </List.Item>
  );

  const SubcategoriaItem = ({ id, nombre, categoriaId }: Subcategoria) => (
    <List.Item key={id}>
      <Radio
        value={{ id, nombre, categoriaId }}
        checked={selectedSubcategoria?.id === id}
        onChange={onSubcategoriaChange}
      >
        {nombre}
      </Radio>
    </List.Item>
  );

  //Categories
  const handleAdd = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const body = {
      "nombre": formState?.nombre
    }

    try {
      const datum = await fetcher('/api2/donations/category', "POST", body);
      mutate([...data!, datum]);
      handleAddModalClose();
    } catch {
      console.log("error")
    }
  }

  const handleEdit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const id = selectedCategoria?.id || -1;
    const body = {
      id,
      nombre: formState?.nombre,
    }
    try {
      const datum = await fetcher('/api2/donations/category', "PUT", body);
      mutate([...data!, { ...body, subcategorias: [] }]);
      handleEditModalClose();
    } catch {
      console.log("error")
    }
  }


  const handleDelete = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const id = selectedCategoria?.id || -1;
    const body = {
      id
    }
    try {
      const datum = await fetcher('/api2/donations/category', "DELETE", body);
      mutate([...data!, datum]);
      handleDeleteModalClose();

    } catch {
      console.log("error")
    }

  }

  //subcategories
  const handleAddSubcategory = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const body = {
      "nombre": formState?.nombre,
      "categoriaId": selectedCategoria?.id
    }

    try {
      const datum = await fetcher('/api2/donations/subcategory', "POST", body);
      mutate();
      handleSubcategoryAddModalClose();
    } catch {
      console.log("error")
    }
  }

  const handleEditSubcategory = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const id = selectedSubcategoria?.id || -1;
    const body = {
      id,
      nombre: formState?.nombre,
    }
    try {
      const datum = await fetcher('/api2/donations/subcategory', "PUT", body);
      mutate();
      handleSubcategoryEditModalClose();
    } catch {
      console.log("error")
    }
  }


  const handleDeleteSubcategory = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const id = selectedSubcategoria?.id || -1;
    const body = {
      id
    }
    try {
      const datum = await fetcher('/api2/donations/subcategory', "DELETE", body);
      mutate();
      handleSubcategoryDeleteModalClose();

    } catch {
      console.log("error")
    }

  }

  return (
    <div>
      <div id="backoffice-donaciones">
        <h1>Listado de ONG's</h1>
        <div className="cols-container">
          <div className="col" >
            <OperacionesButtonGroup
              handleAdd={handleAddModalOpen}
              addEnabled
              handleEdit={handleEditModalOpen}
              editEnabled={anCategoryIsSelected}
              handleDelete={handleDeleteModalOpen}
              deleteEnabled={anCategoryIsSelected}
            />
            <List
              header={<span>Categorías</span>}
              locale={{ emptyText: 'No se encontraron resultados', }}
              bordered
              dataSource={data}
              renderItem={CategoriaItem}
            />
          </div>
          <div className="col">
            <OperacionesButtonGroup
              handleAdd={handleSubcategoryAddModalOpen}
              addEnabled={anCategoryIsSelected}
              handleEdit={handleSubcategoryEditModalOpen}
              editEnabled={anSubcategoryIsSelected}
              handleDelete={handleSubcategoryDeleteModalOpen}
              deleteEnabled={anSubcategoryIsSelected}
            />
            <List
              header={<span>Subcategorías</span>}
              locale={{ emptyText: 'No se encontraron resultados' }}
              bordered
              dataSource={selectedCategoria ? data?.find(c => c.id === selectedCategoria?.id)?.subcategorias : []}
              renderItem={SubcategoriaItem}
            />
          </div>
        </div>
      </div>
      {/*Modales de Categorias */}
      <AgregarModal
        title={"Agregar Categorias"}
        addModalVisible={addModalVisible}
        handleAddModalClose={handleAddModalClose}
        handleAdd={handleAdd}
        nombre={formState?.nombre}
        onInputChange={onInputChange}
      />
      <EditarModal
        title={"Editar Categoria"}
        editModalVisible={editModalVisible}
        handleEditModalClose={handleEditModalClose}
        handleEdit={handleEdit}
        selectedCategoria={selectedCategoria!}
        nombre={formState.nombre}
        onInputChange={onInputChange}
      />
      <EliminarModal
        title={"Eliminar la Categoría"}
        deleteModalVisible={deleteModalVisible}
        handleDeleteModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
      {/*Modales de Subcategorias */}
      <AgregarModal
        title={"Agregar Subcategoria"}
        addModalVisible={addSubcategoryModalVisible}
        handleAddModalClose={handleSubcategoryAddModalClose}
        handleAdd={handleAddSubcategory}
        nombre={formState?.nombre}
        onInputChange={onInputChange}
      />
      <EditarModal
        title={"Editar Subcategoria"}
        editModalVisible={editSubcategoryModalVisible}
        handleEditModalClose={handleSubcategoryEditModalClose}
        handleEdit={handleEditSubcategory}
        selectedCategoria={selectedSubcategoria!}
        nombre={formState.nombre}
        onInputChange={onInputChange}
      />
      <EliminarModal
        title={"Eliminar la Subcategoría"}
        deleteModalVisible={deleteSubcategoryModalVisible}
        handleDeleteModalClose={handleDeleteModalClose}
        handleDelete={handleDeleteSubcategory}
      />
    </div>
  );
};

export default Donaciones;

