import React from "react"
import { Button, Space } from "antd"
import { OperacionesButtonGroupProps } from "../../../types/OperacionesButtonGroup"

const OperacionesButtonGroup = (
    { handleAdd,
        handleEdit,
        handleDelete,
        addEnabled = false,
        editEnabled = false,
        deleteEnabled = false
    }: OperacionesButtonGroupProps) => (
    <>
        <Space wrap style={{ padding: "10px 0" }}>
            <Button
                type="primary"
                onClick={handleAdd}
                disabled={!addEnabled}
            >
                Agregar
            </Button>
            <Button
                type="default"
                onClick={handleEdit}
                disabled={!editEnabled}
            >
                Editar
            </Button>
            <Button
                type="primary"
                onClick={handleDelete}
                disabled={!deleteEnabled}
                danger
            >
                Eliminar
            </Button>
        </Space>
    </>
)
export default OperacionesButtonGroup;