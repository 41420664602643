import React from "react";
import { updateStatistics } from "../../../utils/services/StatisticsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Button } from "antd";

interface Contacto {
  phone?: string;
  whatsapp?: string,
  email: string;
  ongId: number;
}

const messageWhatsApp: string = "Hola, te quiero contactar!";
const subject: string = "Involucrate: Quiero contactarte";
const msg: string = "";

function MetodosContacto(props: Contacto) {
  const { email, phone, whatsapp, ongId } = props;
  const hrefWhatsapp: string = `https://api.whatsapp.com/send/?phone=${whatsapp}&text=${messageWhatsApp}&app_absent=0`;
  const hrefMailTo: string = `mailto:${email}?subject=${subject}&body=${msg}%20goes%20here`;
  const hrefPhone: string = `tel:${phone}`;

  return (
    <div className="metodos-contacto">
      <div className="subtitulo">Coordinar entrega</div>
      <div className="buttons-container">
        {whatsapp && (
          <Button
            className="ant-btn ant-btn-primary btn-contacto btn-whatsapp"
            onClick={() => {
              updateStatistics(ongId, "whatsapp", "donacion");
            }}
            href={hrefWhatsapp}
          >
            <FontAwesomeIcon icon={faWhatsapp} />
            <span>{whatsapp}</span>
          </Button>
        )}
        {email && (
          <Button
            className="ant-btn ant-btn-primary btn-contacto btn-email"
            onClick={() => {
              updateStatistics(ongId, "email", "donacion");
            }}
            href={hrefMailTo}
          >
            <FontAwesomeIcon icon={faEnvelope} />
            <span>{email}</span>
          </Button>
        )}
        {phone && (
          <Button
            className="ant-btn ant-btn-primary btn-contacto btn-phone"
            onClick={() => {
              updateStatistics(ongId, "telefono", "donacion");
            }}
            href={hrefPhone}
          >
            <FontAwesomeIcon icon={faPhone} />
            <span>{phone}</span>
          </Button>
        )}
      </div>
    </div>
  );
}
export default MetodosContacto;
