import { Button } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Evento } from "../../../utils/entities/Evento";

const Title = (props: any) => {
  const evento: Evento = props.evento;
  const navigate_to_map = props.navigate_to_map;

  return (
    <div className="titulo-container">
      <Button
        className="ant-btn ant-btn-primary regresar-mapa"
        onClick={navigate_to_map}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <div className="titulo">{evento.titulo}</div>
    </div>
  );
};

export default Title;
