import React from "react";
import "./index.css";
import OngCreacion from "../../../../components/ongs/ongCreacion";
import "antd/dist/antd.css";

const Index = () => {
  return (
    <div id="ongCreator">
      <div className="bar" />
      <OngCreacion />
    </div>
  );
};

export default Index;
