import { Button } from "antd";
import React from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import news1 from "../../../images/new-home/news1.jpg";
import news2 from "../../../images/new-home/news2.jpg";
import news3 from "../../../images/new-home/news3.jpg";
import NewsCard from "./components/NewsCard";
import { NewsCardI } from "./models";

SwiperCore.use([Navigation, Pagination]);

const Noticias = () => {
  const noticias: NewsCardI[] = [
    {
      title: "Climate Save: el planeta nos necesita",
      date: "22 de enero de 2020",
      link:
        "https://news.involucrate.uy/2020/01/22/climate-save-planeta-en-emergencia/",
      description:
        "En 2015 surge en Canadá el movimiento canadiense Toronto Pig Save fundado por Anita Krajnc, que realiza vigilias a mataderos de chanchos. De este último, con el paso del tiempo, surgieron ramificaciones que se han expandido apuntando a diversas temáticas de forma especializada, como ser: Animal Save, Bee Save y el propio Climate Save.",
      image: news1,
    },
    {
      title: "Un día para involucrarte",
      date: "17 diciembre de 2019",
      link: "https://news.involucrate.uy/2019/12/17/un-dia-para-involucrarte/",
      description:
        "El pasado 7 de diciembre de 2019, se realizó el primer “Un día para involucrarte”, un evento que convocó a más de 40 organizaciones y movimientos sociales/ambientales de Uruguay para realizar acciones, talleres y capacitaciones gratuitas con el fin de integrar nuevos/as voluntarios/as a su causa.",
      image: news2,
    },
    {
      title: "A rodar la vida",
      date: "14 octubre de 2019",
      link: "https://news.involucrate.uy/2019/10/14/a-rodar-la-vida/",
      description:
        "En esta oportunidad nos involucramos con Graciela Reiris, integrante de APRI hace más de 20 años que se define como una colaboradora de la institución y nos cuenta que siempre trabajó vinculada con la temática discapacidad.",
      image: news3,
    },
  ];

  return (
    <div id="h-novedades">
      <div className="title">Novedades</div>
      <Swiper className="swiper1" pagination slidesPerView={1}>
        {noticias.map((noticia: NewsCardI, i: number) => (
          <SwiperSlide key={`slide-${i}`}>
            <NewsCard card={noticia} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper className="swiper2" pagination slidesPerView={2}>
        {noticias.map((noticia: NewsCardI, i: number) => (
          <SwiperSlide key={`slide-${i}`}>
            <NewsCard card={noticia} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper3">
        {noticias.map((noticia: NewsCardI, i: number) => (
          <NewsCard key={`card-${i}`} card={noticia} />
        ))}
      </div>
      <Button type="primary" ghost>
        <a href="https://news.involucrate.uy/" target="_blank" rel="noreferrer">
          Ver más novedades
        </a>
      </Button>
    </div>
  );
};

export default Noticias;
