import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import 'antd/dist/antd.css';
import '@glidejs/glide/dist/css/glide.core.css';
import '../../../utils/components/Slider/glide_theme.css';

interface IGaleriaVoluntariado {
    fotos: string[]
}

function GaleriaVoluntariado(props: IGaleriaVoluntariado):any {
    const listaFotos: string[] = props.fotos;
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isGlideAvailable, setisGlideAvailable] = useState<boolean>(false);
    const [currentImgElement, setcurrentImgElement] = useState<string>("");

    useEffect( () => {
        setisGlideAvailable(listaFotos.length>3);

        if(isGlideAvailable) {
        const glide = new Glide('.glide', {
            type: 'carousel',
            gap: 10,
            perView: 3,
            breakpoints: {
                768: {perView: 2},
                480: {perView: 1}
            }
        });
        
        glide.mount({Controls, Breakpoints});
        }
    }, [listaFotos, isGlideAvailable]);
    
    const showModal = (img: string): void => {
        setcurrentImgElement(img);
        setIsModalVisible(true);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return(
        <div className={`event-galeria glide-${isGlideAvailable}`}>
        {isGlideAvailable ? (
            <div className="glide">
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                    {listaFotos.map((element, index) => (
                        <li className="glide__slide" onClick={() => showModal(element)} key={index}>
                            <div className="event-galeria-img-container">
                            <img src={element} alt="imagen galeria" />
                            </div>
                        </li>
                    ))}
                    </ul>
                    <ul className="glide__bullets" data-glide-el="controls[nav]">
                        {listaFotos.map((el, index) => (
                            <li key={"li-"+index}>
                                <button className="glide__bullet" data-glide-dir={"="+index}></button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                    <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            </div>
        ) : (
            listaFotos.map((element, index) => (
                <div className="event-galeria-img-container-no-slider" onClick={() => showModal(element)} key={index}>
                    <div className="">
                        <img src={element} alt="imagen galeria" />
                    </div>
                </div>
            ))
        )}
        <Modal visible={isModalVisible} footer={null} maskClosable={true} onCancel={handleCancel} wrapClassName="event-galeria-modal-wrapper">
            <div className="event-galeria-modal">
                <img src={currentImgElement} alt="imagen galeria" />
            </div>
        </Modal>
        </div>
    )
}

export default GaleriaVoluntariado;


