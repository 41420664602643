import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { User } from '../../administradores/types/User';
import fetcher from '../../../../../../helpers/fetcher';

interface ChangePasswordFormProps {
    user: User; 
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ user }) => {
    const [form] = useForm();

    const handleReset = async (user: User) => { };

    const onFinish = async (values: { password: string }) => {
        try {
            const datum = await fetcher(`/api2/backoffice/`, 'PUT', values);
            message.success('Contraseña cambiada exitosamente');
            form.resetFields();
        } catch (error) {
            message.error('Error al cambiar la contraseña');
        }
    };

    // Validador personalizado para confirmar la contraseña
    const confirmPasswordValidator = (_:unknown, value: string) => {
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('Las contraseñas no coinciden'));
        }
        return Promise.resolve();
    };

    return (
        <div
            style={{
                paddingBlock:30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',     
            }}
        >
            <div style={{ width: '400px' }}>
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name="password"
                        label="Nueva Contraseña"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese la nueva contraseña',
                            },
                            {
                                min: 6,
                                message: 'La contraseña debe tener al menos 6 caracteres',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirmPassword"
                        label="Confirmar Contraseña"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor confirme la contraseña',
                            },
                            {
                                validator: confirmPasswordValidator,
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Cambiar Contraseña
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default ChangePasswordForm;