import React from "react";
import img from "../../../images/new-home/testimonio.jpg";

const Testimonial = () => {
  return (
    <div id="h-testimonial">
      <div className="title">Testimonios</div>
      <div className="text">
        <p>
          Soy voluntaria en Redalco desde hace aproximadamente tres meses y mi
          experiencia en el tiempo que he estado allí ha sido sumamente
          enriquecedora. Partiendo de la base de que es admirable que un grupo
          de jóvenes se haya propuesto llevar adelante un proyecto como este, es
          también muy gratificante poder ver día a día cómo se cumple el
          objetivo de la Red, que crece constantemente. Es muy lindo ver el
          compromiso y la voluntad de todos los que forman parte de la
          Organización; ellos le dedican unas horas de su semana a otras
          personas y logran que el desperdicio de alimento sea un poquito menor
          del que hubiera habido si no estuvieran ellos. Mi testimonio no es más
          que de admiración y agradecimiento.
        </p>
      </div>
      <div className="icon">
        <img className="navbar-brand-img" src={img} alt="Involucrate Logo" />
      </div>
    </div>
  );
};

export default Testimonial;
