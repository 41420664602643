import React from 'react';
import { TitleRegister, SubTitleRegister, TextRegister } from '../../utils/constants';
import image from '../../../../images/Computer troubleshooting-pana 1.png';

const UnsuccessfulRegistroCompleto = () => {
  return(
    <div className="registro-fallido">
      <div className="title">
      {TitleRegister.text}
      </div>
      <img src={image} alt="error-registro"/>
      <div className="titulo">
        {SubTitleRegister.failed}
      </div>
      <div className="texto">
        {TextRegister.failed}
      </div>
    </div>
  )
}

export default UnsuccessfulRegistroCompleto;
