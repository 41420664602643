import { Category } from "../entities/Category";
import { arrayCategoryDtoToEntity } from "./CategoryMapper";
import { Marker } from "../entities/Marker";

export function markerDtoToEntity(entity: any): Marker {
  return {
    id: entity.id,
    city: entity.localidad,
    coords: {
      x: entity.coordenadas.coordinates[0],
      y: entity.coordenadas.coordinates[1],
    },
    description: entity.descripcionEvento,
    descriptionLocation: entity.descripcionUbicacion,
    name: entity.ongName,
    provincia: entity.provincia,
    number: entity.numero,
    street: entity.calle,
    categories: arrayCategoryDtoToEntity(entity.tipoCategoriaDonaciones),
    ongId: entity.ongId,
  };
}

export function arrayMarkerDtoToEntity(markers: any[]): Marker[] {
  let list: Marker[] = [];
  markers.forEach((marker) => {
    list.push(markerDtoToEntity(marker));
  });
  return list;
}
