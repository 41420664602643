import { Button, Modal } from "antd";
import React from "react";
import { EliminarModalProps } from "../../../types/EliminarModalProps";

const EliminarModal = ({ title = "Eliminar la categoría", deleteModalVisible, handleDeleteModalClose, handleDelete }: EliminarModalProps) => {
    return (
        <Modal
            visible={deleteModalVisible}
            title={title}
            onCancel={handleDeleteModalClose}
            footer={[
                <Button key="cancel" onClick={handleDeleteModalClose}>
                    Cancelar
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    danger
                    onClick={handleDelete}
                >
                    Eliminar
                </Button>,
            ]}
        >
            <p>¿Estás seguro de que deseas {title.toLowerCase()}?</p>
        </Modal>
    )
}

export default EliminarModal; 