import { Col, Space, Row, Button, Form } from 'antd';
import React, { useState, useEffect } from 'react'
import {Input, } from '../Input';
import MapWithAMarker from '../MapWithAMarker';
import { useSharedSWR } from '../../../contexts/SWRContext';
import fetcher from '../../../../../../../../helpers/fetcher';
import { useForm } from '../../../../../../../../hooks/useFrom';
import { LatLng } from "../../../../../../../../components/registroONG/components/sede/models";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from "react-places-autocomplete";


const CentralBusiness = () => {
    const { data, mutate } = useSharedSWR();
    const sedeCentral = data?.sedes?.at(0) || {};
    const {
        id,
        telefonoFijo,
        horarios,
        nombreResponsable,
        apellidoResponsable,
        celular, //tel responsable
        mail,
        ubicacion: {
            id: idUbicacion,
            descripcion,
            place_id,
            coordenadas: {
                type,
                coordinates: [lat, lng]
            },
            calle,
            numero,
            pisoDepto,
            localidad,
            provincia,
            codigoPostal,
        },
    } = sedeCentral
    const { formState, handleChangeNestedData:onInputChange, onInputChangeForId, onResetForm } = useForm(sedeCentral)
    const center = { lat, lng };
    const [markerPosition, setMarkerPosition] = useState(center);
    const [direccion, setDireccion] = useState(descripcion ?? "");
    const [form] = Form.useForm();

    const getCoordinates = (location: any) => {
        const lat = location.lat();
        const lng = location.lng();
        return { lat: lat, lng: lng };
    };

    const handleChange = (address: any) => {
        setDireccion(address);
      };
    
    const handleSelect = (address: any) => {
        geocodeByAddress(address)
          .then((results) => {
            const values = getFormValues(results[0]);
            setCoordenadas(values.coordenadas);
            setDireccion(address);
            form.setFieldsValue({ direccion: address });
            return getLatLng(results[0]);
          })
          .catch((error) => console.error("Error", error));
    };

    const getFormValues = (direccion: any) => {
        const coordenadas = getCoordinates(direccion.geometry.location);
        const place_id = direccion.place_id;
      
        return { coordenadas: coordenadas, place_id: place_id };
    };

    const defaultCoordenadas: LatLng = {
        lat: -34.8833,
        lng: -56.1667,
    };

    const options = {
        componentRestrictions: { country: ["uy"] },
    };

    const [coordenadas, setCoordenadas] = useState(
        typeof sedeCentral.coordenadas == "undefined"
            ? defaultCoordenadas
            : sedeCentral.coordenadas
    );

    useEffect( () => {
        form.setFields([{name: ['ubicacion','descripcion'],value: direccion}]);
    }, [direccion])

    const handleEditOng = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const { lat, lng }=markerPosition;
        const body = { id, ...formState};
        const descripcion = form.getFieldValue("direccion");
        const coordenadas={
            type,
            coordinates: [lat, lng]
        }
        body.ubicacion={
            ...body.ubicacion, 
            coordenadas,
            descripcion
        }
        
        try {
            const datum = await fetcher(
                `/api2/ong/bo/sedes/${id}`,
                "PATCH",
                body
            );
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Error: ${error.message}`)
            }
        }
    }
    return (
        <Form size="middle" className="boEditarSedePrincipal" form={form}>
                <Row justify='center'>
                    <Col span="24">
                    <PlacesAutocomplete
                                value={direccion}
                                onSelect={handleSelect}
                                onChange={handleChange}
                                searchOptions={options}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div
                                    style={{
                                    position: "relative",
                                    }}
                                >
                                    <Form.Item
                                    name="direccion"
                                    colon={false}
                                    hasFeedback
                                    label={
                                        <span>
                                        Dirección (Puedes buscar tu dirección en el siguiente campo)
                                        </span>
                                    }
                                    rules={[
                                        {
                                        validator(_, value) {
                                            if (direccion === "") {
                                            return Promise.reject("Por favor ingresar una dirección");
                                            } else {
                                            return Promise.resolve();
                                            }
                                        },
                                        },
                                    ]}
                                    >
                                    <Input
                                        {...getInputProps({
                                        placeholder: "Ingresar dirección...",
                                        })}
                                        defaultValue={direccion}
                                    />
                                    </Form.Item>
                                    <div
                                    className="autocomplete-dropdown-container"
                                    style={{
                                        position: "absolute",
                                        top: "100%",
                                        backgroundColor: "white",
                                        zIndex: 2,
                                        width: "100%",
                                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                    }}
                                    >
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index: number) => {
                                        const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                                        return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                            })}
                                            key={index}
                                        >
                                            <div>{suggestion.description}</div>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </div>
                                )}
                            </PlacesAutocomplete>
                    <Form.Item label="Informacion Adicional" colon={false}>
                        <Input
                            defaultValue={pisoDepto}
                            name='ubicacion.pisoDepto'
                            placeholder="Informacion Adicional"
                            onChange={onInputChange}
                        />
                    </Form.Item>
                    </Col>
                    {/** 
                    <Col>
                        <label htmlFor="calle">Calle</label>
                        <Input
                            defaultValue={calle}
                            name='ubicacion.calle'
                            placeholder="Calle"
                            onChange={onInputChange}
                        />
                    </Col>
                    */}
                    
                        <Space direction='horizontal' >
                            {/**<Space direction='vertical' align='baseline' size={0}>
                                <label htmlFor="numero">Número</label>
                                <Input
                                    defaultValue={numero}
                                    name='ubicacion.numero'
                                    placeholder="Número"
                                    onChange={onInputChange}
                                />
                            </Space>
                            
                            <Space direction='vertical' align='baseline' size="middle">
                                <Form.Item label="Informacion Adicional" colon={false}>
                                    <Input
                                        defaultValue={pisoDepto}
                                        name='ubicacion.pisoDepto'
                                        placeholder="Informacion Adicional"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Space>
                            
                            <Space direction='vertical' align='baseline' size={0}>
                                <label htmlFor='codigoPostal'>Código Postal</label>
                                <Input
                                    defaultValue={codigoPostal}
                                    name='ubicacion.codigoPostal'
                                    placeholder="Código Postal"
                                    onChange={onInputChange}
                                />
                            </Space>
                            */}
                        </Space>
                    
                    {/**
                    <Col>
                        <label htmlFor="localidad">Localidad</label>
                        <Input
                            defaultValue={localidad}
                            name='ubicacion.localidad'
                            placeholder="Localidad"
                            onChange={onInputChange}
                        />
                    </Col>
                    <Col >
                        <label htmlFor="provincia">Departamento</label>
                        <Input
                            defaultValue={provincia}
                            name='ubicacion.provincia'
                            placeholder="Departamento"
                            onChange={onInputChange}
                        />
                    </Col>
                     */}
                
                </Row>
                <Row>
                
                {/** 
                <Space direction='vertical'>
                    <Col >
                        <label htmlFor="telefonoFijo">Teléfono de la sede</label>
                        <Input
                            defaultValue={telefonoFijo}
                            name='telefonoFijo'
                            placeholder="Teléfono de la sede"
                            type="telephone"
                            onChange={onInputChange}
                        />
                    </Col>
                    <Col >
                        <label htmlFor="telefono">Horario</label>
                        <Input
                            defaultValue={horarios}
                            name='horarios'
                            placeholder="Horario"
                            onChange={onInputChange}
                        />
                    </Col>

                    <Col>
                        <Row>

                            <label htmlFor="responsable">Persona Responsable</label>
                        </Row>
                        <Row>
                            <Space direction='horizontal' align='baseline'>
                                <Input
                                    defaultValue={nombreResponsable}
                                    name='nombreResponsable'
                                    placeholder="Nombre"
                                    onChange={onInputChange}
                                />
                                <Input
                                    defaultValue={apellidoResponsable}
                                    name='apellidoResponsable'
                                    placeholder="Apellido"
                                    onChange={onInputChange}
                                />
                            </Space>
                        </Row>
                    </Col>
                    <Col >
                        <label htmlFor="telefono">Teléfono responsable</label>
                        <Input
                            defaultValue={celular}
                            name='celular'
                            placeholder=""
                            type="telephone"
                            onChange={onInputChange}
                        />
                    </Col>
                    <Col >
                        <label htmlFor="email">E-mail</label>
                        <Input
                            defaultValue={mail}
                            name='mail'
                            placeholder=""
                            type="email"
                            onChange={onInputChange}
                        />
                    </Col>
                </Space>
                */}
                
                    <Col span="24">
                        <Row justify="center">
                            <label>Ubicación en el mapa</label>
                            <MapWithAMarker
                                center={center}
                                setMarkerPosition={setMarkerPosition}
                                markerPosition={markerPosition}
                                style={{ width: '409px', height: '350px' }}
                            />
                        </Row>
                    </Col>
                
                </Row>
            
            <Space style={{ display: "flex", alignItems: "flex-end", alignSelf: "flex-end", justifyContent: "flex-end",marginTop:3 }}>
                <Button type="primary" onClick={handleEditOng}>Guardar</Button>
                <Button onClick={onResetForm}>Cancelar</Button>
            </Space>
        </Form>
    )
};

export default CentralBusiness;
