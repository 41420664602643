import { Button, Space, Spin } from "antd";
import React, { FC, ReactNode, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons'

type LayoutProps = {
    title: string;
    children: ReactNode;
    isLoading?: boolean;
}

const Layout: FC<LayoutProps> = ({ title, children, isLoading = false }: LayoutProps) => {
    const navigate = useNavigate();
    const handleBack = (e: SyntheticEvent) => {
        navigate('/backoffice/ongs/')
    }

    const Layout = ({ title, children }: LayoutProps) => {
        return (
            <div className="donaciones-container">
                <Space style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <h1 className="titulo">
                        {title}
                    </h1>
                    <Button
                        onClick={handleBack}
                        style={{ display: 'flex', alignItems: 'center' }}
                        icon={<ArrowLeftOutlined />}
                    >Volver
                    </Button>
                </Space>

                {children}
            </div>
        )
    }

    if (isLoading) {
        return (
            <Layout title={title}>
                <div className="spinner-container">
                    <Spin
                        className="spinner"
                        size="large"
                        tip="Cargando..."
                    />
                </div>
            </Layout>
        );
    }
    return (
        <Layout title={title}>
            {children}
        </Layout>
    );
}

export default Layout;