export const getPhoneNumberAndCountryPrefix = (fullNumber: string, option: number): string => {
  if (!fullNumber) {
    return "";
  }

  let stringEnd = 1;

  if (fullNumber.startsWith("+54")) { stringEnd = 3; }
  if (fullNumber.startsWith("+598")) { stringEnd = 4; }

  if (option === 0) {
    const startsWith = fullNumber.slice(0, 1) === "+" ? fullNumber.slice(0, stringEnd) : '';
    const countrySelected = countryOptions.find(option => option.startsWith === startsWith);
    return countrySelected ? countrySelected.country : 'Otros';
  } else if (option === 1) {
    return fullNumber.slice(0, 1) === "+" ? fullNumber.substring(stringEnd) : fullNumber;
  }
  return ""
}

export const addCountryPrefixNumber = (countryCode: string, number: string): string => {
  return (countryOptions.find(option => option.country === countryCode)?.startsWith || "") + number;
};

export const validatePhoneNumber = (phoneNumber: string, country: string): boolean => {
  const _phoneNumber = phoneNumber.replace(/[-_]/g, '');
  switch (country) {
    case 'UY': return /^[+]?\d{8}$/.test(_phoneNumber);
    case 'ARG': return /^[+]?\d{11}$/.test(_phoneNumber);
    default: return /^[+]?\d{10,15}$/.test(_phoneNumber);
  }
}

/*export const countryOptions = [
  { country: "ARG", // ex: "ARG"
    prefix: "+54", // ex: "+54"
    mask: '+54-9', // ex: '+54-9-0000000000'
    flag: "/AR.svg" // ex: "https://www.countryflags.io/ar/flat/64.png"
  },
  { country: "UY", // ex: "ARG"
    prefix: "+54", // ex: "+54"
    mask: '+598', // ex: '+54-9-0000000000'
    flag: "/UY.svg" // ex: "https://www.countryflags.io/ar/flat/64.png"
  },
  { country: "Otros", // ex: "ARG"
    prefix: '', // ex: "+54"
    mask: '', // ex: '+54-9-0000000000'
    flag: "/globo.png" // ex: "https://www.countryflags.io/ar/flat/64.png"
  },
];*/

export const countryOptions = [
  {
    mask: '+00-0-0000000000',
    startsWith: '+54',
    lazy: false,
    country: 'AR',
    maxLength: 11,
    flag: "/AR.svg"
  },
  {
    mask: '+000-0000-0000',
    startsWith: '+598',
    lazy: false,
    country: 'UY',
    maxLength: 8,
    flag: "/UY.svg"
  },
  {
    mask: '+000000000[0][0][0]',
    startsWith: '',
    lazy: false,
    country: 'Otros',
    maxLength: 15,
    flag: "/globo.png"
  }
];
