import { Space, Form, Button, Divider, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react'
import { Input } from '../Input';
import MapWithAMarker from '../MapWithAMarker';
import fetcher from '../../../../../../../../helpers/fetcher';
import { useForm } from '../../../../../../../../hooks/useFrom';
import { useSharedSWR } from '../../../contexts/SWRContext';
import { LatLng } from "../../../../../../../../components/registroONG/components/sede/models";
import
PlacesAutocomplete,
{
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";

const OtherBusinessForm = ({ otraSede, hideModal }: { otraSede: any, hideModal: () => void }) => {

    const { mutate } = useSharedSWR();

    const {
        id,
        telefonoFijo,
        horarios,
        nombreResponsable,
        apellidoResponsable,
        celular, //tel responsable
        mail,
        ubicacion: {
            id: idUbicacion,
            descripcion,
            place_id,
            coordenadas: {
                type,
                coordinates: [lat, lng]
            },
            calle,
            numero,
            pisoDepto,
            localidad,
            provincia,
            codigoPostal,
        },
    } = otraSede
    const { formState, handleChangeNestedData: onInputChange, onResetForm } = useForm(otraSede)
    const center = { lat, lng };
    const [markerPosition, setMarkerPosition] = useState(center);
    const [direccion, setDireccion] = useState(descripcion ?? "");
    const [form] = Form.useForm();

    const handleClose = () => {
        //onResetForm();
        hideModal();
    }

    const getFormValues = (direccion: any) => {
        const coordenadas = getCoordinates(direccion.geometry.location);
        const place_id = direccion.place_id;

        return { coordenadas: coordenadas, place_id: place_id };
    };

    const getCoordinates = (location: any) => {
        const lat = location.lat();
        const lng = location.lng();
        return { lat: lat, lng: lng };
    };

    const handleChange = (address: any) => {
        setDireccion(address);
    };

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then((results) => {
                const values = getFormValues(results[0]);
                setCoordenadas(values.coordenadas);
                setDireccion(address);
                form.setFieldsValue({ direccion: address });
                return getLatLng(results[0]);
            })
            .catch((error) => console.error("Error", error));
    };

    const defaultCoordenadas: LatLng = {
        lat: -34.8833,
        lng: -56.1667,
    };

    const options = {
        componentRestrictions: { country: ["uy"] },
    };

    const [coordenadas, setCoordenadas] = useState(
        typeof otraSede.coordenadas == "undefined"
            ? defaultCoordenadas
            : otraSede.coordenadas
    );

    useEffect(() => {
        form.setFields([{ name: ['ubicacion', 'descripcion'], value: direccion }]);
    }, [direccion])

    const handleEditOng = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const { lat, lng } = markerPosition;
        const body = { id, ...formState };
        //const {city: localidad, street: calle, number: numero, postal_code: codigoPostal, region: provincia} = form.getFieldsValue();
        const { pisoDepto, direccion: descripcion } = form.getFieldsValue(["pisoDepto", "direccion"]);

        const coordenadas = {
            type,
            coordinates: [lat, lng]
        }

        /*body.ubicacion = {
            ...body.ubicacion,
            calle,
            localidad, 
            numero, 
            codigoPostal, 
            provincia,
            coordenadas
        }*/

        body.ubicacion = {
            ...body.ubicacion,
            coordenadas,
            pisoDepto,
            descripcion
        }

        /*
        body.ubicacion.coordenadas = coordenadas;
        body.ubicacion.pisoDepto = pisoDepto;
        body.ubicacion.descripcion = direccion;
        */
        //console.log("🚀 ~ file: EditarONG.tsx:1010 ~ handleEditOng ~ body:", body)

        try {
            const datum = await fetcher(
                `/api2/ong/bo/sedes/${id}`,
                "PATCH",
                body
            );
            //alert(JSON.stringify(datum))

            mutate!()
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Error: ${error.message}`)
            }
        }
    }
    return (
        <>
            <Form className="form boEditarSede" form={form} preserve={false}>
                <Row className="form-row">
                    <Col className="form-col-70">
                        <Row className="form-row">
                            <Col className="form-col">
                                <PlacesAutocomplete
                                    value={direccion}
                                    onSelect={handleSelect}
                                    onChange={handleChange}
                                    searchOptions={options}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            <Form.Item
                                                name="direccion"
                                                colon={false}
                                                hasFeedback
                                                label={
                                                    <span>
                                                        Dirección (Puedes buscar tu dirección en el siguiente campo)
                                                    </span>
                                                }
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            if (direccion === "") {
                                                                return Promise.reject("Por favor ingresar una dirección");
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    {...getInputProps({
                                                        placeholder: "Ingresar dirección...",
                                                    })}
                                                    defaultValue={direccion}
                                                />
                                            </Form.Item>
                                            <div
                                                className="autocomplete-dropdown-container"
                                                style={{
                                                    position: "absolute",
                                                    top: "100%",
                                                    backgroundColor: "white",
                                                    zIndex: 2,
                                                    width: "100%",
                                                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                                }}
                                            >
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion, index: number) => {
                                                    const className = suggestion.active
                                                        ? "suggestion-item--active"
                                                        : "suggestion-item";
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                            key={index}
                                                        >
                                                            <div>{suggestion.description}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                <Form.Item label="Place_id" name="place_id" hidden={true}>
                                    <Input />
                                </Form.Item>
                                {/**<Form.Item label="Dirección" className="obligatorio" name="descripcion" colon={false} 
                                rules={[
                                    {
                                        validator(_, value) {
                                            console.log(_, value)
                                            if (direccion === "") {
                                            return Promise.reject("Por favor ingresar una dirección");
                                            } else {
                                            return Promise.resolve();
                                            }
                                        },
                                    },
                                ]}
                                hasFeedback
                                >
                                    <MapSearch 
                                        initialValue={descripcion}
                                        updateForm={(datos:any) => {
                                            Object.keys(datos).forEach((key:any) => {
                                                form.setFieldsValue({ [key]: datos[key] })
                                            });
                                            setMarkerPosition(datos.coordenadas);
                                        }}
                                        name='ubicacion.descripcion'
                                        onChange={onInputChange} 
                                        setDireccion = {setDireccion}
                                        direccion = {direccion} />
                                </Form.Item>
                             */}
                            </Col>
                            {/*
                            <Col className="form-col">
                                <Form.Item 
                                label="Calle" 
                                name="street" 
                                colon={false} 
                                className="obligatorio"
                                rules={[
                                    {
                                        required:true,
                                        message:"Por favor, ingresar la calle.",
                                        whitespace:true
                                    },
                                    { min: 1 , message: 'Debe ingresar un nombre para la calle.'},
                                    { max: 30, message: 'La calle no puede exceder los 30 caracteres.' },
                                    ]}
                                hasFeedback
                                >
                                    <Input
                                        defaultValue={calle}
                                        name='ubicacion.calle'
                                        placeholder="Calle"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            
                            <Col className="form-col">
                                <Form.Item 
                                label="Número" 
                                name="number" 
                                colon={false} 
                                className="obligatorio"
                                rules={[
                                    {
                                        required:true,
                                        type: "string",
                                        message:"Por favor, ingresar el número de vivienda.",
                                        whitespace:true
                                    },
                                    { min: 1 , message: 'Debe ingresar un numero para la vivienda.'},
                                    { max: 30, message: 'El número no puede exceder los 30 caracteres.' },
                                    ]}
                                    hasFeedback
                                >
                                    <Input
                                        defaultValue={numero}
                                        name='ubicacion.numero'
                                        placeholder="Número"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            */}
                        </Row>

                        <Row className="form-row">
                            <Col className="form-col">
                                <Form.Item label="Informacion Adicional" name="pisoDepto" colon={false}>
                                    <Input
                                        defaultValue={pisoDepto}
                                        name='ubicacion.pisoDepto'
                                        placeholder="Informacion Adicional"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>

                            {/**
                            <Col className="form-col">
                                <Form.Item 
                                label="Código Postal" 
                                name="postal_code" 
                                colon={false} 
                                className="obligatorio"
                                rules={[
                                    {
                                      required:true,
                                      message:"Por favor, ingresar código postal de la sede.",
                                      whitespace:true
                                    },
                                    { pattern:postalRE },
                                    { len:5, message : "El código postal de la sede debe tener 5 caracteres.",}
                                  ]}
                                  hasFeedback
                                  >
                                    <Input
                                        defaultValue={codigoPostal}
                                        name='ubicacion.codigoPostal'
                                        placeholder="Código Postal"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="form-col">
                                <Form.Item 
                                    label="Localidad" 
                                    name="city" 
                                    colon={false} 
                                    className="obligatorio"
                                    rules={[
                                        {
                                        required:true,
                                        message:"Por favor, ingresar la localidad.",
                                        whitespace:true
                                        },
                                        { min: 1 , message: 'Debe ingresar un nombre para la localidad.'},
                                        { max: 30, message: 'La localidad no puede exceder los 30 caracteres.' },
                                    ]}
                                    hasFeedback>
                                    <Input
                                        defaultValue={localidad}
                                        name='ubicacion.localidad'
                                        placeholder="Localidad"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                             */}
                        </Row>
                        {/**
                        <Row className="form-row">
                            <Col className="form-col">
                                <Form.Item 
                                label="Departamento" 
                                name="region" 
                                colon={false} 
                                className="obligatorio"
                                rules={[
                                    {
                                        required:true,
                                        message:"Por favor, ingresar el Departamento/Provincia.",
                                        whitespace:true
                                    },
                                    { min: 1 , message: 'Debe ingresar un nombre para el Departamento/Provincia.'},
                                    { max: 30, message: 'El Departamento/Provincia no puede exceder los 30 caracteres.' },
                                    ]}
                                hasFeedback>
                                    <Input
                                        defaultValue={provincia}
                                        name='ubicacion.provincia'
                                        placeholder="Departamento"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="form-col">
                                <Form.Item 
                                    label="Teléfono de la sede" 
                                    name="telefonoFijo" 
                                    colon={false} 
                                    className="obligatorio"
                                    rules={[
                                        {
                                            required:true,
                                            type: "string",
                                            message:"Por favor, ingresar telefono de la sede.",
                                            whitespace:true
                                        },
                                        { len: 8 , message: 'El numero de telefono debe tener 8 caracteres.'}
                                    ]}
                                    hasFeedback
                                    >
                                    <Input
                                        defaultValue={telefonoFijo}
                                        name='telefonoFijo'
                                        placeholder="Teléfono de la sede"
                                        type="telephone"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="form-col">
                                <Form.Item 
                                    label="Horario" 
                                    name="horarios" 
                                    colon={false} 
                                    className="obligatorio"
                                    rules={[
                                        {
                                            required:true,
                                            type: "string",
                                            message:"Por favor, ingresar el horario de la sede.",
                                            whitespace:true
                                        },
                                        { max: 30 , message: 'El horario no debe tener mas de 30 caracteres.'}
                                        ]}
                                        hasFeedback
                                    >
                                    <Input
                                        defaultValue={horarios}
                                        name='horarios'
                                        placeholder="Horario"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row> */}

                        <Row className="form-row">
                            {/**
                            <Col className="form-col">
                                <Form.Item 
                                label="Persona Responsable" 
                                name="nombreResponsable" 
                                colon={false} 
                                className="obligatorio"
                                rules={[
                                    {
                                        required:true,
                                        type: "string",
                                        message:"Por favor, ingresar el nombre de la persona responsable de la sede.",
                                        whitespace:true
                                    },
                                    { max: 30 , message: 'El nombre no debe superar los 30 caracteres.'}
                                ]}
                                hasFeedback
                                >
                                    <Input
                                        defaultValue={nombreResponsable}
                                        name='nombreResponsable'
                                        placeholder="Nombre"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="form-col">
                                <Form.Item 
                                label="Apellido Responsable" 
                                name="apellidoResponsable" 
                                colon={false} 
                                className="obligatorio"
                                rules={[
                                    {
                                        required:true,
                                        type: "string",
                                        message:"Por favor, ingresar el apellido de la persona responsable de la sede.",
                                        whitespace:true
                                    },
                                    { max: 30 , message: 'El apellido no debe superar los 30 caracteres.'}
                                ]}
                                hasFeedback
                                >
                                    <Input
                                        defaultValue={apellidoResponsable}
                                        name='apellidoResponsable'
                                        placeholder="Apellido"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="form-col">
                                <Form.Item 
                                name="celular" 
                                colon={false} 
                                className="obligatorio"
                                label="Celular de la persona responsable" 
                                rules={[
                                    {
                                        required:true,
                                        type: "string",
                                        message:"Por favor, ingresar telefono celular de la persona responsable de la sede.",
                                        whitespace:true
                                    },
                                    { len: 9 , message: 'El número debe tener minimo 9 caracteres.'}
                                ]}
                                hasFeedback
                                >
                                    <Input
                                        defaultValue={celular}
                                        name='celular'
                                        placeholder="Telefono"
                                        type="telephone"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="form-col">
                                <Form.Item 
                                label="E-mail" 
                                name="mail" 
                                colon={false} 
                                className="obligatorio"
                                rules={[
                                    { 
                                        required: true, 
                                        type: 'email',
                                        message: 'El correo es obligatorio.'
                                    }
                                ]}
                                hasFeedback
                                >
                                    <Input
                                        defaultValue={mail}
                                        name='mail'
                                        placeholder=""
                                        type="email"
                                        onChange={onInputChange}
                                    />
                                </Form.Item>
                            </Col>
                            */}
                            <Col className="form-col">
                                <div className="map-container">
                                    <Form.Item label="Ubicación en el mapa" colon={false}>
                                        <MapWithAMarker
                                            center={center}
                                            setMarkerPosition={setMarkerPosition}
                                            markerPosition={markerPosition}
                                            style={{ width: '100%', height: '352px' }}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    {/**<Col className="form-col-30"></Col> */}
                </Row>
            </Form>
            <Divider />
            <Space style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <Button
                    type="primary"
                    onClick={handleEditOng}
                >
                    Guardar
                </Button>
                <Button
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
            </Space>
        </>

    )
};

export default OtherBusinessForm;

