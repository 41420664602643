import { useState, useEffect } from "react";
import { getCategories } from "../../../utils/constants";
import ajaxAction, { Config } from "../../../utils/ajaxAction";
import { arrayCategoryDtoToEntity } from "../../../utils/mappers/CategoryMapper";
import { Category } from "../../../utils/entities/Category";
import _ from "lodash";

var config: Config = {
  service: getCategories,
  method: "GET",
};

const useCategories = () => {
  const [categories, updateCategories] = useState([] as Category[]);

  useEffect(() => {
    ajaxAction(config)
      .then(async (response) => {
        const data = await response.json();
        const categorias = _.sortBy(
          arrayCategoryDtoToEntity(data[0].categorias),
          "name"
        );
        updateCategories(categorias);
      })
      .catch((err) => console.log(err));
  }, []);

  return categories;
};

export default useCategories;
