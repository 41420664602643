import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import navBar from "../../images/new-home/NavBar.png";
import navBarToggler from "../../images/new-home/NavBarToggler.png";

function Header() {
  const items = [
    { id: 1, name: "Donar", link: "/donar" },
    { id: 2, name: "Voluntariado", link: "/voluntariado" },
    { id: 3, name: "Mi ONG", link: "/donar" },
    { id: 4, name: "Contacto", link: "/contact" },
    { id: 5, name: "FAQ", link: "/faq" },
    { id: 6, name: "Entrar", link: "/mapa/login" },
  ];

  const navbarItems = items.map((item) => {
    return (
      <li className="nav-item" key={item.id}>
        <Link to={item.link} className="nav-link">
          {item.name}
        </Link>
      </li>
    );
  });

  return (
    <Fragment>
      <div className="barra" />
      <div id="h-navbar">
        <nav className="navbar navbar-expand-md navbar-light">
          <a className="logo" href="/">
            <img src={navBar} />
          </a>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarHome"
          >
            <img src={navBarToggler} />
          </button>
          <div className="collapse navbar-collapse" id="navbarHome">
            <ul className="navbar-nav">{navbarItems}</ul>
          </div>
          <Button className="ingresar" type="primary">
            <Link to="/mapa/login" target="_self">
              Ingresar
            </Link>
          </Button>
        </nav>
      </div>
    </Fragment>
  );
}

export default Header;
