import L from 'leaflet';
import React, { useEffect, useRef } from "react";
import { customIcon } from '../../../../../utils/const/iconConfig';
import { createTileLayer } from '../../../../../utils/const/mapUtils';

import 'leaflet/dist/leaflet.css';

interface MapProps {
  coordenadas: { lat: number; lng: number };
  setCoordenadas: (coords: { lat: number; lng: number }) => void;
}

const Map = ({ coordenadas, setCoordenadas }: MapProps) => {
  const mapRef = useRef<L.Map | null>(null);
  const markerRef = useRef<L.Marker | null>(null);

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map('map', {
        center: coordenadas,
        zoom: 12,
        minZoom: 8,
        maxZoom: 15
      });

      mapRef.current.attributionControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');
      createTileLayer().addTo(mapRef.current);

      markerRef.current = L.marker(coordenadas, {
        icon: customIcon,
        draggable: true
      }).addTo(mapRef.current);

      markerRef.current.on('dragend', (e: L.DragEndEvent) => {
        const position = e.target.getLatLng();
        setCoordenadas(position);
      });

    } else {
      mapRef.current.setView(coordenadas, mapRef.current.getZoom());
    }
  }, [coordenadas, setCoordenadas]);

  return <div id="map" style={{ height: "400px", width: "100%" }}></div>;
};

export default Map;
