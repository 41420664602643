
import React from 'react';
import { Ong } from '../../../utils/entities/Ong';

import { Carousel } from 'antd';

interface FotosProps {
    ong: Ong
}

function Fotos(props:FotosProps) {
  const { ong } = props;
  
  const gallery = ong.galeria;
  const foto = ong.photo;

  return (
      <div className='fotos'>
        <div className="subtitulo">Fotos</div>
        <div className="fotos-container">
            <div className='image-container'>
                <img className='image' src={foto} />
            </div>
            {(gallery !== undefined && gallery?.length > 0) &&
            <Carousel autoplay className='carousel-div'>
                {gallery.map((src, index) => {
                return(
                    <div key={index}>
                        <div className='carousel-slide' style={{"backgroundImage":`url(${src})`}}></div>
                    </div>
                )
                })}
            </Carousel>
            }
        </div>
      </div>
  );

}

export default Fotos;
