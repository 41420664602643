import React from 'react';
import { Col, Space, Row, Alert, Button, Popover } from 'antd';
import { useSharedSWR } from '../../../contexts/SWRContext';
import fetcher from '../../../../../../../../helpers/fetcher';
import { formatDate } from '../../../../../../../../helpers/formatDate';

const StatusAndHistoryAccount = () => {
    const { data, mutate } = useSharedSWR()
    const {
        id,
        status,
        fuente,
        createdAt,
        updatedAt,
    } = data || {};
    const handleEditOng = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const body = { status: (status === "Habilitada" ? "Deshabilitada" : "Habilitada") };
        try {
            const datum = await fetcher(
                `/api2/ong/bo/info/${id}`,
                "PATCH",
                body
            );
            if (mutate) {
                mutate(datum);
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Error: ${error.message}`)
            }
        }
    }

    const content = (
        <div>
            <p>Creada: {formatDate(createdAt!)}HS</p>
            <p>Actualizada: {formatDate(updatedAt!)}HS</p>
        </div>
    );

    const statusType = status === "Pendiente" ?
        "info"
        : (status === "Habilitada" ? "success" : "error");
    return (
        <Row align="middle"  >
            <Space align="baseline" size="large" wrap>
                <Row>
                    <label htmlFor="name">Fuente</label>
                    <h6>{fuente ?? "desconocida"}</h6>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <label htmlFor="name">Estado</label>
                            <Alert
                                message={status}
                                type={statusType}
                                showIcon
                            />
                        </Row>
                        <Row>
                            <Space
                                align='baseline'
                                direction='horizontal'
                                style={{ margin: 5, padding: 10, alignItems: 'center' }}
                            >
                                <Button
                                    type='primary'
                                    style={{ background: status === "Habilitada" ? "#dc3545" : "#28a745" }}
                                    onClick={handleEditOng}>
                                    {
                                        status !== "Habilitada" ?
                                            "Habilitar"
                                            : "Deshabilitar"
                                    }
                                </Button>
                                <Popover 
                                    content={content}
                                    placement="bottom" 
                                    title="Historial de Estados" 
                                    trigger="click"
                                >
                                    <Button>
                                        Historial de Estados
                                    </Button>
                                </Popover>
                            </Space>
                        </Row>
                    </Col>
                </Row>

            </Space>
        </Row>
    )
}
export default StatusAndHistoryAccount;