import { Subcategorie } from "../entities/Subcategorie";

export function subcategorieDtoToEntity(sub: any): Subcategorie {
  return {
    id: sub.id,
    name: sub.nombre,
  };
}

export function arraySubcategorieDtoToEntity(subs: any[]): Subcategorie[] {
  let res: Subcategorie[] = [];
  if (subs) {
    subs.forEach((element) => {
      let subs: Subcategorie = subcategorieDtoToEntity(element);
      res.unshift(subs);
    });
  }
  return res;
}
