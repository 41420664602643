import { useState, useEffect, useCallback } from "react";
import { Marker } from "../../../utils/entities/Marker";
import { getMarkers } from "../../../utils/constants";
import ajaxAction, { Config } from "../../../utils/ajaxAction";
import { arrayMarkerDtoToEntity } from "../../../utils/mappers/MarkerMapper";

var configMarkers: Config = {
  service: getMarkers,
  method: "GET",
};

const useMarkers = (selected: string[]) => {
  const [filteredMarkers, updateFilteredMarkers] = useState([] as Marker[]);

  const isSubcategorieSelected = useCallback(
    (marker: Marker) => {
      for (let i = 0; i < marker.categories.length; i++) {
        const categorie = marker.categories[i];
        for (
          let j = 0;
          categorie.subcategories !== undefined &&
          j < categorie.subcategories.length;
          j++
        ) {
          const subCategorie = categorie.subcategories[j];
          for (let x = 0; x < selected.length; x++) {
            const element = selected[x];
            if (element === subCategorie.id.toString()) {
              return true;
            }
          }
        }
      }
      return false;
    },
    [selected]
  );

  useEffect(
    () => {
      ajaxAction(configMarkers)
        .then(async (response) => {
          const data = await response.json();
          const dataMap = arrayMarkerDtoToEntity(data);
          let filtered: Marker[] = [];
          dataMap.forEach((marker) => {
            if (isSubcategorieSelected(marker)) {
              filtered.push(marker);
            }
          });
          updateFilteredMarkers(filtered);
        })
        .catch((err) => console.log(err));
    },
    [isSubcategorieSelected]
  );

  /** 
  function isSubcategorieSelected(marker: Marker): boolean {
    for (let i = 0; i < marker.categories.length; i++) {
      const categorie = marker.categories[i];
      for (
        let j = 0;
        categorie.subcategories !== undefined &&
        j < categorie.subcategories.length;
        j++
      ) {
        const subCategorie = categorie.subcategories[j];
        for (let x = 0; x < selected.length; x++) {
          const element = selected[x];
          if (element === subCategorie.id.toString()) {
            return true;
          }
        }
      }
    }
    return false;
  }
  */

  return filteredMarkers;
};

export default useMarkers;
