import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./index.css";

const Cargando = (props: any) => {
  return (
    <div className="loading-container">
      <LoadingOutlined style={{ fontSize: 24 }} spin />
    </div>
  );
};

export default Cargando;
