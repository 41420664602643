import L from 'leaflet';
import React, { useEffect } from 'react';
import { createTileLayer } from '../../../utils/const/mapUtils';

import 'leaflet/dist/leaflet.css';

const BlockedMap = () => {

  useEffect(() => {
    const map = L.map('map', {
      center: [-34.8833, -56.1667],
      zoom: 12,
      minZoom: 12,
      maxZoom: 12,
      dragging: false,
      touchZoom: false,
      doubleClickZoom: false,
      scrollWheelZoom: false,
      boxZoom: false,
      keyboard: false,
      zoomControl: false
    });

    map.attributionControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');
    createTileLayer().addTo(map);

    return () => {
      map.remove();
    };
  });

  return <div id="map" style={{ height: '100%', width: '100%' }}></div>;
};

export default BlockedMap;
