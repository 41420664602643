import { useState, ChangeEvent } from "react";

export const useForm = (initialForm: any) => {
    const [formState, setFormState] = useState(initialForm);

    const onInputChange = ({
        target,
    }: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const handleChangeNestedData = ({
        target,
    }: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >) => {
        const { name, value } = target;

        setFormState((prevData: any) => {
            const nestedFields = name.split('.');
            let nestedData = prevData;

            // Acceder a cada nivel de anidación
            for (let i = 0; i < nestedFields.length - 1; i++) {
                nestedData = nestedData[nestedFields[i]];
            }

            // Actualizar el valor en el último nivel de anidación
            nestedData[nestedFields[nestedFields.length - 1]] = value;

            return { ...prevData };
        });
    };

    const onInputChangeForId = (e: number | string | boolean, id: string) => {

        setFormState({
            ...formState,
            [id]: e, //el event es el value para los casos del select en ant :(
        });
    };

    const onInputChangeNumberValue = ({
        target,
    }: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [name]: parseInt(value),
        });
    };

    const onInputChangeFloatNumberValue = ({
        target,
    }: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [name]: parseFloat(value),
        });
    };

    const onResetForm = () => {
        setFormState(initialForm);
    };

    const isEmpty = () => {
        return Object.keys(formState).length === 0;
    };

    return {
        formState,
        onInputChange,
        handleChangeNestedData,
        onInputChangeForId,
        onInputChangeNumberValue,
        onInputChangeFloatNumberValue,
        onResetForm,
        isEmpty,
    };
};
