import React from "react";

function Card(props:any) {
  return(
    <div className="carta">
      <div className="imgContainer">
        <img src={props.img} />
      </div>
      <div className="textContainer">
        <div>{props.text}</div>
        <div className="separador"></div>
        <div>{props.source}</div>
        <div>{props.company}</div>
      </div>
    </div>
  );
}

export default Card;
