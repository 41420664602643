import { Ong } from "../entities/Ong";
import { Subcategorie } from "../entities/Subcategorie";
import { sedeListDtoToEntity } from "./SedeMapper";
import blankPic from "../../images/blank-profile.jpg";

export function ongDtoToEntity(ong: any): Ong {
  const referenteDonaciones = ong.referentes.find(
    (ref: any) => ref.tipo === "donaciones"
  );

  return {
    id: ong.id,
    name: ong.name,
    description: ong.description,
    email: ong.email,
    phone:
      referenteDonaciones !== undefined
        ? referenteDonaciones.telefono
        : "",
    contactName: ong.nombreResponsable,
    contactSurname: ong.apellidoResponsable,
    contactNumber:
      referenteDonaciones !== undefined ? referenteDonaciones.numeroDeContacto : "",
    receive: mapSubcategoria(ong.ongs_donaciones),
    instagram: appendHttps(ong.instagram),
    twitter: appendHttps(ong.twitter),
    facebook: appendHttps(ong.facebook),
    website: appendHttps(ong.website),
    sedes: sedeListDtoToEntity(ong.sedes),
    photo: setPhoto(ong.photo),
    galeria: setGaleria(ong.fotos_ongs),
  };
}

function setGaleria(gal: any[]) {
  let response: string[] = [];

  if (gal) {
    gal.forEach((element) => {
      response.push("/" + element.foto);
    });
  }

  return response;
}

function setPhoto(photo: string): string {
  if (photo !== undefined && photo.length > 3) {
    return "data:image/jpeg;base64," + photo;
  }
  return blankPic;
}

function mapSubcategoria(subs: any[]): Subcategorie[] {
  //Cree otro mapper porque vienen de diferente forma los datos.
  var list: Subcategorie[] = [];
  if (subs) {
    subs.map(function(sub) {
      list.push({
        id: sub.Ongs_SubcategoriasDonaciones.ongId,
        name: "",
      });
    });
  }
  return list;
}

function mapContactNumberDonaciones(referentes: any[]): string | undefined {
  const referenteDonaciones = referentes.find(
    (ref) => ref.tipo === "donaciones"
  );

  if (referenteDonaciones) {
    return referenteDonaciones.numeroDeContacto;
  }

  return referenteDonaciones;
}

function appendHttps(url: string): string | undefined {
  return url;
}
