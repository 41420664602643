import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { Requeriment } from "../utils/models";

const validateRequirements: (reqs: Requeriment[], data: any) => boolean = (
  reqs,
  data
) => {
  return reqs.every((requirement: Requeriment) => {
    if (!data[requirement.tab_name]) {
      return false;
    } else {
      return requirement.requeriments.some(
        (element: string) => data[requirement.tab_name][element]
      );
    }
  });
};

const desplazarHaciaArriba = () => {
  const element = document.body;
  element.scrollIntoView({ block: "start", behavior: "smooth" });
};

//Form wrapper for creation forms
const EditionFW = (props: any) => {
  const nombre = props.nombre;
  const data: any = props.data;
  const formData: any = props.data[nombre] ? props.data[nombre] : {};
  const form = props.form;
  //Función a la que le pasamos los resultados del form y que ella vea qué hace con ellos
  const actualizarDatos = props.actualizarDatos;
  //Función que procesa los datos del formulario
  const procesarDatos = props.procesarDatos;
  //Los requerimientos del formulario
  const requerimientos = props.requerimientos;

  const [cumpleRequerimientos, setCumpleRequerimientos] = useState<boolean>(
    false
  );

  useEffect(() => {
    setCumpleRequerimientos(validateRequirements(requerimientos, data));
  }, []);

  if (cumpleRequerimientos) {
    return (
      <Form
        name={nombre}
        form={form}
        autoComplete="off"
        initialValues={formData}
        onFinish={(values) => {
          actualizarDatos({ [nombre]: procesarDatos(values) });
        }}
        onFinishFailed={() => desplazarHaciaArriba()}
      >
        {props.children}
        {!(formData instanceof Array) && (
          <div className="save-MiONG">
            <Button
              className="btn btn-primary btn-guardar"
              form={nombre}
              type="primary"
              htmlType="submit"
              onClick={() => {}}
            >
              Guardar
            </Button>
          </div>
        )}
      </Form>
    );
  } else {
    return null;
  }
};

export default EditionFW;
