import { Coordinates } from "../entities/Coordinates";
import { Sede } from "../entities/Sede";
import { Ubicacion } from "../entities/Ubicacion";

export function ubicacionDtoToEntity(ubicacion: any): Ubicacion {
  return {
    calle: ubicacion.calle,
    codigoPostal: ubicacion.codigoPostal,
    coordenadas: mapCoordenadas(ubicacion.coordenadas),
    descripcion: ubicacion.descripcion,
    id: ubicacion.id,
    localidad: ubicacion.localidad,
    numero: ubicacion.numero,
    pisoDepto: ubicacion.pisoDepto,
    place_id: ubicacion.place_id,
    provincia: ubicacion.provincia,
  };
}

export function ubicacionListDtoToEntity(ubicacionesRaw: any[]): Ubicacion[] {
  var ubicaciones: Ubicacion[] = [];
  if (ubicacionesRaw && ubicacionesRaw.length > 0) {
    ubicacionesRaw.forEach((element) => {
      const ubicacion = ubicacionDtoToEntity(element);
      ubicaciones.push(ubicacion);
    });
  }
  return ubicaciones;
}

function mapCoordenadas(coordenadas: any): Coordinates {
  return {
    type: coordenadas.type,
    coordinates: [coordenadas.coordinates[0], coordenadas.coordinates[1]],
  };
}
