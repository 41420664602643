import ajaxAction from './ajaxAction';

function sendEmail (
  data: FormData,
  service: string,
  callbackEmailSent: (arg0: boolean) => void
) {
  //obtener host de archivo compartido
    service = "http://localhost:8080/" + service;  
    ajaxAction({
      method: 'POST',
      service: service,
      data: data,
    }).then((result: { status: number }) => {
      callbackEmailSent(result.status === 200);
    });
  };

export default sendEmail;
