import React from "react";
import empresa1 from "../../../images/new-home/Empresa1.png";
import empresa2 from "../../../images/new-home/Empresa2.png";
import empresa3 from "../../../images/new-home/Empresa3.png";
import empresa4 from "../../../images/new-home/Empresa4.png";

const Empresas = () => {
  return (
    <div id="h-empresas">
      <div className="title">Empresas que nos acompañan</div>
      <div className="icons">
        <div className="icon">
          <img src={empresa1} />
        </div>
        <div className="icon">
          <img src={empresa2} />
        </div>
        <div className="icon">
          <img src={empresa3} />
        </div>
        <div className="icon">
          <img src={empresa4} />
        </div>
      </div>
    </div>
  );
};

export default Empresas;
