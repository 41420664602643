import { CategoryVolunteer } from "../entities/CategoryVolunteer";
import { Event } from "../entities/Event";

export function eventDtoToEntity(e: any): Event {
  return {
    id: e.id,
    titulo: e.titulo,
    description: e.descripcionEvento,
    coords: {
      x: e.coordenadas.coordinates[0],
      y: e.coordenadas.coordinates[1],
    },
    categories: mapCategories(e.tipoCategoriaVoluntariado),
    sedes: e.sede,
    desriptionLocation: e.descripcionUbicacion,
    ongId: e.ongId,
    ongName: e.ongName,
    ongDescription: e.ongDescription,
  };
}

export function arrayEventDtoToEntity(events: any[]): Event[] {
  var list: Event[] = [];
  if (events) {
    events.forEach((element) => {
      list.unshift(eventDtoToEntity(element));
    });
  }
  return list;
}

function mapCategories(values: any[]): CategoryVolunteer[] {
  let array: CategoryVolunteer[] = [];
  values.forEach((element) => {
    array.unshift({
      id: element.id,
      name: element.nombre,
    });
  });
  return array;
}
