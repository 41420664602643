import ajaxAction from "../../../utils/ajaxAction";
import blankOngLogo from "../../../images/blank-ong.jpg";
import { postONG } from "../../../utils/constants";

const mapReferenteDonaciones = (referenteDonaciones: any) => {
  if (
    referenteDonaciones.recibir_soporte === false
  ) {
    return {
      personaReferente: "",
      telefono: "",
      email: "",
      numeroDeContacto: "",
      tipo: "donaciones",
      recibir_soporte: false
    };
  } else {
    return {
      personaReferente: referenteDonaciones.personaReferente,
      telefono: referenteDonaciones.telefono,
      email: referenteDonaciones.email,
      numeroDeContacto: referenteDonaciones.numeroDeContacto,
      tipo: "donaciones",
      recibir_soporte: true
    };
  }
};

const mapReferenteVoluntariado = (referenteVoluntariado: any) => {
  if (
    referenteVoluntariado.recibir_soporte === false
  ) {
    return {
      personaReferente: "",
      telefono: "",
      email: "",
      numeroDeContacto: "",
      tipo: "voluntariado",
      recibir_soporte: false
    };
  } else {
    return {
      personaReferente: referenteVoluntariado.personaReferente,
      telefono: referenteVoluntariado.telefono,
      email: referenteVoluntariado.email,
      numeroDeContacto: referenteVoluntariado.numeroDeContacto,
      tipo: "voluntariado",
      recibir_soporte: true
    };
  }
};

const mapDonaciones = (donaciones: any) => {
  if (donaciones === undefined) {
    return []
  } else {
    return Object.keys(donaciones).map((donacionId: any) => {
      return ({ id: donacionId, description: "" })
    });
  }
}

const mapBankingInformation = (bankingInformation: any) => {
  if (bankingInformation === undefined) {
    return null;
  } else {
    return {
      id: "",
      bankName: bankingInformation.banco,
      accountNumber: bankingInformation.numeroCuenta,
      accountType: bankingInformation.tipoDeCuenta,
      accountName: "",
      currencyDollar: null,
      linkToDonations: bankingInformation.website
    }
  }
}

const mapOportunidades = (oportunidades: any) => {
  if (Array.isArray(oportunidades)) {
    const oportunidadesMapped: Array<any> = []
    oportunidades.forEach((oportunidad: any) => {
      const oportunidadMapped: any = {}
      oportunidadMapped.titulo = oportunidad.titulo
      oportunidadMapped.descripcion = oportunidad.descripcion
      oportunidadMapped.emergenciaId = null
      oportunidadMapped.tipoInicioId = 1
      oportunidadMapped.tipoHorarioId = 1
      oportunidadMapped.tipoDuracionId = 1
      oportunidadMapped.fecha_desde = null
      oportunidadMapped.fecha_hasta = null
      oportunidadMapped.dias_lunes = oportunidad.dias.includes("dias_lunes")
      oportunidadMapped.dias_martes = oportunidad.dias.includes("dias_martes")
      oportunidadMapped.dias_miercoles = oportunidad.dias.includes("dias_miercoles")
      oportunidadMapped.dias_jueves = oportunidad.dias.includes("dias_jueves")
      oportunidadMapped.dias_viernes = oportunidad.dias.includes("dias_viernes")
      oportunidadMapped.dias_sabado = oportunidad.dias.includes("dias_sabado")
      oportunidadMapped.dias_domingo = oportunidad.dias.includes("dias_domingo")
      oportunidadMapped.turnos_maniana = oportunidad.horario.includes("turnos_maniana")
      oportunidadMapped.turnos_tarde = oportunidad.horario.includes("turnos_tarde")
      oportunidadMapped.turnos_noche = oportunidad.horario.includes("turnos_noche")
      oportunidadMapped.tiempo_unavez = false
      oportunidadMapped.tiempo_ocasional = false
      oportunidadMapped.tiempo_mediotiempo = false
      oportunidadMapped.tiempo_tiempocompleto = false
      oportunidadMapped.presencia_virtual = false
      oportunidadMapped.presencia_fisica = false
      oportunidadMapped.status = "ENABLED"
      oportunidadMapped.sede = oportunidad.sede
      oportunidadMapped.categorias = oportunidad.categorias
      oportunidadesMapped.push(oportunidadMapped)
    })
    return oportunidadesMapped
  } else {
    return []
  }
}

export const registerONG = (data: any) => {
  //Sección de ONG
  const ong: any = {
    name: data["datos-principales-form"].name,
    cuit: "",
    email: data["datos-principales-form"].email,
    password: data["datos-principales-form"].password,
    description: data["datos-principales-form"].description,
    phone: "",
    tipoEntidadId: null,
    nombreResponsable: "",
    apellidoResponsable: "",
    cargoResponsable: "",
    claseMisionId: null,
    facebook: data["datos-principales-form"].facebook,
    instagram: data["datos-principales-form"].instagram,
    twitter: data["datos-principales-form"].twitter,
    website: data["datos-principales-form"].website,
    foto: data["datos-principales-form"].foto ?? blankOngLogo,
    re_bebes: false,
    re_primerainfancia: false,
    re_ninios: false,
    re_adolescentes: false,
    re_jovenes: false,
    re_adultos: false,
    re_terceraedad: false,
    re_noaplica: false,
    referenteDonaciones: mapReferenteDonaciones(data["referente-de-donaciones-form"]),
    referenteVoluntariado: mapReferenteVoluntariado(data["referente-de-voluntariado-form"]),
    bankInformation: mapBankingInformation(data.cuentaBancaria),
    link_collaborate: "",
    show_banking_information: data.cuentaBancaria === undefined
  }
  //Sección de donaciones
  const donaciones = mapDonaciones(data["donaciones-form"]);
  //Seccion de sedes
  let sedes = [data["sede-principal-form"], ...data["sedes-form"]];
  sedes = sedes.map((sede: any) => {
    return ({
      allowsDonations: true,
      calle: "",
      codigoPostal: "",
      numero: "",
      localidad: "",
      provincia: "",
      pisoDepto: sede.pisoDepto ?? "",
      telefonoFijo: "",
      horarios: "",
      nombreResponsableSede: "",
      apellidoResponsableSede: "",
      celular: "",
      mail: "",
      coordinates: {
        lat: sede.coordenadas.lat,
        lng: sede.coordenadas.lng
      },
      place_id: sede.place_id,
      descripcion: sede.direccion
    })
  })
  //Consolidado
  const ongData = {
    email: data["datos-principales-form"].email,
    password: data["datos-principales-form"].password,
    ong: ong,
    sedes: sedes,
    fotos: data["datos-principales-form"].gallery,
    donaciones: donaciones,
    oportunidades: mapOportunidades(data["oportunidades-form"])
  };
  return ajaxAction({
    method: 'POST',
    data: ongData,
    service: postONG,
  })
};
