import React, { Fragment, useEffect, useState } from "react";
import DatosPrincipales from "./forms/datosPrincipales";
import Sede from "./forms/sede";
import Sedes from "./forms/sedes";
import ReferenteDonaciones from "./forms/referenteDonaciones";
import Donaciones from "./forms/donaciones";
import CuentaBancaria from "./forms/cuentaBancaria";
import ReferenteVoluntariado from "./forms/referenteVoluntariado";
import Oportunidades from "./forms/oportunidades";
import Guardar from "./forms/guardar";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Navigation from "./utilsEdicion/navigation";
import AuthService from "../../services/auth/AuthService";
import DTOUserToFormFormat from "./utilsEdicion/authMapper";
import { updateONG, updateOngDonaciones, updateONGPassword } from "./utilsEdicion/update";

import EditionFW from "./formWrappers/editionFW";
import CambiarContrasenha from "./forms/cambiarContrasenha/cambiarContrasenha";

export const arrActionsTypes = ["CambiarTab", "Guardar"] as const;
type actionsTypes = typeof arrActionsTypes[number];

const notificate = (ok: boolean) => {
  notification.open({
    type: ok ? "success" : "error",
    message: "Datos Actualizados",
    description: `Los datos de la sección ${ok ? "" : "no"} han sido actualizados`,
    duration: 5,
  });
};

const OngEdicion = (props: any) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(-1);
  const [activeTabName, setActiveTabName] = useState<string>("");
  const [futureTabIndex, setFutureTabIndex] = useState<number>(-1);

  const [data, setData] = useState<any>();
  const [tempData, setTempData] = useState<any>();

  const [action, setAction] = useState<actionsTypes>(arrActionsTypes[1]);

  const [ongId, setOngId] = useState(null);

  const tabs: ((props: any) => JSX.Element)[] = [
    DatosPrincipales,
    CambiarContrasenha,
    Sede,
    Sedes,
    ReferenteDonaciones,
    Donaciones,
    CuentaBancaria,
    ReferenteVoluntariado,
    Oportunidades,
    Guardar
  ];

  const actualizarDatos = (newData: any) => {
    if (action === arrActionsTypes[0]) {
      setTempData({ ...tempData, ...newData });
      setActiveTabIndex(futureTabIndex);
      setAction(arrActionsTypes[1]);
    } else {
      const updatedTempData = { ...tempData, ...newData };
      const updatedData = { ...data, ...newData };
      if (activeTabIndex === 1) {
        if (updatedData[activeTabName].password === undefined) {
          notification.open({
            type: "error",
            message: "Datos No Actualizados",
            description: `La contraseña nueva no puede estar vacia`,
            duration: 5,
          });
        } else {
          updateONGPassword(updatedData).then((res: any) => {
            if (res.ok) {
              notificate(true);
            } else {
              notificate(false);
            }
          });
        }
      } else if (activeTabIndex === 5) {
        updateOngDonaciones(updatedData).then((res: any) => {
          if (res.ok) {
            notificate(true);
            setTempData(updatedTempData);
            setData(updatedData);
          } else {
            notificate(false);
          }
        });
      } else {
        updateONG(updatedData, ongId!).then((res: any) => {
          if (res.ok) {
            notificate(true);
            AuthService.loggedInFrontOffice().then((dataReceived) => {
              if (dataReceived) {
                setTempData(updatedTempData);
                setData(updatedData);
              }
            });
          } else {
            notificate(false);
          }
        });
      }
    }
  };

  const tabSelector = () => {
    if (activeTabIndex < 0) {
      const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;
      return <Spin indicator={antIcon} />;
    } else {
      return generateTabsContent();
    }
  };

  useEffect(() => {
    AuthService.loggedInFrontOffice().then((dataReceived) => {
      if (dataReceived) {
        setData(DTOUserToFormFormat(dataReceived.user));
        setTempData(data);
        setOngId(dataReceived.user.id);
        setActiveTabIndex(0);
      }
    });
  }, []);


  useEffect(
    () => {
      if (tempData === undefined) {
        setTempData(data);
      }
    },
    [data]
  );

  const generateTabsContent = () => {
    let Tab = tabs[activeTabIndex];
    return (
      <Fragment>
        <div className="title">Editar datos de la ONG</div>
        <Navigation
          form={activeTabName}
          tabs={tabs}
          data={data}
          setAction={setAction}
          activeTabIndex={activeTabIndex}
          setFutureTabIndex={setFutureTabIndex}
        />
        <div key={activeTabIndex}>
          {
            <Tab
              actualizarDatos={actualizarDatos}
              setActiveTabName={setActiveTabName}
              data={data}
              modo="edicion"
              fw={EditionFW}
            />
          }
        </div>
      </Fragment>
    );
  };

  return <div className="form-container">{tabSelector()}</div>;
};

export default OngEdicion;
