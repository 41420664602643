import React, { useState, useEffect, Fragment } from "react";
import DatosPrincipales from "./forms/datosPrincipales";
import Sedes from "./forms/sedes";
import Sede from "./forms/sede";
import ReferenteDonaciones from "./forms/referenteDonaciones";
import Donaciones from "./forms/donaciones";
import CuentaBancaria from "./forms/cuentaBancaria";
import ReferenteVoluntariado from "./forms/referenteVoluntariado";
import Oportunidades from "./forms/oportunidades";
import Guardar from "./forms/guardar";
import RegistroCompleto from "./forms/registroCompleto";
import Navigation from "./utilsCreacion/navigation";
import { Progress, Spin } from "antd";
import { registerONG } from "./utilsCreacion/register";
import UnsuccessfulRegistroCompleto from "./forms/unsuccessfulRegistroCompleto/unsuccessfulRegistroCompleto";
import { LoadingOutlined } from "@ant-design/icons";
import { Status } from "./utils/models";
import CreationFW from "./formWrappers/creationFW";

const OngCreacion = () => {
  const [registerComplete, setRegisterComplete] = useState<boolean>(false);
  const [registerStatus, setRegisterStatus] = useState<Status>(
    Status.Unfinished
  );
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeTabName, setActiveTabName] = useState<string>("");

  const [data, setData] = useState<any>({});

  const [direccion, setDireccion] = useState<number>(1);

  const tabs = [
    DatosPrincipales,
    Sede,
    Sedes,
    ReferenteDonaciones,
    Donaciones,
    CuentaBancaria,
    ReferenteVoluntariado,
    Oportunidades,
    Guardar,
  ];

  const validarPaso = (newData: any) => {
    setData({ ...data, ...newData });
    setActiveTabIndex((prevState: any) => prevState += direccion);
    setDireccion((prevstate) => prevstate);
  };

  const retroceder = () => {
    setDireccion(-1);
    return true;
  };

  const avanzar = () => {
    setDireccion(1);
    return true;
  };

  const guardar = () => {
    setRegisterComplete(true);
  };

  useEffect(
    () => {
      if (registerComplete) {
        setRegisterStatus(Status.Loading);
        registerONG(data).then((res: any) => {
          console.log("res", res);
          if (res.status === 200 && res.ok) {
            setRegisterStatus(Status.Success);
          } else {
            setRegisterStatus(Status.Error);
          }
        });
      }
    },
    [registerComplete, data]
  );

  const tabSelector = () => {
    if (registerComplete && registerStatus === Status.Success) {
      return <RegistroCompleto />;
    } else if (registerComplete && registerStatus === Status.Error) {
      return <UnsuccessfulRegistroCompleto />;
    } else if (registerComplete && registerStatus === Status.Loading) {
      const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;
      return <Spin indicator={antIcon} />;
    } else {
      return generateTabsContent();
    }
  };

  const generateTabsContent = () => {
    let Tab = tabs[activeTabIndex];
    return (
      <Fragment>
        <div className="title">Registrá tu ONG</div>
        <Progress
          strokeColor={{
            "0%": "#692B5E",
            "100%": "#692B5E",
          }}
          percent={
            registerComplete ? 100 : (activeTabIndex / tabs.length) * 100
          }
          showInfo={false}
        />
        <div key={activeTabIndex}>
          {
            <Tab
              actualizarDatos={validarPaso}
              setActiveTabName={setActiveTabName}
              data={data}
              modo="registro"
              fw={CreationFW}
            />
          }
        </div>
        <Navigation
          form={activeTabName}
          buttonConf={{
            retroceder: activeTabIndex > 0,
            avanzar: activeTabIndex >= 0 && activeTabIndex < tabs.length - 1,
            guardar: activeTabIndex == tabs.length - 1
          }}
          guardar={guardar}
          retroceder={retroceder}
          avanzar={avanzar}
        />
      </Fragment>
    );
  };

  return <div className="form-container">{tabSelector()}</div>;
};

export default OngCreacion;
