import VolunteerInfo from "../types/VolunteerInfo";
import { auth } from "../utils/constants";
import ajaxAction from "../utils/ajaxAction";

const post = (url: string, data: object, service: string) => {
  return ajaxAction({
    method: "POST",
    data: data,
    service: service + url,
  });
};

const submitVolunteerInscription = (volunteer: VolunteerInfo) => {
  return post("register/volunteer", volunteer, auth);
};

export const saveNewVolunteerOng = (
  event_id: number,
  volunteer: VolunteerInfo,
  callback: (v: boolean) => void
) => {
  ajaxAction({
    method: "POST",
    service: "api/inscripciones/",
    data: { event_id, volunteer_id: volunteer.id, createNotification: true },
  }).then((result) => {
    if (result.status === 200) {
      callback(true);
    } else {
      callback(false);
    }
  });
};

export { submitVolunteerInscription };
